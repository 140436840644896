import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E1",
    exerciseKey: "img/FriendsPlus/Page39/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:#0f9b52' >SPEAKING</span> Work in pairs. Answer the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:25cm'>
          <b>1</b>&ensp;Have you visited a foreign country? If so, did you notice any differences in that country’s customs?<br>
          <b>2</b>&ensp;What customs or cultural differences do you think visitors to Viet Nam might notice?<br>
        </div>
        `,
        answer: ["confused", "happy", "anxious", "annoyed", "surprised"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E2",
    exerciseKey: "img/FriendsPlus/Page39/Key/E2answerKey.png",
    audio: "Audios/1.28.mp3",
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `<headphone name='1.28' colorimg=#e8aeb1  color='white'></headphone> Listen to ten extracts from dialogues. Match
        each extract with a photo, A or B.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Gestures and expressions</b>",
          "bite your fingernails",
          "bow your head",
          "fidget",
          "fold your arms",
          "frown",
          "give a thumbs up",
          "grimace",
          "grin",
          "nod your head",
          "point",
          "pout",
          "purse your lips",
          "raise your eyebrows",
          "shake your head",
          "shrug your shoulders",
          "wink",
          "yawn",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page39/E2/1.jpg" style='height:16cm'/>
            <div>
                  <table style="width: 60%; border-collapse: collapse;">
                  <tr>
                      <th style="width:30%; padding: 8px; text-align: left; border: 2px solid black;text-align:center;">Photo A</th>
                      <th style="width:30%; padding: 8px; text-align: left; border: 2px solid black;text-align:center;">Photo B</th>
                  </tr>
                  <tr>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                  </tr>
                  <tr>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                  </tr>
                  <tr>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                  </tr>
                  <tr>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                  </tr>
                  <tr>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                      <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                  </tr>
                  
              </table>
            </div>
        </div>
        `,
        // InputRong: true,
        answer: [
          "1|3|4|8|9",
          "2|5|6|7|10",
          "1|3|4|8|9",
          "2|5|6|7|10",
          "1|3|4|8|9",
          "2|5|6|7|10",
          "1|3|4|8|9",
          "2|5|6|7|10",
          "1|3|4|8|9",
          "2|5|6|7|10",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E3",
    exerciseKey: "img/FriendsPlus/Page39/Key/E3answerKey.png",
    audio: "Audios/1.28.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='1.28' colorimg=#e8aeb1  color='white'></headphone> Listen again. Rewrite the sentences making
        them formal. Use the words in brackets to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "be supposed to",
          "don’t have to, don’t need to and needn’t must and have to",
          "mustn’t",
          "should and ought to",
        ],
        styleElement: { color: "#f88b1f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Hi there. (good)<br>#<br>
          <b>2</b>&ensp;We don’t know each other. (believe / met)<br>#<br>
          <b>3</b>&ensp;Good to meet you. (a pleasure)<br>#<br>
          <b>4</b>&ensp;Come and meet some of my friends. (allow me / introduce / colleagues)<br>#<br>
          <b>5</b>&ensp;Fancy a coffee? (could / fetch / at all)<br>#<br>
          <b>6</b>&ensp;Sure, why not? (kind / to offer)<br>#<br>
        </div>
          `,
        // InputRong: true,
        answer: [
          "Good morning / afternoon.",
          "I don't believe we've met.",
          "It's a pleasure to meet you.",
          "Allow me to introduce you to my colleagues.",
          "Could I fetch you a coffee at all?",
          "It's very kind of you to offer.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E4",
    exerciseKey: "img/FriendsPlus/Page39/Key/E4answerKey.png",
    audio: "Audios/1.29.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 800,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.29' colorimg=#e8aeb1  color='white'></headphone> Read the <span style='color:#c40b44'>Listening Strategy</span>. Then listen and
        match each dialogue with a text type. Give reasons for
        your answers.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],

    stylesTextInput: {
      maxLength: 1,
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    character: /[,?]/,
    component: T6,
    questions: [
      {
        title: `
        <div>
          <b>a</b>&ensp;A conversation between friends<br>
          <b>b</b>&ensp;A news item<br>
          <b>c</b>&ensp;An announcement<br>
          <b>d</b>&ensp;A conversation between strangers<br>
          <b>e</b>&ensp;A professional conversation between colleagues<br>
        </div>
        <b>Answer</b><br>
         <div style='display:flex'>#<b>a</b><textarea id=5 rows=2></textarea></div>
         <div style='display:flex'>#<b>b</b><textarea id=6 rows=2></textarea></div>
         <div style='display:flex'>#<b>c</b><textarea id=7 rows=2></textarea></div>
         <div style='display:flex'>#<b>d</b><textarea id=8 rows=2></textarea></div>
         <div style='display:flex'>#<b>e</b><textarea id=9 rows=2></textarea></div>
        `,
        answer: [
          "2",
          "5",
          "3",
          "4",
          "1",
          "tone of voice, informal language, e.g. Guess what!",
          "formal language, the topic is a news item: The government has issued a statement regarding travel to Africa in the near future.",
          "formal language and topic vocabulary: passengers for flight BA6Y3, gate number 47",
          "tone of voice, polite request for help: Could you help me, please? I'm trying to find Leicester Square.",
          "formal language and topic vocabulary: colleague, We are very much hoping that you will be able to work with us on this.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E5",
    exerciseKey: "img/FriendsPlus/Page39/Key/E5answerKey.png",
    audio: "Audios/1.30.mp3",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='1.30' colorimg=#e8aeb1  color='white'></headphone> Listen to three texts. Match the situations (1–3)
        with the speakers (a–c).`,
        color: "black",
        left: -30,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 900,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "582px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "77px",
              left: "582px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "127px",
              left: "582px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "382px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "77px",
              left: "382px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "127px",
              left: "382px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["1-3", "2-4", "0-5"],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `mustn’t / needn’t`,
          `must / are_supposed_to`,
          `don’t_have_to / shouldn’t`,
          `You’re_supposed_to / You_must`,
          `ought_to / don’t_need_to`,
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <input id=0 type=boxMatch />
      <input id=1 type=boxMatch />
      <input id=2 type=boxMatch />
      <input id=3 type=boxMatch />
      <input id=4 type=boxMatch />
      <input id=5 type=boxMatch />
      <div style='display:flex;line-height:50px'>
        <div>
          <b>1</b>&ensp;Informal conversation<br>
          <b>2</b>&ensp;Dialogue between strangers<br>
          <b>3</b>&ensp;Radio advert<br>
        </div>
        <div style='margin-left:250px'>
          <b>a</b>&ensp;Actor in a voiceover<br>
          <b>b</b>&ensp;Friends<br>
          <b>c</b>&ensp;Airport employee<br>
        </div>
      </div>

      `,
    },
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E6",
    exerciseKey: "img/FriendsPlus/Page39/Key/E6answerKey.png",
    audio: "Audios/1.30.mp3",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 300, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.30' colorimg=#e8aeb1  color='white'></headphone> Listen again. Choose the correct answers (a–c).`,
        color: "black",
        width: "28cm",
        left: -20,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "582px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c`, `a <br> b <br> c`, `a <br> b <br> c`],
        answers: ["0-4", "1-4", "2-8"],
        initialValue: [],
      },
      Layout: `
      
      <div style='line-height:50px'>
            <b>1</b>&ensp;In text 1, why did Anna feel embarrassed during the meal?
            <div style='display:flex'>
              <div style='margin-left:15px;margin-right:10px'><input id=0 type=Circle /></div>
              <div>Her colleague did something which shocked the other diners.<br>
                She accidentally did something that was culturally unacceptable.<br>
                She ignored somebody who was being introduced to her.</div>
            </div>
            <b>2</b>&ensp;In text 2, what mistake did one of the speakers make?
            <div style='display:flex'>
              <div style='margin-left:15px;margin-right:10px'><input id=1 type=Circle /></div>
              <div>He put his laptop in the wrong place.<br>
                He failed to empty his pockets.<br>
                He walked through the wrong archway.</div>
            </div>
            <b>3</b>&ensp;In text 3, what is the speaker trying to persuade the listeners to do?
            <div style='display:flex'>
              <div style='margin-left:15px;margin-right:10px'><input id=2 type=Circle /></div>
              <div>To book a water sports holiday in a particular destination.<br>
                To use the internet to find the best holidays abroad.<br>
                To book a holiday with a particular holiday company.</div>
            </div>
      </div>

      `,
    },
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E7",
    exerciseKey: "img/FriendsPlus/Page39/Key/E7answerKey.png",
    recorder: true,
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Prepare two short role-plays
        where you meet someone for the first time – the first is
        at a formal event and the second is at a friend’s party.
        Include the following:
        `,
        color: "black",
        left: 50,
        width: "27cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["You should buy a phrase book."],
        styleElement: { marginLeft: 20, color: "#08728a", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 700 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page39/E7/1.jpg" style='height:5.5cm'/>
        <ul>
          <li> Greet and introduce yourself.</li>
          <li> Explain why you’re there / how you know the host.</li>
          <li> Exchange some personal information.</li>
          <li> Offer to get your new friend a drink.</li>
          <li> Bring the conversation to an end.</li>
        </ul>
        `,
        answer: [
          "Are we supposed to leave a tip?",
          "You mustn't enter the temple in shorts.",
          "You don't need to speak Spanish.",
          "He shouldn't have any problem making friends (in my opinion).",
          "It could rain while we're away.",
          "Look at the map; this must be the right road!",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P39-E8",
    exerciseKey: "img/FriendsPlus/Page39/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:#0f9b52' >SPEAKING</span> Work in pairs. Use modal verbs and agree
        on how a visitor to your country should behave in these
        situations.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Going for dinner in a restaurant<br>
        <b>2</b>&ensp;Being invited to a friend’s parents’ house<br>
        <b>3</b>&ensp;Visiting a religious building<br>
        <b>4</b>&ensp;Going to the beach<br>
        <img src="img/FriendsPlus/Page39/E8/1.jpg" style='height:3cm'/>


        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
