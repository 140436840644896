import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E1",
    exerciseKey: "img/FriendsPlus/Page65/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Read the message board. Who thinks highly of the HR
        manager?`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page65/E1/1.jpg" style='height:26cm'/><br>

        
        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E2",
    exerciseKey: "img/FriendsPlus/Page65/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Find all the adjectives in exercise 1.
        Do they come before a noun or after a linking verb?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 750, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page65/E2/1.jpg" style='height:10cm'/><br>
        <div style='line-height:50px'>
          <b>Before a noun</b><textarea id=0 rows=3></textarea>
          <b>After a linking verb</b><textarea id=1 rows=3></textarea>
        </div>
        <img src="img/FriendsPlus/Page65/E1/1.jpg" style='height:27cm'/>
        
        `,
        // InputRong: true,
        answer: [
          "talented young (HR manager), selfless (charity work), fashionable white (dress), silver (shoes), self-centred (people), huge modern (offices), enviable (perks), stuffy noisy (factory), low (wages)",
          "(she's) great, (looks) stunning, (she's) Amazing!, (get really) annoyed",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E3",
    exerciseKey: "img/FriendsPlus/Page65/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Look again at the adjectives that come before a noun
        in exercise 1. Match them with the categories (size, age,
        colour, etc.) in the <span style='color:#91268e'>Learn this!</span> box.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["young – age", "self-centred – opinion"],
        styleElement: { color: "#4dc4c4", marginRight: "100px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 750, paddingTop: 10 },
    pointThieuWord: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    character: ",",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page65/E2/1.jpg" style='height:10cm'/><br>
        <div style='line-height:50px'>
          <b>Opinion</b>#<br>
          <b>Size</b>#<br>
          <b>Age</b>#<br>
          <b>Colour</b>#<br>
        </div>
        <img src="img/FriendsPlus/Page65/E1/1.jpg" style='height:27cm'/>
        
        `,
        // InputRong: true,
        answer: [
          "Talented, selfless, fashionable, enviable, self-centred, stuffy noisy",
          "low, huge",
          "young, modern",
          "white, silver",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E4",
    exerciseKey: "img/FriendsPlus/Page65/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the adjectives in brackets
        in the correct order.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 300,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <div style='width:27cm;line-height:47px'>
          <b>1</b>&ensp;My company’s forum is talking about a# musician. (young / Portuguese / vain)<br>
          <b>2</b>&ensp;The interviewer was wearing a# dress. (Japanese / white / long / silk)<br>
          <b>3</b>&ensp;At the company’s fancy party, the director always appears on stage in a# wig. (massive / red / ridiculous)<br>
          <b>4</b>&ensp;My idol supervisor goes to work on a(n)# motorbike. (Italian / black / 1960s<br>
        </div>

        `,
        answer: [
          "vain young Portuguese",
          "long white silk Japanese",
          "ridiculous massive red",
          "1960s black Italian ",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E5",
    exerciseKey: "img/FriendsPlus/Page65/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Match the adjectives with similar meanings to make
        some pairs.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 800,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["afraid – frightened"],
        styleElement: { color: "#4dc4c4", marginRight: "30px" },
        width: "70%",
      },
      {
        src: [
          "<s>afraid</s>",
          "alive",
          "alone",
          "angry",
          "annoyed",
          "asleep",
          "<s>frightened</s>",
          "glad",
          "happy",
          "live",
          "lonely",
          "sleeping",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        
        <div style='display:flex'>
          <b>Answer:</b><textarea id=0 rows=2></textarea>
        </div>
        `,
        answer: [
          "alive - live, alone - lonely, angry - annoyed, asleep - sleeping, glad - happy",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E6",
    exerciseKey: "img/FriendsPlus/Page65/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 570 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#91268e'>Look out!</span> box. Which adjective in each pair
        from exercise 5 can go a) before a noun and b) only after
        a linking verb? Use a dictionary to help you.
        `,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "afraid",
          "alive",
          "alone",
          "angry",
          "annoyed",
          "asleep",
          "frightened",
          "glad",
          "happy",
          "live",
          "lonely",
          "sleeping",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    character: ",",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page65/E6/1.jpg" style='height:7.5cm'/><br>

        <div style='line-height:47px'>
          <b>a)</b>&ensp;before a noun and<br>#<br>
          <b>b)</b>&ensp;only after a linking verb?<br>#<br>
        </div>
        `,
        answer: [
          "angry, frightened, happy, living, lonely, sleeping",
          "afraid, alive, alone, annoyed, asleep, glad",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E7",
    exerciseKey: "img/FriendsPlus/Page65/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Choose the correct words to complete the sentences.
        Sometimes both are possible.
        `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    component: Circle_Write,

    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `glad / happy`,
          `disappointed / upset`,
          `asleep / sleeping`,
          `brilliant / French`,
          `grey / nasty`,
          `alone / lonely`,
        ],
        answers: ["0-4", "1-0", "1-4", "2-4", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='width:27cm;line-height:47px'>
        <b>1</b>&ensp;Ten <input id=0 type=Circle /> winners have shared this week’s lottery prize.<br>
        <b>2</b>&ensp;The woman was <input id=1 type=Circle /> because the noise woke her <input id=2 type=Circle /> baby.<br>
        <b>3</b>&ensp;A ninety-year-old <input id=3 type=Circle /> actor has won an award at the Cannes Film Festival.<br>
        <b>4</b>&ensp;There are some huge <input id=4 type=Circle /> clouds in the distance.<br>
        <b>5</b>&ensp;Some well-known people have quite <input id=5 type=Circle /> lives.<br>
      </div>
      `,
    },
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P65-E8",
    exerciseKey: "img/FriendsPlus/Page65/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Using adjectives and linking
        verbs, tell your partner about a famous person you would
        like to meet.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <ul>
          <li>Give some information about them and what they do.</li>
          <li>Say why you’d like to meet them and how it would make you feel. </li>
        </ul>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
