import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E4",
    exerciseKey: "img/FriendsPlus/Page61/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the holiday activities (1–14) with the verbs
        below.`,
        color: "black",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 150,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex'>
          <div>
            <b>1</b>&ensp;#the sights<br>
            <b>2</b>&ensp;#it easy<br>
            <b>3</b>&ensp;#out in a restaurant<br>
            <b>4</b>&ensp;#from it all<br>
            <b>5</b>&ensp;#your horizons<br>
            <b>6</b>&ensp;#new experiences<br>
            <b>7</b>&ensp;#the sun<br>
          </div>
          <div style='margin-left:100px'>
            <b>8</b>&ensp;#local food<br>
            <b>9</b>&ensp;#physically active<br>
            <b>10</b>&ensp;#yourself at a spa<br>
            <b>11</b>&ensp;#with your friends<br>
            <b>12</b>&ensp;#the nightlife<br>
            <b>13</b>&ensp;#people<br>
            <b>14</b>&ensp;#your batteries<br>
          </div>
        </div>
        `,
        answer: [
          "see",
          "take",
          "eat",
          "get away",
          "broaden",
          "have",
          "soak up",
          "try",
          "be",
          "pamper",
          "hang out",
          "enjoy",
          "meet",
          "recharge",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E5",
    exerciseKey: "img/FriendsPlus/Page61/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Which sound(s) are you likely to make when you …
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 200,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: [
          "<b>Holidays and holiday accommodation</b>",
          "adventure holiday",
          "B&B",
          "backpacking",
          "beach holiday",
          "beach house",
          "cabin",
          "camper van",
          "camping",
          "campsite",
          "caravan",
          "city break",
          "cottage",
          "couch-surfing",
          "cruise",
          "cycling holiday",
          "ecotourism",
          "guest house",
          "holiday camp",
          "holiday home",
          "hotel",
          "houseboat",
          "house swap",
          "package holiday",
          "self-catering apartment",
          "tent",
          "time-share apartment",
          "villa",
          "volunteering",
          "winter sports holiday",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <div style='display:flex'>
          <div style='margin-right:20px'>
            <b>1</b>&ensp;#<br>
            <b>2</b>&ensp;#<br>
            <b>3</b>&ensp;#<br>
            <b>4</b>&ensp;#<br>
            <b>5</b>&ensp;#<br>
            <b>6</b>&ensp;#<br>
            <b>7</b>&ensp;#<br>
            <b>8</b>&ensp;#<br>
            <b>9</b>&ensp;#<br>
            <b>10</b>&ensp;#<br>
            <b>11</b>&ensp;#<br>
            <b>12</b>&ensp;#<br>
          </div>
          <img src="img/FriendsPlus/Page61/E5/1.jpg" style='height:26cm'/>

        </div>
        `,
        answer: [
          "soaking",
          "physically",
          "house",
          "sights",
          "ecotourism",
          "self-catering",
          "local",
          "volunteering",
          "package",
          "city break",
          "cruise",
          "couch-surfing",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E6",
    exerciseKey: "img/FriendsPlus/Page61/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 250, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Match the compound adjectives below
        with definitions (1–10).`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Compound adjectives</b>",
          "bad-mannered",
          "easy-going",
          "hard-working",
          "light-hearted",
          "open-minded",
          "quick-witted",
          "self-confident",
          "single-minded",
          "thick-skinned",
          "well-behaved",
        ],
        width: "90%",
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
          <b>1</b>&ensp;having confidence in yourself and your abilities#<br>
          <b>2</b>&ensp;thinking only about the thing that you want to achieve#<br>
          <b>3</b>&ensp;not easily upset by negative comments#<br>
          <b>4</b>&ensp;relaxed and happy to accept things without worrying#<br>
          <b>5</b>&ensp;behaving in a way that is acceptable#<br>
          <b>6</b>&ensp;able to think in a fast way#<br>
          <b>7</b>&ensp;cheerful and without problems#<br>
          <b>8</b>&ensp;rude and not showing respect#<br>
          <b>9</b>&ensp;willing to accept other ideas and ways of doing things#<br>
          <b>10</b>&ensp;putting a lot of effort into something#<br>
        </div>

        `,
        answer: [
          "self-confident",
          "single-minded",
          "thick-skinned",
          "easy-going",
          "well-behaved",
          "quick-witted",
          "light-hearted",
          "bad-mannered",
          "open-minded",
          "hard-working",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E7",
    exerciseKey: "img/FriendsPlus/Page61/Key/E7answerKey.png",
    audio: "Audios/2.10.mp3",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='2.10' colorimg=#d1b6d5  color='white'></headphone> Listen to three male students talking about
        their own qualities. Which MBTI type do you think
        they belong to? Identify the job (a–c) each person is
        suitable for`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 360, textAlign: "center" },
    component: T6,
    character: ",",
    // checkDuplicated: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:47px'>
            <b>a</b>&ensp;A salesman<br>
            <b>b</b>&ensp;A psychologist<br>
            <b>c</b>&ensp;A judge<br>
        </div>
        <b>Answer:</b><br>
        <div style='line-height:47px'>
            <b>Speaker 1</b>&ensp;#<br>
            <b>Speaker 2</b>&ensp;#<br>
            <b>Speaker 3</b>&ensp;#<br>
        </div>

        <img src="img/FriendsPlus/Page61/E6/1.jpg" style='height:25cm'/>

        `,
        answer: [
          "The Mediator - a psychologist",
          "The Director - a judge",
          "The Persuader - a salesman",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E8",
    exerciseKey: "img/FriendsPlus/Page61/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { textAlign: "center", width: 400, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    character: ",",
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in groups. To what extent do you
        think personality tests help a high school student in
        choosing a job or career?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        `,
        answer: [
          "sremote",
          "breathtaking, inaccessible",
          "overcrowded, commercialised",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E9",
    exerciseKey: "img/FriendsPlus/Page61/Key/E9answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='2.02' colorimg=#d1b6d5  color='white'></headphone> Read the <span style='color:#92278f' >Recycle!</span> box. Then
        listen again and answer the questions in
        writing.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page61/E9/1.jpg" style='height:13cm'/>
        <div style='width:25cm'>
            <b>1</b>&ensp;In dialogue 1, what does the woman wish?<br>#<br>
            <b>2</b>&ensp;In dialogue 2, how would the boy feel if he spent a few nights at Lindisfarne Castle?<br>#<br>
            <b>3</b>&ensp;In dialogue 3, what advice would the woman give if somebody wanted a relaxing holiday?<br>#<br>
        </div>
        
        
        
        `,
        answer: [
          "She wishes they were already on holiday.",
          "He would be worried about meeting ghosts.",
          "This wouldn't be a great place to visit.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P61-E10",
    exerciseKey: "img/FriendsPlus/Page61/Key/E10answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 370, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Work in pairs. Which of the
        holidays in exercise 2 would you most like
        to go on? Why?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
          Which gesture or expression might you use if …<br>
            <b>1</b>&ensp;you wish someone good luck in an exam? <br>
            <b>2</b>&ensp;you are puzzled by a question?<br>
            <b>3</b>&ensp;you don’t know the answer to a question that someone has asked?<br>
        </div>
        <img src="img/FriendsPlus/Page61/E10/1.jpg" style='height:4cm'/>

        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
};

export default json;
