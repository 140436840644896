import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E1",
    exerciseKey: "img/FriendsPlus/Page41/Key/E1answerKey.png",
    audio: "Audios/1.32.mp3",

    titleQuestion: [
      {
        num: "1",
        title: `<headphone name='1.32' colorimg=#d1b6d5  color='white'></headphone> Look at the British English words below. What
        are the American English equivalents? Listen to the
        three voicemails and check. `,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: ["film (n)", "flat", "lift (n)", "petrol", "tap", "toilet"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 800,
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
          <b>film</b> – #<br>
          <b>flat</b> – #<br>
          <b>lift</b> – #<br>
          <b>petrol</b> – #<br>
          <b>tap</b> – #<br>
          <b>toilet</b> – #<br>
        </div>
        `,
        answer: ["movie", "apartment", "elevator", "gas", "faucet", "restroom"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E2",
    exerciseKey: "img/FriendsPlus/Page41/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the email and the <span style='color:#91268e'>Learn this!</span> box.  Find examples
        of American English in the email.
        `,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>", "bite your fingernails"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `Hey Sam!
           Can’t wait for you to arrive!
           I’ve organised your accommodation –
            you’ll be staying with a neighbor of mine|.
           He’s from Mexico, so you’ll be able to practice your Spanish while you’re on vacation|.
           Public transportation|’s pretty good here, and I live near the subway|, but bring your driver’s license anyhow|.
           Maybe we could take a trip out to the theater one evening? Anyway, I’ll meet you at the airport on Saturday|.
           New York’s beautiful in the fall|, so I know we’ll have a great time, even if we just play soccer in the yard|!
          `,
        ],
        answers: [
          "0-162",
          "0-190",
          "0-210",
          "0-224",
          "0-326",
          "0-356",
          "0-362",
        ],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page41/E2/2.jpg" style='height:6cm'/>
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page41/E2/1.jpg" style='height:14cm'/>
        <div style='line-height:35px;width:18cm;position:absolute;top:112px;left:49px'>
          <input id=0 type=Circle />
        </div>
      </div>
   
      
      
      `,
    },
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E3",
    exerciseKey: "img/FriendsPlus/Page41/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Dictionary Strategy</span> and the dictionary entry.
        What is the American word for ‘head teacher’? How are
        the two equivalents labelled?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["annoyance", "anxiety"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page41/E3/2.jpg" style='height:4cm'/><br>
        <img src="img/FriendsPlus/Page41/E3/1.jpg" style='height:7cm'/><br>
        <b>head teacher</b>#<br>
        <b>principal</b>#<br>

        `,

        // InputRong: true,
        answer: ["BrE", "NAmE"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E4",
    exerciseKey: "img/FriendsPlus/Page41/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Match the words in A with the words in B with the same
        meaning. Which word in each pair is American English?
        Use a dictionary to help you.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 80,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page41/E3/1.jpg" style='height:7cm'/>
        <div style='display:flex'>
          <b style='color: rgb(146,39,143)'>A</b> <hintbox id=0></hintbox>
        </div>
        <div style='display:flex'>
          <b style='color: rgb(146,39,143)'>B</b> <hintbox id=1></hintbox>
        </div>
        <b>biscuit</b> (#) – <input id=18 width=150px /> (#)<br>
        <b>candy</b> (#) – <input id=19 width=150px /> (#)<br>
        <b>cell phone</b> (#) – <input id=20 width=150px /> (#)<br>
        <b>check</b> (#) – <input id=21 width=150px /> (#)<br>
        <b>motorway</b> (#) – <input id=22 width=150px /> (#)<br>
        <b>queue</b> (#) – <input id=23 width=150px /> (#)<br>
        <b>sidewalk</b> (#) – <input id=24 width=150px /> (#)<br>
        <b>sneakers</b> (#) – <input id=25 width=150px /> (#)<br>
        <b>torch</b> (#) – <input id=26 width=150px /> (#)
         `,
        answer: [
          "BrE",
          "cookie",
          "AmE",
          "AmE",
          "sweets",
          "BrE",
          "AmE",
          "mobile",
          "BrE",
          "AmE",
          "bill",
          "BrE",
          "BrE",
          "highway",
          "AmE",
          "BrE",
          "line",
          "AmE",
          "AmE",
          "pavement",
          "BrE",
          "AmE",
          "trainers",
          "BrE",
          "BrE",
          "flashlight",
          "AmE",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E5",
    exerciseKey: "img/FriendsPlus/Page41/Key/E5answerKey.png",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Read rules 1 and 2 in the <span style='color:#91268e'> Look out!</span> box. Find examples
        in exercise 2 and make a note of the British English
        spelling.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],

    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page41/E5/1.jpg" style='margin-left:-50px;height:9cm'/>
          <img src="img/FriendsPlus/Page41/E5/2.png" style='height:12cm'/>
        </div>
        <div style='margin-top:-100px'>
          <b>Answer</b><br>
          <div style='display:flex'>
            <div><b>1</b><br><br><br><br>
            <b>2</b></div>
            <div>#<br>#<br>#<br>#<br>
            #<br></div>
          </div>
        </div>

        `,
        answer: [
          "neighbor - neighbour|practice - practise|license - licence|theater -theatre",
          "neighbor - neighbour|practice - practise|license - licence|theater -theatre",
          "neighbor - neighbour|practice - practise|license - licence|theater -theatre",
          "neighbor - neighbour|practice - practise|license - licence|theater -theatre",
          "organized - organised",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E6",
    exerciseKey: "img/FriendsPlus/Page41/Key/E6answerKey.png",
    audio: "Audios/1.33.mp3",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 60 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.33' colorimg=#d1b6d5  color='white'></headphone> Read rule 3 in the <span style='color:#91268e'> Look out!</span> box. Listen to the
        words below being said by an American and a British
        speaker. Which one do you hear first? Write <i>Am</i> or <i>Br</i>. 
        `,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page41/E5/1.jpg" style='height:10cm'/>
        <div style='display:flex'>
            <div>
              <b>1</b>&ensp;address <span style='color:rgb(58,147,205)'>Br</span><br>
              <b>2</b>&ensp;advertisement #<br>
              <b>3</b>&ensp;brochure #<br>
              <b>4</b>&ensp;garage #<br>
              <b>5</b>&ensp;leisure #<br>
            </div>
            <div style='margin-left:200px'>
              <b>6 </b>&ensp;tomato #<br>
              <b>7 </b>&ensp;vase #<br>
              <b>8 </b>&ensp;vitamins #<br>
              <b>9 </b>&ensp;yoghurt #<br>
              <b>10</b>&ensp; zebra #<br>
            </div>
        </div>

        `,
        answer: ["Br", "Am", "Br", "Am", "Am", "Br", "Am", "Br", "Br"],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E7",
    exerciseKey: "img/FriendsPlus/Page41/Key/E7answerKey.png",
    audio: "Audios/1.34.mp3",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='1.34' colorimg=#d1b6d5  color='white'></headphone> Listen to six speakers. Is each speaker American
        or British?
        `,
        color: "black",
        left: 100,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 70,
      paddingTop: 10,
    },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
         <div style='display:flex'>
              <div style='margin:0px 50px;'><b>1</b>&ensp;#</div>
              <div><b>2</b>&ensp;#</div>
              <div style='margin:0px 50px;'><b>3</b>&ensp;#</div>
              <div><b>4</b>&ensp;#</div>
              <div style='margin:0px 50px;'><b>5</b>&ensp;#</div>
              <div><b>6</b>&ensp;#</div>
         </div>
        `,
        answer: ["Am", "Br", "Am", "Am", "Br", "Br"],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P41-E8",
    exerciseKey: "img/FriendsPlus/Page41/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Each student makes three
        sentences using the words in exercise 4 and reads them
        to the other student. The other student will tell their
        equivalents in British English or American English.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <b style='color: rgb(146,39,143)'>A</b> <hintbox id=0></hintbox>
      </div>
      <div style='display:flex'>
        <b style='color: rgb(146,39,143)'>B</b> <hintbox id=1></hintbox>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
