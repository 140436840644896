import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E1",
    exerciseKey: "img/FriendsPlus/Page58/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Look at the two photos. What do you know
        about the Aborigine and Maori cultures?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page58/E1/1.jpg" style='height:12cm'/><br>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E2",
    exerciseKey: "img/FriendsPlus/Page58/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. What two customs do the texts mention?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 50, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page58/E2/1.jpg" style='height:14cm'/><br>
        <img src="img/FriendsPlus/Page58/E2/2.jpg" style='height:14cm'/><br>
        <b>Answer:</b><textarea id=0 rows=6></textarea>
        `,
        // InputRong: true,
        answer: [
          "One of the most important Aborigine customs is the walkabout: boys aged thirteen spend time alone in the desert and hunt for food to survive. An important Maori custom is Ta moko, the practice of tattooing the face or body. The tattoos represent the family or tribe a person belongs to.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E3",
    exerciseKey: "img/FriendsPlus/Page58/Key/E3answerKey.png",
    audio: "Audios/2.07.mp3",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the text. Write one word in
        each gap.<br>
        <headphone name='2.07' colorimg=#9bc5d6  color='white'></headphone>`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 250, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:25cm'>
          Anybody who is hoping to have new experiences when
          they visit Australia or New Zealand should meet the
          original inhabitants of <sup>1</sup># two countries, the
          Aborigines and the Maoris. However, <sup>2</sup>#
          both cultures have experienced similar problems caused
          by the arrival of Europeans to their lands, the Aborigines
          and the Maoris are very different.
        </div>
        <div style='width:25cm'>
          <b style='color:rgb(218,108,49);font-size:34px'>The Aborigines</b><br>
          The Aborigines have one of the oldest surviving cultures
          in the world. It is more <br><sup>3</sup># 60,000 years
          old, and during this period of time, they have formed
          hundreds of tribes that have developed different customs
          and around seven hundred different languages. In 1971,
          a flag <sup>4</sup># designed to represent all the
          Aborigine tribes and help unite them.<br>
          The Aborigines hold a lot of ceremonies to celebrate
          different events, but one of the most important to nearly
          <sup>5</sup># of the tribes is the ‘walkabout’. It might
          seem like an adventure to people from other cultures, but
          the walkabout involves boys aged thirteen sleeping rough
          for as long as six months in the Australian desert. They
          spend the whole time alone and <sup>6</sup># to hunt for
          food to survive. Once the walkabout period has finished,
          they are welcomed back by their village, which celebrates
          the arrival of a new man in the community. 
        </div>
        <div style='width:25cm'>
          <b style='color:rgb(66,130,54);font-size:34px'>The Maoris</b><br>
          The Maoris arrived in New Zealand from East Polynesia in
          the second half of the 12th century. The Maoris all speak
          the <sup>7</sup># language and share the same customs,
          and this has helped them to have a strong presence in
          modern New Zealand. Maori is one of the country’s two
          official languages. Maoris believe that all natural things
          and living things are connected, and modern Maoris
          are very concerned <sup>8</sup># the protection of the
          environment.<br>
          One important aspect of Maori culture is Ta moko –
          tattooing. Both men and women can have tattoos
          <sup>9</sup># their bodies or their faces. The tattoos
          represent the family or tribe that they belong <sup>10</sup># .
          The designs are very intricate and are now inspiring a lot of
          the designs of tattoo artists around the world.
        </div>
        `,
        answer: [
          "these",
          "although / though",
          "than",
          "was",
          "all",
          "have",
          "same",
          "about",
          "on",
          "to",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E4",
    exerciseKey: "img/FriendsPlus/Page58/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 570,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the text again. Write A for Aborigines or M for Maoris.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 180,
      textAlign: "center",
    },
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        
        <div> 
          Which people …<br>
          <b>1</b>&ensp;involve both females and males in a ritual?#<br>
          <b>2</b>&ensp;are interested in ecology?#<br>
          <b>3</b>&ensp;tried to symbolically unite their different groups?#<br>
          <b>4</b>&ensp;might have problems understanding each other?#<br>
          <b>5</b>&ensp;visually display their association with certain people?#<br>
          <b>6</b>&ensp;expect teenagers to be able to look after themselves?#<br>
        </div>
        <div style='width:25cm;margin-top:10px'>
          Anybody who is hoping to have new experiences when
          they visit Australia or New Zealand should meet the
          original inhabitants of <sup>1</sup>_________ two countries, the
          Aborigines and the Maoris. However, <sup>2</sup>_________
          both cultures have experienced similar problems caused
          by the arrival of Europeans to their lands, the Aborigines
          and the Maoris are very different.
        </div>
        <div style='width:25cm'>
          <b style='color:rgb(218,108,49);font-size:34px'>The Aborigines</b><br>
          The Aborigines have one of the oldest surviving cultures
          in the world. It is more <br><sup>3</sup>_________ 60,000 years
          old, and during this period of time, they have formed
          hundreds of tribes that have developed different customs
          and around seven hundred different languages. In 1971,
          a flag <sup>4</sup>_________ designed to represent all the
          Aborigine tribes and help unite them.<br>
          The Aborigines hold a lot of ceremonies to celebrate
          different events, but one of the most important to nearly
          <sup>5</sup>_________ of the tribes is the ‘walkabout’. It might
          seem like an adventure to people from other cultures, but
          the walkabout involves boys aged thirteen sleeping rough
          for as long as six months in the Australian desert. They
          spend the whole time alone and <sup>6</sup>_________ to hunt for
          food to survive. Once the walkabout period has finished,
          they are welcomed back by their village, which celebrates
          the arrival of a new man in the community. 
        </div>
        <div style='width:25cm'>
          <b style='color:rgb(66,130,54);font-size:34px'>The Maoris</b><br>
          The Maoris arrived in New Zealand from East Polynesia in
          the second half of the 12th century. The Maoris all speak
          the <sup>7</sup>_________ language and share the same customs,
          and this has helped them to have a strong presence in
          modern New Zealand. Maori is one of the country’s two
          official languages. Maoris believe that all natural things
          and living things are connected, and modern Maoris
          are very concerned <sup>8</sup>_________ the protection of the
          environment.<br>
          One important aspect of Maori culture is Ta moko –
          tattooing. Both men and women can have tattoos
          <sup>9</sup>_________ their bodies or their faces. The tattoos
          represent the family or tribe that they belong <sup>10</sup>_________ .
          The designs are very intricate and are now inspiring a lot of
          the designs of tattoo artists around the world.
        </div>
        `,
        answer: [
          "M / Maoris",
          "M / Maoris",
          "A / Aborigines",
          "A / Aborigines",
          "M / Maoris",
          "A / Aborigines",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E5",
    exerciseKey: "img/FriendsPlus/Page58/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Answer the questions in your own words.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 450,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "do not have problems",
          "got",
          "happen",
          "looked at",
          "really think",
          "said",
          "she didn’t say sorry",
          "showed her",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Why do you think the creation of a flag was useful for the Aborigines?<textarea id=0 rows=2 ></textarea>
        <b>2</b>&ensp;Why is ‘walkabout’ so important?<textarea id=1 rows=2 ></textarea>
        <b>3</b>&ensp;Why are Maoris so interested in looking after the environment?<textarea id=2 rows=2 ></textarea>
        <b>4</b>&ensp;How would you be able to know what tribe a Maori adult belongs to?<textarea id=3 rows=2 ></textarea>
        `,
        answer: [
          "It represents all the Aboriginal tribes and might help to unite them.",
          "It marks a change in status from adolescence to adulthood for males and it teaches them to survive in the desert.",
          "Because they believe all natural things and living things are connected.",
          "You would know by looking at their tattoos.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E6",
    exerciseKey: "img/FriendsPlus/Page58/Key/E6answerKey.png",
    audio: "Audios/2.08.mp3",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='2.08' colorimg=#b6d5b9  color='white'></headphone> Listen to a radio interview. What is Nikau’s
        attitude to his own culture? Is he proud, indifferent,
        concerned, or ignorant? How do you know?`,
        color: "black",
        width: "28cm",
        left: -20,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 600,
    },
    // character: ",",
    // hideBtnFooter: true,

    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <b>Answer:</b>
       
        <textarea id=0 rows=3></textarea>
        `,
        answer: [
          `He is proud of his culture. He knows a lot about it and speaks about it proudly.`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E7",
    exerciseKey: "img/FriendsPlus/Page58/Key/E7answerKey.png",
    audio: "Audios/2.08.mp3",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='2.08' colorimg=#b6d5b9  color='white'></headphone> Listen again and answer the questions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 860,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 600,
      // textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;What does kia ora mean?<br>#<br>
          <b>2</b>&ensp;What does whenua refer to?<br>#<br>
          <b>3</b>&ensp;What type of holiday do a lot of tourists go on in New Zealand?<br>#<br>
          <b>4</b>&ensp;Which sport are Maoris good at?<br>#<br>
          <b>5</b>&ensp;What is a haka?<br>#<br>
        </div>
       
        
        `,
        answer: [
          "It means hello in Maori.",
          "It refers to the Maoris' connection with the land.",
          "They often go on adventure holidays.",
          "They are good at rugby.",
          "A haka is a traditional Maori war dance.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E8",
    exerciseKey: "img/FriendsPlus/Page58/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss what you think about
        the customs of ‘walkabout’ and ‘Ta moko’.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
s        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P58-E9",
    exerciseKey: "img/FriendsPlus/Page58/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in groups. Find out facts about the main
        ethnic groups in northern Viet Nam, Tay and Nung. Then
        present them to the whole class.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "PROJECT", icon: "", color: "#999b9c" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
