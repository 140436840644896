import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E1",
    exerciseKey: "img/FriendsPlus/Page106/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> <br> Read paragraphs (A–C) and questions (1–6). Match the
        paragraphs with the questions. Each paragraph matches
        with two questions. `,

        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    stylesTextInput: { width: 40, textAlign: "center" },
    selectStyle: {
      marginTop: 2,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C"],
    component: T6,
    questions: [
      {
        title: `
        In which paragraph does the author mention a breakthrough which …<br>
        <div style='display:flex'><select id=0></select><b>1</b>&ensp;relieves a painful condition?</div>
        <div style='display:flex'><select id=1></select><b>2</b>&ensp;has an impact on a life-threatening situation?</div>
        <div style='display:flex'><select id=2></select><b>3</b>&ensp;is used in particular areas of the world?</div>
        <div style='display:flex'><select id=3></select><b>4</b>&ensp;replaces a device already available?</div>
        <div style='display:flex'><select id=4></select><b>5</b>&ensp;needs to be used for a short period daily?</div>
        <div style='display:flex'><select id=5></select><b>6</b>&ensp;helps people do things they couldn’t do before?</div>
        <b style='font-size:30px'>Medical breakthroughs </b>

      <div style='display:flex;width:25cm'>
        <b>A</b>
        <div style='margin-left:15px'>
            After twenty years of research, scientists have finally
            succeeded in developing a vaccine for dengue, a viral
            disease found in tropical areas. The illness causes high
            fever and severe joint pain, and in some cases, it can
            be fatal. According to the World Health Organisation,
            each year between 50 and 100 million people develop
            dengue, which is transmitted to humans by mosquitoes.
            The commercialised vaccine can protect children in Asia
            and Latin America against the virus.
        </div>
      </div>
      <div style='display:flex;width:25cm'>
       <b>B</b>
        <div style='margin-left:15px'>
            Artificial limbs have been around since ancient times,
            but no prosthetic has been as lifelike as the Bebionic
            small hand. The appliance not only looks like a hand,
            but it also works like one. Electrical impulses triggered
            by the user’s muscle movements connect to individual
            motors and powerful microprocessors in each finger,
            causing it to move. Specifically aimed at women and
            teenagers, the artificial hand enables the user to
            perform a range of activities previously unmanageable,
            such as using cutlery and riding a bike.
        </div>
      </div>
      <div style='display:flex;width:25cm'>
        <b>C</b>
        <div style='margin-left:15px'>
            Good news for migraine patients – a special device is
            available. The battery-driven headband sits across the
            forehead and over the ears and has a self-adhesive
            electrode, which helps it stay in place. This electrode
            applies an electric current to the skin and tissue below
            the headband stimulating the nerves which are said to
            trigger the headaches. By wearing the headband for the
            recommended twenty minutes per day, users are said to
            experience significantly fewer migraines.
        </div>
      </div>
 
        `,
        answer: ["C", "A", "A", "B", "C", "B"],
      },
    ],
  },
  2: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E2",
    exerciseKey: "img/FriendsPlus/Page106/Key/E2answerKey.png",
    audio: "Audios/3.25.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='3.25' colorimg=#9bc5d6  color='white'></headphone> Listen to a small talk on the song Heal the World
        and complete the text with the missing information.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 350, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            The song Heal the World in Michael Jackson’s album
            <sup>1</sup># was first released <sup>2</sup>#. It
            lasted <sup>3</sup>#. It became successful due to its
            lyrics and wish <sup>4</sup>#. Jackson himself was
            really <sup>5</sup># having written the song which
            was sung <sup>6</sup># all over the world. The song
            ranked 27 on the Billboard Hot 100. Together with the
            song, the Heal The World Foundation was also created
            by Jackson with dedication to improving
            <sup>7</sup>#. Jackson’s music and message
            <sup>8</sup># though he is no longer here. 
        </div>
         
        `,
        answer: [
          "Dangerous",
          "in November 1992",
          "6:25 minutes",
          "for humanity",
          "proud of",
          "by different artists",
          "the unlucky children's lives",
          "do live on",
        ],
      },
    ],
  },
  3: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E3",
    exerciseKey: "img/FriendsPlus/Page106/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        Work in pairs. You and a friend have been asked to design
a poster to publicise an environmental campaign among
teenagers. Discuss and agree on the following points to
decide what the poster should look like.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <ul>
            <li>Topic</li>
            <li>Image</li>
            <li>Slogan</li>
        </ul>
         
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E4",
    exerciseKey: "img/FriendsPlus/Page106/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 1 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        Your school is proposing replacing all of the textbooks with
        e-books. The school wants to listen to the ideas of students.
        Write a report (180–200 words) to the school presenting the
        advantages and disadvantages of the scheme.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E5",
    exerciseKey: "img/FriendsPlus/Page106/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E6",
    exerciseKey: "img/FriendsPlus/Page106/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page106/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E7",
    exerciseKey: "img/FriendsPlus/Page106/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Review Unit 8",
    id: "SB12-2024-U8-P106-E8",
    exerciseKey: "img/FriendsPlus/Page106/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page106/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
