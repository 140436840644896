import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E1",
    exerciseKey: "img/FriendsPlus/Page98/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Is there a problem with litter where you live? Read the
        opinions below. Who do you think should be responsible
        for reducing litter?`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: ["diseases", "hospitals", "hygiene"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: 800,
      },
    ],
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      // marginBottom: 23,
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page98/E1/1.jpg" style='height:16cm'/><br>

        `,
        answer: [
          "He had been successful thanks to his good preparations, persistence and good strategies.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E2",
    exerciseKey: "img/FriendsPlus/Page98/Key/E2answerKey.png",
    // recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples of each
        rule in exercise 1.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["diseases", "hospitals", "hygiene"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: 800,
      },
    ],
    // character: ",",
    // hideBtnFooter: true,

    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page98/E2/1.jpg" style='height:19.4cm'/><br>
       <div style='display:flex'>
            <b>1:</b>
            <div style='margin-left:15px'>
                <div style='display:flex'><b>a</b><textarea id=0 rows=2 ></textarea></div>
                <div style='display:flex'><b>b</b><textarea id=1 rows=2 ></textarea></div>
            </div>
        </div>
        <div style='display:flex'><b>2:</b><textarea id=2 rows=3></textarea></div>
        <img src="img/FriendsPlus/Page98/E1/1.jpg" style='margin-top:15px;height:15cm'/><br>
        `,
        // InputRong: true,
        answer: [
          "The people that I blame are the teenagers - it's them who cause all the problems.",
          "What they should do is employ extra staff to help clean up the streets.",
          "All the government needs to do is force companies to use biodegradable or recyclable materials in their products and the problem will be solved.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E3",
    exerciseKey: "img/FriendsPlus/Page98/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Rewrite the sentences (1–7) to make a contrast. Begin
        with It and emphasise the underlined words.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["It’s Lara who’s coming with us, not Mia."],
        styleElement: { color: "#268a9f", marginLeft: "23px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, marginLeft: 22, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // character: "-",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
            <b>1</b>&ensp;Mia isn’t coming shopping with us. <u>Lara</u> is.
            <hintbox id=0></hintbox>
            <b>2</b>&ensp;I haven’t got a credit card. I’ve got a <u>debit card</u>.<br>#<br>
            <b>3</b>&ensp;The first supermarket opened <u>in 1916</u>. It wasn’t 1920.<br>#<br>
            <b>4</b>&ensp;Her mum doesn’t work in a store. <u>Her aunt</u> does.<br>#<br>
            <b>5</b>&ensp;Many people don’t want quality. They want <u>low prices</u>.<br>#<br>
            <b>6</b>&ensp;The sales start <u>on Friday</u>. They don’t start today.<br>#<br>
            <b>7</b>&ensp;I blame <u>teenagers</u> for the litter. I don’t blame the restaurants.<br>#<br>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "It's a debit card that I've got, not a credit card.",
          "It was in 1916 that the first supermarket opened, not 1920.",
          "It's her aunt who works in a store, not her mum.| It's her aunt who / that works in a store, not her mum.",
          "It's low prices that many people want, not quality.",
          "It's on Friday that the sales start, not today.",
          "It's teenagers that I blame for the litter, not the restaurants.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E4",
    exerciseKey: "img/FriendsPlus/Page98/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find two examples in
        exercise 1. How would the sentences read without the
        emphatic structure?
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
        // prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["save", "learn", "have", "lie", "rise", "go", "work"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      fontSize: 23,
      width: 240,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          `One_thing_I_can’t_stand_is_all_the_litter_outside_fast_food_restaurants|. 
What_they_should_do_is_employ_extra_staff_to_help_clean_up_the_streets|.`,
          `The_people_that_I_blame_are_the_teenagers – it’s_them_who_cause_all_the_problems|. 
The_town_where_I_live_is_full_of_youngsters|, hanging_around_in_gangs_and_leaving_rubbish_everywhere_–_it’s_a_disgrace|.`,
          `There’s_so_much_plastic_packaging_nowadays|. 
All_the_government_needs_to_do_is_force_companies_to_use_biodegradable_or_recyclable_materials_in_their_products_and_the_problem_will_be_solved|.`,
        ],
        answers: ["1-0", "0-0"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page98/E2/1.jpg" style='height:18cm'/>
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page98/E2/2.jpg" style='height:17cm'/>
          <div style='font-size: 25px;line-height: 30px;width: 187mm;position:absolute;top:136px;left:60px'>
              <input id=0 type=Circle /> <b style='color:rgb(42,106,180)'>Rob K</b>
          </div>
          <div style='font-size: 25px;line-height: 30px;width: 187mm;position:absolute;top:276px;left:60px'>
              <input id=1 type=Circle /> <b style='color:rgb(0,147,65)'>Daniel</b>
          </div>
          <div style='font-size: 25px;line-height: 30px;width: 187mm;position:absolute;top:451px;left:60px'>
              <input id=2 type=Circle /> <b style='color:rgb(248,144,32)'>Bex</b>
          </div>
          
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E5",
    exerciseKey: "img/FriendsPlus/Page98/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the sentences (1–6). Begin with the words in
        brackets and emphasise the underlined words.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    character: /[|/]/,
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:27cm'>
            <b>1</b>&ensp;<u>Store loyalty cards</u> have really caught on. (One idea …) <textarea id=0 rows=2></textarea>
            <b>2</b>&ensp;It’s <u>the directors of companies</u> who should change. (The people …) <textarea id=1 rows=2></textarea>
            <b>3</b>&ensp;Many European firms are investing in <u>Asia</u>. (One place …) <textarea id=2 rows=2></textarea>
            <b>4</b>&ensp;<u>Greed</u> drives most consumerism. (The thing …) <textarea id=3 rows=2></textarea>
            <b>5</b>&ensp;<u>Plastic packaging</u> creates most waste. (The thing …) <textarea id=4 rows=2></textarea>
            <b>6</b>&ensp;Self-service stores first opened in the <u>USA</u>. (The country …) <textarea id=5 rows=2></textarea>
        </div>
        
        `,
        answer: [
          "One idea that has really caught on is store loyalty cards.",
          "The people that should change are the directors of companies.|The people who should change are the directors of companies. / that should change are the directors of companies.",
          "One place that many European firms are investing in is Asia.|One place many European firms are investing in is Asia.|One place (that) many European firms are investing in is Asia. / One place where many European firms are investing is Asia.",
          "The thing that drives most consumerism is greed.",
          "The thing that creates most waste is plastic packaging.",
          "The country where self-service stores first opened was the USA.|The country where self-service stores first opened was / The country where self-service stores first opened is the USA.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E6",
    exerciseKey: "img/FriendsPlus/Page98/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",
    stylesTextInput: { background: "none", width: 100 },
    titleQuestion: [
      {
        num: "6",
        title: `Complete the text with the words below. Use each word
        once.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["all", "it’s", "one", "that", "thing", "what", "where"],
        styleElement: { color: "#f48c1e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='background:rgb(217,229,219); padding:10px 20px; width:20cm '>
            The <sup>1</sup># that annoys me most is the litter. Clearly <sup>2</sup># that
            some people care about is convenience, not the environment. So
            <sup>3</sup># the government needs to do is prosecute those involved
            in anti-social behaviour. But <sup>4</sup># the businesses themselves
            that also need to take more responsibility – for example, the
            place <sup>5</sup># I usually shop has installed a security camera to
            deter people from dropping rubbish outside. And <sup>6</sup># thing
            they’ve tried successfully in some places is putting up lifesize
            cardboard police officers. It seems all <sup>7</sup># some people need
            is a gentle reminder to act responsibly.
        </div>
      
        `,
        answer: ["thing", "all", "what", "it's", "where", "one", "that"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E7",
    exerciseKey: "img/FriendsPlus/Page98/Key/E7answerKey.png",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Think of different ways to
        complete each sentence. Then compare your ideas with
        other pairs’.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='line-height:50px'>
        <b>1</b>&ensp;The places in my town that have the most litter are …<br>
        <b>2</b>&ensp;All we need to do to reduce litter is …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P98-E8",
    exerciseKey: "img/FriendsPlus/Page98/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page98/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
