import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E1",
    exerciseKey: "img/FriendsPlus/Page93/Key/E1answerKey.png",
    recorder: true,
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Is the number of people using social 
          networks increasing? Which social network do you think 
          attracts the most users?`,
        color: "black",
        left: 50,
        width: "32cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>Active users of social networks worldwide in millions (Statista)
        <img src="img/FriendsPlus/Page93/E1/1.jpg" style='height:6cm'/>
        <img src="img/FriendsPlus/Page93/E1/2.jpg" style='height:45mm'/>
        <img src="img/FriendsPlus/Page93/E1/3.jpg" style='height:15cm'/>
        </div>

        `,
        answer: [
          "locate",
          "renovate",
          "excavate",
          "build",
          "unearth",
          "remove",
          "restore",
          "display",
          "bury",
          "destroy",
          "uncover",
          "examine",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page93/Key/E2answerKey.png",
    component: T6,
    inputSize: 500,
    stylesTextInput: { width: 950 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the task and the report. Answer the questions.`,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    questions: [
      {
        title: `
      
      <div style='width:25cm;margin-left:-50px'>
        <ul>
          <li>What information does the table give?</li>#
          <li>How many social networks are mentioned?</li>#
          <li>Which network had the largest number of active users in January 2022? Which had the smallest?</li>#
          <li>Is there any information in the table you could add to the report?</li>#
        </ul>
      </div>
      <img src="img/FriendsPlus/Page93/E1/3.jpg" style='height:14cm'/>
      `,
        answer: [
          "Data of the number of people worldwide using social networks in 2022 and 2023",
          "4 social networks",
          "The largest number of active users in 2022: Facebook - The smallest number: Tiktok",
          "How the number of Tiktok users rises in 2023",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E3",
    exerciseKey: "img/FriendsPlus/Page93/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    isHello: true,
    textareaStyle: { width: 680, paddingTop: 3 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#035293'>Reading Strategy</span>. Did the writer follow all of the 
          advice? Find and underline examples.`,
        color: "black",
        left: 0,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page93/E3/1.jpg" style='height:85mm'/>
          <div>
            <textarea id=0 rows=5></textarea>
          </div>
      
        
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E4",
    exerciseKey: "img/FriendsPlus/Page93/Key/E4answerKey.png",
    video: "",

    component: Circle_Write,
    // recorder: true,
    textareaStyle: { width: 200, paddingTop: 2, resize: "none" },
    titleQuestion: [
      {
        num: "4",
        title: `Look at the phrases below. Which are in 
          the report?`,
        color: "black",
        left: 150,
        width: "30cm",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#9a2894" }],
      },
    ],
    // isHello: true,
    question: {
      leftContent: "100px",

      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { paddingTop: 0, width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 5,
        listWords: [
          `The_table_shows … <br>
          The_table_presents / describes / gives_information …`,
          `First, …<br>
          Second, …<br>
          In_contrast, …<br>
          Similarly, …<br>
          It_would_seem_that …<br>
          In_comparison_with …
          `,
        ],
        answers: ["0-0", "1-48", "1-120"],
        initialValue: [],
      },
      Layout: `
      <div style='border-left: 5px solid rgb(0,96,167);margin-left:100px;padding-left:10px'>
            <b style='color:rgb(0,96,167)'>Introducing the table</b><br>
            <div>
              <input id=0 type='Circle' />
            </div>
            <b style='color:rgb(0,96,167)'>Giving remarks</b><br>
            <div>
              <input id=1 type='Circle' />
            </div>
          </div>
          <img src="img/FriendsPlus/Page93/E1/3.jpg" style='margin:20px 100px;height:14cm'/>
      

      
      `,
    },
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E5",
    exerciseKey: "img/FriendsPlus/Page93/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    textAlign: "center",
    stylesTextInput: { width: 180, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "almost",
          "considerably",
          "suddenly",
          "slowly",
          "slightly",
          "rise",
          "change",
          "fall",
          "double",
          "plunge",
        ],
        width: 800,
        borderColor: "white",
        colorHint: "#942690",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Combine one verb and one adverb from 
          the list to complete each of the following sentences. 
          Remember to put the verbs in the correct forms.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", color: "#942690", icon: "" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='width:28cm'>
            <b>1</b>&ensp;The number of young vloggers has##for the last three months. Vlogging has become a trend.<br>
            <b>2</b>&ensp;More and more people turn to social media. The number of traditional media users is##.<br>
            <b>3</b>&ensp;The number of followers##after just a week. The YouTuber was really worried. <br>
            <b>4</b>&ensp;The number of old people using Instagram last year##compared to that in the previous year. I hardly see the difference.<br>
            <b>5</b>&ensp;The cookery videos could attract so many viewers that the profit this month##that last December.<br>
          </div>
          
      

        `,
        answer: [
          "considerably",
          "risen",
          "slowly",
          "falling",
          "suddenly",
          "plunged",
          "slightly",
          "changed",
          "almost",
          "doubled",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E6",
    exerciseKey: "img/FriendsPlus/Page93/Key/E6answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 655, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Read the task below. Write a report.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <img src="img/FriendsPlus/Page93/E6/1.jpg" style='height:45mm'/>

          <div></div>
          <img src="img/FriendsPlus/Page93/E6/2.jpg" style='height:5cm'/>
          <textarea id='0' rows=5></textarea>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P93-E7",
    exerciseKey: "img/FriendsPlus/Page93/Key/E7answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 655, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <img src="img/FriendsPlus/Page93/E6/3.jpg" style='height:60mm'/>

          <div></div>
          <textarea id='0' rows=5></textarea>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
