import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import { CgMagnet } from "react-icons/cg";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E1",
    exerciseKey: "img/FriendsPlus/Page15/Key/E1answerKey.png",
    component: T6,
    recorder: true,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. What do you understand by the
        term ‘sibling rivalry’? Why do you think it can happen?
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page15/E1/1.jpg" style='margin-left:100px;height:21cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E2",
    exerciseKey: "img/FriendsPlus/Page15/Key/E2answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 300, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the text and compare your ideas from exercise 1.`,
        color: "black",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page15/E1/1.jpg" style='margin-left:100px;height:21cm'/>
        
        `,
        answer: [
          "Under no circumstances",
          "Not only … also|Not only…also",
          "On no account",
          "Rarely",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E3",
    exerciseKey: "img/FriendsPlus/Page15/Key/E3answerKey.png",
    audio: "Audios/1.07.mp3",
    component: T6,
    // checkDuplicated: true,
    // hideBtnFooter:true,
    // textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 400, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='1.07' colorimg=#eab4b2  color='white'></headphone> Read the <span style='color:#ca0d49'> Listening Strategy</span>. Then listen and
        write down the sentences you hear.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page15/E3/1.jpg" style='height:7cm'/>
          <div style='line-height:50px'>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
          </div> 
        </div> 
        `,
        answer: [
          "What's your name?",
          "My little brother is only two.",
          "Let's go to my house.",
          "The twins have got blue eyes.",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E4",
    exerciseKey: "img/FriendsPlus/Page15/Key/E4answerKey.png",
    audio: "Audios/1.07.mp3",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.07' colorimg=#eab4b2  color='white'></headphone> Read the <span style='color:#ca0d49'>Pronunciation</span>. Listen again and
        repeat the examples.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Never have we faced such a serious problem"],
        styleElement: { color: "#3c8fa8", marginRight: 40 },
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: {
      width: 600,
      background: "none",
      paddingTop: 10,
    },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
        <img src="img/FriendsPlus/Page15/E4/1.jpg" style='margin-left:-30px;height:13cm'/>
          <img src="img/FriendsPlus/Page15/E3/1.jpg" style='height:7cm'/>
        </div>
      
      
        
        `,
        answer: [
          "Nowhere could we find a coffee shop open.",
          "Hardly had I woken up when my mobile rang.",
          "At no time were they aware of the danger.",
          "Not until next year will there be any more festivals.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E5",
    exerciseKey: "img/FriendsPlus/Page15/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      // resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Match each sentence you wrote down in exercise 3 with
        a point in the <span style='color:#ca0d49'>Pronunciation</span> box. `,
        color: "black",
        left: 50,
        width: "30cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 50,
      paddingTop: 10,
    },
    widthModalKey: 1150,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page15/E4/1.jpg" style='margin-left:-30px;height:13cm'/>
          <div style='line-height:50px'>
            <b>1</b>&ensp;What's your name?#<br>
            <b>2</b>&ensp;My little brother is only two.#<br>
            <b>3</b>&ensp;Let's go to my house.#<br>
            <b>4</b>&ensp;The twins have got blue eyes.#<br>
          </div>
        </div>
        `,
        // notSymbol: true,
        answer: [`3`, `2`, `3`, `1`],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E6",
    exerciseKey: "img/FriendsPlus/Page15/Key/E6answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      // resize: "none",
    },
    titleQuestion: [
      {
        num: "6",
        title: `Read the sentences (1–6) aloud. Each contains one
        feature of fast speech from the <span style='color:#ca0d49'>Pronunciation</span>  box. Can
        you say where in the sentence it occurs? `,
        color: "black",
        left: 50,
        width: "30cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 200,
      paddingTop: 10,
    },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='display:flex;line-height:50px'>
          <div style='margin-right:100px'>
            <b>1</b>&ensp;I’ve got two or three dictionaries. (point 1)<br>
            <b>2</b>&ensp;Is this your address? (point 1)<br>
            <b>3</b>&ensp;I’ve got a bad cough. (point 2)<br>
            <b>4</b>&ensp;We went ten-pin bowling. (point 2)<br>
            <b>5</b>&ensp;She’s my best friend. (point 3)<br>
            <b>6</b>&ensp;This coffee tastes bad. (point 3)<br>
          </div>
          <b>Answer:</b>
          <div>
            <div style='margin-left:10px'>
              <b>1</b>#<br>
              <b>2</b>#<br>
              <b>3</b>#<br>
              <b>4</b>#<br>
              <b>5</b>#<br>
              <b>6</b>#<br>
            </div>
          </div>
        </div>
        `,
        // notSymbol: true,
        answer: [
          `two or`,
          `your address`,
          `bad cough`,
          `ten-pin`,
          `best friend`,
          `tastes bad`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E7",
    exerciseKey: "img/FriendsPlus/Page15/Key/E7answerKey.png",
    audio: "Audios/1.08.mp3",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='1.08' colorimg=#eab4b2  color='white'></headphone> Listen, check and repeat. `,
        color: "black",
        width: "27cm",
        left: 50,
      },
    ],
    inputSize: 700,
    hideBtnFooter: true,
    questions: [
      {
        title: ``,
        answer: [
          "Rarely does he go out during the week.",
          "In no way was he to blame.",
          "Try not to worry about the future.",
          "She was lucky not to hurt herself badly.",
          "Only when they know the facts will they decide.",
          "Haven't we been here before?",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E8",
    exerciseKey: "img/FriendsPlus/Page15/Key/E8answerKey.png",
    audio: "Audios/1.09.mp3",
    titleQuestion: [
      {
        num: "8",
        title: `<headphone name='1.09' colorimg=#eab4b2  color='white'></headphone> Listen to a monologue and two dialogues. For
        questions (1–3), choose the correct answers (a–c).
         `,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: ["a <br> b <br> c", "a <br> b <br> c", "a <br> b <br> c"],
        answers: ["0-8", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:47px'>
      <b>1</b>&ensp;What has helped the girl improve her relationship withher sister?
         <div style='display:flex;margin-left:15px'>
          <div>
            <input/ id=0 type='Circle'>
          </div>
          <div style='margin-left:10px'>
            Spending time with each other rather than with friends.<br>
            Strengthening her sister’s relationship with their mother.<br>
            Discussing why her sister used to behave unkindly.
          </div>
         </div>
      <b>2</b>&ensp;What does the boy accuse his sister of lying about?
         <div style='display:flex;margin-left:15px'>
          <div>
            <input id=1 type='Circle'/>
          </div>
          <div style='margin-left:10px'>
            Whether she has taken something from his room.<br>
            Whether she has only just written her name on something.<br>
            Whether she asked permission before borrowing something.
          </div>
         </div>
      <b>3</b>&ensp;What do the brothers explain to the interviewer?
         <div style='display:flex;margin-left:15px'>
          <div>
            <input id=2 type='Circle'/>
          </div>
          <div style='margin-left:10px'>
            Why they didn’t get on when they were younger.<br>
            Which brother behaved worse while growing up.<br>
            Why they always spoiled each other’s things.
          </div>
         </div>
      </div>
      `,
    },
  },
  9: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P15-E9",
    exerciseKey: "img/FriendsPlus/Page15/Key/E9answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 900, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss this quotation from
        a famous British writer. What does it say about sibling
        relationships? Do you agree with its message?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page15/E9/1.jpg" style='height:2.8cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
