import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E3",
    exerciseKey: "img/FriendsPlus/Page73/Key/E3answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 800,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Look at the list of nutrition words below.
        Find one food on the eatwell plate that contains each
        thing in the list. `,
        color: "black",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Nutrition</b>",
          "additives",
          "calcium",
          "calories",
          "carbohydrate",
          "cholesterol",
          "fat",
          "fibre",
          "mineral",
          "nutrient",
          "preservative",
          "protein",
          "vitamins",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "80%",
      },
      {
        src: ["additives – sweets"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 150,
      textAlign: "center",
    },
    character: ",",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <img src="img/FriendsPlus/Page72/E1/1.jpg" style='height:12cm'/>
        <div style='line-height:50px'>
          <b>additives</b>: <textarea id=0 rows></textarea> 
          <b>calcium</b>: <textarea id=1 rows></textarea> 
          <b>calories</b>: <textarea id=2 rows></textarea> 
          <b>carbohydrate</b>: <textarea id=3 rows></textarea> 
          <b>cholesterol</b>: <textarea id=4 rows></textarea> 
          <b>fat</b>: <textarea id=5 rows></textarea> 
          <b>fibre</b>: <textarea id=6 rows></textarea> 
          <b>mineral</b>: <textarea id=7 rows></textarea> 
          <b>nutrient</b>: <textarea id=8 rows></textarea> 
          <b>preservative</b>: <textarea id=9 rows></textarea> 
          <b>protein</b>: <textarea id=10 rows></textarea> 
          <b>vitamins</b>: <textarea id=11 rows></textarea> 
        </div>
        `,
        answer: [
          "baked beans, tinned chickpeas, biscuits, fizzy drinks",
          "cheese, milk, yoghurt",
          "all foods",
          "bread, cereal, pasta, potatoes, rice",
          "eggs, meat",
          "meat, milk, nuts, oil, biscuits, cake",
          "apples, grapes, lemons, pears, asparagus, broccoli, cabbage, carrots, onions, peppers, tomatoes, bananas, sweetcorn, peas, mushrooms",
          "apples, bananas, grapes, lemons, pears, raspberries, asparagus, broccoli, cabbage, carrots, lettuce, onions, peppers, tomatoes, cheese, milk, yoghurt",
          "all food",
          "baked beans, biscuits, long life milk, tinned chickpeas",
          "cheese, milk, yoghurt, eggs, fish, meat, nuts",
          "cheese, milk, yoghurt, fish, meat, nuts, oil, apples, bananas, grapes, lemons, pears, raspberries, asparagus, broccoli, cabbage, carrots, lettuce, onions, peppers, tomatoes",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E4",
    exerciseKey: "img/FriendsPlus/Page73/Key/E4answerKey.png",
    recorder: true,
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Work in pairs. Think about your own diets.
        How do they compare to the advice on the plate?`,
        color: "black",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 150,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
      <div style='margin:10px;border:5px solid rgb(196,12,69); border-radius:15px; padding:10px 20px;width:12cm'>
        If you <span style='background:rgb(188,140,191)'>burn</span> as many calories as you take in,
        your weight remains the same, so regular
        exercise can help <span style='background:rgb(188,140,191)'>control</span> your weight.
      </div>
        `,
        answer: [
          "see",
          "take",
          "eat",
          "get away",
          "broaden",
          "have",
          "soak up",
          "try",
          "be",
          "pamper",
          "hang out",
          "enjoy",
          "meet",
          "recharge",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E5",
    exerciseKey: "img/FriendsPlus/Page73/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the quiz with the correct form of the
        highlighted verbs from the texts in exercise 2. Then do
        the quiz in pairs.  
        `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 200,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: [
          "<b>Holidays and holiday accommodation</b>",
          "adventure holiday",
          "B&B",
          "backpacking",
          "beach holiday",
          "beach house",
          "cabin",
          "camper van",
          "camping",
          "campsite",
          "caravan",
          "city break",
          "cottage",
          "couch-surfing",
          "cruise",
          "cycling holiday",
          "ecotourism",
          "guest house",
          "holiday camp",
          "holiday home",
          "hotel",
          "houseboat",
          "house swap",
          "package holiday",
          "self-catering apartment",
          "tent",
          "time-share apartment",
          "villa",
          "volunteering",
          "winter sports holiday",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { textAlign: "center", width: 120, paddingTop: 2 },
        answers: [
          "burns",
          "digest",
          "contains",
          "boost",
          "control",
          "reduce",
          "produce",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
        ],
        answers: ["0-4", "1-4", "2-8", "3-4", "4-8", "5-0", "6-0"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
       <div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(240,127,112);font-size:35px;border:4px solid rgb(240,127,112);padding:0px 12px;border-radius:50%;text-align:center;'><b>1</b></span></div>
                    <div style='margin-left:15px'>
                        Which activity <input id=0 ></input> more calories?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=0 type=Circle />
                            </div>
                            <div>
                              walking <br> cycling <br> skateboarding
                            </div>
                         </div>
                    </div>
                  </div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(8,169,152);font-size:35px;border:4px solid rgb(8,169,152);padding:0px 12px;border-radius:50%;text-align:center;'><b>2</b></span></div>
                    <div style='margin-left:15px'>
                        How long does it take your stomach to <input id=1 ></input> a three-course meal?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=1 type=Circle />
                            </div>
                            <div>
                              2 hours <br> 24 hours <br> 10 hours
                            </div>
                         </div>
                    </div>
                  </div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(4,65,112);font-size:35px;border:4px solid rgb(4,65,112);padding:0px 12px;border-radius:50%;text-align:center;'><b>3</b></span></div>
                    <div style='margin-left:15px'>
                        Which food <input id=2 ></input> the most protein?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=2 type=Circle />
                            </div>
                            <div>
                              broccoli <br> cheese <br> eggs
                            </div>
                         </div>
                    </div>
                  </div>
          </div>
          <div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(197,1,121);font-size:35px;border:4px solid rgb(197,1,121);padding:0px 12px;border-radius:50%;text-align:center;'><b>4</b></span></div>
                    <div style='margin-left:15px'>
                        Which food will <input id=3 ></input> your energy levels and help you work better?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=3 type=Circle />
                            </div>
                            <div>
                              yoghurt <br> banana <br> pastry
                            </div>
                         </div>
                    </div>
                  </div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(106,108,70);font-size:35px;border:4px solid rgb(106,108,70);padding:0px 12px;border-radius:50%;text-align:center;'><b>5</b></span></div>
                    <div style='margin-left:15px'>
                        What’s the best way to <input id=4 ></input> your weight?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=4 type=Circle />
                            </div>
                            <div>
                              Don’t eat carbohydrates.  <br> Eat three times a day.  <br> Eat healthily and do regular exercise.
                            </div>
                         </div>
                    </div>
                  </div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(141,103,116);font-size:35px;border:4px solid rgb(141,103,116);padding:0px 12px;border-radius:50%;text-align:center;'><b>6</b></span></div>
                    <div style='margin-left:15px'>
                        Which food best helps to <input id=5 ></input> the risk of heart problems?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=5 type=Circle />
                            </div>
                            <div>
                              apples <br> coconut <br> white bread
                            </div>
                         </div>
                    </div>
                  </div>
                  <div style='display:flex'>
                    <div><span style='color: rgb(113,126,157);font-size:35px;border:4px solid rgb(113,126,157);padding:0px 12px;border-radius:50%;text-align:center;'><b>7</b></span></div>
                    <div style='margin-left:15px'>
                        What is lost when you <input id=6 ></input> food in a factory?
                         <div style='display:flex'>
                            <div style='margin-left:20px'>
                             <input id=6 type=Circle />
                            </div>
                            <div>
                              nutrients <br> salt <br> additives
                            </div>
                         </div>
                    </div>
                  </div>
            </div>
        </div>
      `,
    },
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E6",
    exerciseKey: "img/FriendsPlus/Page73/Key/E6answerKey.png",
    audio: "Audios/2.18.mp3",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 50, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='2.18' colorimg=#d1b6d5  color='white'></headphone>  Listen to three people talking about food.
        Match the speakers (1–3) with what they say (A–D).
        There is one extra sentence.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Compound adjectives</b>",
          "bad-mannered",
          "easy-going",
          "hard-working",
          "light-hearted",
          "open-minded",
          "quick-witted",
          "self-confident",
          "single-minded",
          "thick-skinned",
          "well-behaved",
        ],
        width: "90%",
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex;justify-content:space-between;width:80%'>
          <span>Speaker 1: #</span>
          <span>Speaker 2: #</span>
          <span>Speaker 3: #</span>
        </div>
        <div style='line-height:50px'>
          <b>A</b>&ensp;I’ve changed my lifestyle by making an effort to change.<br>
          <b>B</b>&ensp;I can easily find food products that help me avoid health problems.<br>
          <b>C</b>&ensp;Technology is a positive tool, helping us to be healthy.<br>
          <b>D</b>&ensp;One substance in particular will help our bodies store the power they need for physical exercise.<br>
        </div>

        `,
        answer: ["C", "D", "A"],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E7",
    exerciseKey: "img/FriendsPlus/Page73/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Read the <span style='color:#92278f' >Recycle!</span> box. Then complete the sentences
        with the correct form of must, mustn’t, have to and don’t
        have to`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    character: ",",
    // checkDuplicated: true,
    questions: [
      {
        title: `
        <div>
            <b>1</b>&ensp;The trainer tells the marathon runners that they
            # eat lots of carbohydrates, but that they
            # eat fast food.<br>
            <b>2</b>&ensp;The speaker has coeliac disease, so he
            # buy gluten-free food. But he # go to
            special shops; he can find it at the supermarket.<br>
            <b>3</b>&ensp;The man’s doctor warned him that he
            # start eating more healthily.<br>
            <b>4</b>&ensp;According to the speaker, people # count
            how many calories they eat. There are apps to do it for
            them.<br>
        </div>


        `,
        answer: [
          "must",
          "mustn't",
          "has to",
          "doesn't have to",
          "had to",
          "don't have to",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E8",
    exerciseKey: "img/FriendsPlus/Page73/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { textAlign: "center", width: 400, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    character: ",",
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Use the information from the
        eatwell plate to answer the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Do you agree with the phrase ‘you are what you eat’?
            Why? / Why not?<br>
            <b>2</b>&ensp;Why do you think more and more people are
            becoming overweight? What can governments do to
            encourage people to eat more healthily?<br>
          </div>
        `,
        answer: [
          "sremote",
          "breathtaking, inaccessible",
          "overcrowded, commercialised",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E9",
    exerciseKey: "img/FriendsPlus/Page73/Key/E9answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='2.02' colorimg=#d1b6d5  color='white'></headphone> Read the <span style='color:#92278f' >Recycle!</span> box. Then
        listen again and answer the questions in
        writing.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page73/E9/1.jpg" style='height:13cm'/>
        <div style='width:25cm'>
            <b>1</b>&ensp;In dialogue 1, what does the woman wish?<br>#<br>
            <b>2</b>&ensp;In dialogue 2, how would the boy feel if he spent a few nights at Lindisfarne Castle?<br>#<br>
            <b>3</b>&ensp;In dialogue 3, what advice would the woman give if somebody wanted a relaxing holiday?<br>#<br>
        </div>
        
        
        
        `,
        answer: [
          "She wishes they were already on holiday.",
          "He would be worried about meeting ghosts.",
          "This wouldn't be a great place to visit.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P73-E10",
    exerciseKey: "img/FriendsPlus/Page73/Key/E10answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 370, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Work in pairs. Which of the
        holidays in exercise 2 would you most like
        to go on? Why?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
          Which gesture or expression might you use if …<br>
            <b>1</b>&ensp;you wish someone good luck in an exam? <br>
            <b>2</b>&ensp;you are puzzled by a question?<br>
            <b>3</b>&ensp;you don’t know the answer to a question that someone has asked?<br>
        </div>
        <img src="img/FriendsPlus/Page73/E10/1.jpg" style='height:4cm'/>

        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
};

export default json;
