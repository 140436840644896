import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E1",
    exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Read the postcard. What country is Josh meeting Emma in?`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:20cm'>
            Hi Emma,<br>
            Greetings from Amsterdam! (Although, by the time
            you read this, I’ll probably be travelling to Berlin.)
            Amsterdam is great! I’ve done so much already – I’m afraid,
            I’ll have run out of energy before the end of the trip! Luckily, this
            time tomorrow I’ll be on a canal cruise, so that should be a bit
            calmer. I hope you’re not studying too hard – I’ll think of you while
            I’m relaxing on the boat! It’s great that you’ll be joining me in Paris
            next month. Guess what? Maroon 5 are touring there. I’ll buy some
            tickets for us. Right, I’d better go, but I promise I’ll send another
            postcard as soon as I’ve arrived in Berlin.<br>
            See you soon!<br>
            Josh
        </div>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E2",
    exerciseKey: "img/FriendsPlus/Page52/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples for rules
        (1–3) in exercise 1.
        
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 450, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page52/E2/1.jpg" style='margin-left:-50px;height:18cm'/><br>
        <div style='width:20cm'>
            Hi Emma,<br>
            Greetings from Amsterdam! (Although, by the time
            you read this, I’ll probably be travelling to Berlin.)
            Amsterdam is great! I’ve done so much already – I’m afraid,
            I’ll have run out of energy before the end of the trip! Luckily, this
            time tomorrow I’ll be on a canal cruise, so that should be a bit
            calmer. I hope you’re not studying too hard – I’ll think of you while
            I’m relaxing on the boat! It’s great that you’ll be joining me in Paris
            next month. Guess what? Maroon 5 are touring there. I’ll buy some
            tickets for us. Right, I’d better go, but I promise I’ll send another
            postcard as soon as I’ve arrived in Berlin.<br>
            See you soon!<br>
            Josh
        </div>
        <b>Answer:</b><br>
        <b>1</b>#<br>
        <b>2</b>#<br>
        <b>3</b>#<br>
        
        `,
        // InputRong: true,
        answer: [
          "by the time you read this",
          "as soon as I've arrived in Berlin",
          "while I'm relaxing on the boat!",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E3",
    exerciseKey: "img/FriendsPlus/Page52/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct verb forms (a–c). `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 350, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 100, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-0", "1-0", "2-8", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
          <b>1</b>&ensp;He’ll let us know the moment to the festival. 
             <div style='display:flex'>
               <div style='margin-left:15px'><input id=0 type='Circle' /></div>
               <div>
                    he gets <br>
                    he will have got <br>
                    he’s getting <br>
               </div>
             </div>
          <b>2</b>&ensp;We’ll have lunch after some sightseeing. 
             <div style='display:flex'>
               <div style='margin-left:15px'><input id=1 type='Circle' /></div>
               <div>
                    we’ve done <br>
                    we’re doing <br>
                    we do <br>
               </div>
             </div>
          <b>3</b>&ensp;The hostel won’t reserve a room until in full.
             <div style='display:flex'>
               <div style='margin-left:15px'><input id=2 type='Circle' /></div>
               <div>
                    we’re paying <br>
                    we’ll pay <br>
                    we pay <br>
               </div>
             </div>
          <b>4</b>&ensp;You could write some postcards while breakfast.
             <div style='display:flex'>
               <div style='margin-left:15px'><input id=3 type='Circle' /></div>
               <div>
                    you’ll be having <br>
                    you’re having <br>
                    you’ve had <br>
               </div>
             </div>
          <b>5</b>&ensp;Let’s take plenty of suncream in case really hot.
             <div style='display:flex'>
               <div style='margin-left:15px'><input id=4 type='Circle' /></div>
               <div>
                    it’ll be <br>
                    it’s <br>
                    it’s being <br>
               </div>
             </div>
      
      
      `,
    },
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E4",
    exerciseKey: "img/FriendsPlus/Page52/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the email with the correct form of the verbs below.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page52/E4/1.jpg" style='height:17cm'/>
          <div style='position:absolute;top:125px;left:56px;width:20cm'>
              Hi Matt,
              <div style='margin:10px 0px'>
                  As soon as I <sup>1</sup># the festival tickets we booked, I <br><sup>2</sup>#
                  you know. They may arrive tomorrow. The journey should be
                  quite easy. When we <sup>3</sup># at the station, we <sup>4</sup># the ten
                  o’clock train and we <br><sup>5</sup># there by noon. While we <sup>6</sup>#
                  for the tents to be put up, we will buy some food and drinks at
                  a nearby convenience store. By the time the sun <sup>7</sup># , we <sup>8</sup># a fire and <sup>9</sup># our dinner. I can’t wait to see you
                  next Friday.
              </div>
              Pete
          </div>
        </div>
        `,
        answer: [
          "receive",
          "'ll let|will let",
          "arrive",
          "'ll catch|will catch",
          "'ll have got|will have got",
          "are waiting",
          "sets",
          "'ll have built|will have built",
          "had",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E5",
    exerciseKey: "img/FriendsPlus/Page52/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the sentences using the words in brackets. `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 500,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "in three hours from now",
          "at midnight tonight",
          "in three months’ time",
          "by this time next year",
          "by your 20th birthday",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: "70%",
      },
    ],
    character: /[/|]/,
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;I won’t get there before it’s dark. (by the time) <textarea id=0 rows=2></textarea>
          <b>2</b>&ensp;Finish your homework, and I’ll let you watch TV. (once) <textarea id=1 rows=2></textarea>
          <b>3</b>&ensp;When Josh gets here, we’ll go out. (until) <textarea id=2 rows=2></textarea>
          <b>4</b>&ensp;We won’t pay for the room until we arrive. (when) <textarea id=3 rows=2></textarea>
          <b>5</b>&ensp;He’ll talk to me and then he’ll email you. (after) <textarea id=4 rows=2></textarea>
        </div>

        `,
        answer: [
          "By the time I get there, it will be dark.|By the time I get there, it'll be dark. / It'll be dark by the time I get there.|It will be dark by the time I get there.",
          "Once you have finished your homework, I will let you watch TV.|Once you have finished your homework, I'll let you watch TV.|Once you've finished your homework, I will let you watch TV.|Once you've finished your homework, I'll let you watch TV.",
          "We will not go out until Josh gets here.|We won't go out until Josh gets here.",
          "We will pay for the room when we arrive.|We'll pay for the room when we arrive. / When we arrive, we'll pay for the room.|When we arrive, we will pay for the room.",
          "He will email you after he is talked to me.|He'll email you after he is talked to me.|He will email you after he's talked to me.|He'll email you after he's talked to me. / After he's talked to me, he'll email you.|After he is talked to me, he'll email you.|After he's talked to me, he will email you.|After he is talked to me, he will email you.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E6",
    exerciseKey: "img/FriendsPlus/Page52/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",
    recorder: true,
    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Think of an appropriate way to finish each
        sentence. Then compare your ideas with your partner’s.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <ul>
          <li>By the time I’ve left school, …</li>
          <li>As soon as I finish this task, …</li>
          <li>The minute I get home, …</li>
        </ul>
        <img src="img/FriendsPlus/Page52/E6/1.jpg" style='height:3.3cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E7",
    exerciseKey: "img/FriendsPlus/Page52/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page52/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P52-E8",
    exerciseKey: "img/FriendsPlus/Page52/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page52/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
