import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E1",
    exerciseKey: "img/FriendsPlus/Page129/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Correct the mistakes in the reported sentences.
`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 800, paddingTop: 10 },
    hintBox: [
      {
        src: ["be in charge of", "cut", "request", "resign"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 900,
        marginLeft: 200,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

    <div style='width:26cm'>    
            <b>1</b>&ensp;Pat refused listening to my explanation.<textarea id=0 rows=2></textarea >
            <b>2</b>&ensp;They advised me go to hospital immediately.<textarea id=1 rows=2></textarea >
            <b>3</b>&ensp;We congratulated him that he passed his driving test.<textarea id=2 rows=2></textarea >
            <b>4</b>&ensp;She suggested us having a picnic in the park.<textarea id=3 rows=2></textarea >
            <b>5</b>&ensp;I confessed losing my sister’s new scarf.<textarea id=4 rows=2></textarea >
            <b>6</b>&ensp;He invited us go to his party that Friday.<textarea id=5 rows=2></textarea >
            <b>7</b>&ensp;I begged him not telling anyone my secret.<textarea id=6 rows=2></textarea >
            <b>8</b>&ensp;Oli insisted to paid me half the taxi fare.<textarea id=7 rows=2></textarea >
            <b>9</b>&ensp;He encouraged us that we should keep trying.<textarea id=8 rows=2></textarea >
            <b>10</b>&ensp;Fiona threatened calling the police. <textarea id=9 rows=2></textarea >
        </div>
        `,
        // InputRong: true,
        answer: [
          "Pat refused to listen to my explanation.",
          "They advised me to go to hospital immediately.",
          "We congratulated him on passing his driving test.",
          "She suggested having a picnic in the park. / She suggested (that) we have a picnic in the park.",
          "I confessed to losing my sister's new scarf.",
          "He invited us to go to his party that Friday.",
          "I begged him not to tell anyone my secret.",
          "Oli insisted on paying me half the taxi fare.",
          "He encouraged us to keep trying.",
          "Fiona threatened to call the police.",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E2",
    exerciseKey: "img/FriendsPlus/Page129/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with a preposition if necessary
and the correct form of the verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: {
      marginLeft: 20,
      textAlign: "center",
      width: 250,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "drop",
          "leave",
          "lose",
          "make",
          "play",
          "spend",
          "study",
          "visit",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

        <div style='width:26cm'>
            <b>1</b>&ensp;My neighbour accused me # litter in his garden.<br>
            <b>2</b>&ensp;He apologised # so much noise.<br>
            <b>3</b>&ensp;Our teacher reminded us # the next chapter for homework.<br>
            <b>4</b>&ensp;Ian blamed his brother # his favourite T-shirt.<br>
            <b>5</b>&ensp;The manager ordered everyone # the building.<br>
            <b>6</b>&ensp;The fans demanded that the band # one more song.<br>
            <b>7</b>&ensp;I admitted # all my money on a leather jacket.<br>
            <b>8</b>&ensp;They recommended that we # the new art gallery
        </div>
        `,
        // InputRong: true,
        answer: [
          "of dropping",
          "for making",
          "to study",
          "for losing",
          "to leave",
          "should play / play",
          "spending",
          "should visit / visit",
        ],
      },
    ],
  },
  3: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E3",
    exerciseKey: "img/FriendsPlus/Page129/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with <i>in, for, on, to</i> or <i>with</i>.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about", "at", "by", "from", "of", "on", "to", "with"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 70, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;Scientists are still searching for a solution # the problem of global warming.<br>
            <b>2</b>&ensp;I don’t know what the matter # my watch is, but it’s stopped working.<br>
            <b>3</b>&ensp;What’s the difference # price between these two pairs of boots?<br>
            <b>4</b>&ensp;Do you have a preference # tea or coffee?<br>
            <b>5</b>&ensp;I didn’t use to like spicy food but I’ve got a taste # it since visiting India.<br>
            <b>6</b>&ensp;A recent earthquake has had a terrible effect # some countries.<br>
            <b>7</b>&ensp;There’s been a rise # prices since the new government came to power.<br>
            <b>8</b>&ensp;No one could explain the reason # the broken window.<br>
        </div>
        `,
        // InputRong: true,
        answer: ["to", "with", "in", "for", "for", "on", "in", "for"],
      },
    ],
  },
  4: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E4",
    exerciseKey: "img/FriendsPlus/Page129/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with adjectives formed from the
        nouns in brackets.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "265px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          color: "white",
          //   border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          //   border: "2px solid",
          //   borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `
      
     <div style='line-height:50px;display:flex'>
            <div style='margin-right:250px'>
                <b>1</b>&ensp;let someone know<br>
                <b>2</b>&ensp;look forward to<br>
                <b>3</b>&ensp;put off<br>
                <b>4</b>&ensp;hand in<br>
                <b>5</b>&ensp;stand for<br>
                <b>6</b>&ensp;leave out<br>
            </div>
            <div>
                <b>a</b>&ensp;represent<br>
                <b>b</b>&ensp;submit<br>
                <b>c</b>&ensp;inform<br>
                <b>d</b>&ensp;anticipate<br>
                <b>e</b>&ensp;omit<br>
                <b>f</b>&ensp;postpone<br>
            </div>
     </div>
      `,
    },
  },
  5: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E5",
    exerciseKey: "img/FriendsPlus/Page129/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the words
        and phrases below. Say which sentence is formal or informal.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "apologise",
          "inform",
          "let you know",
          "postpone",
          "put off",
          "sorry",
        ],
        styleElement: {
          color: "#91268e",
          marginBottom: 10,
          marginRight: "40px",
        },
        width: "90%",
      },
    ],
    character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
            <b>1</b>&ensp; # about breaking your vase!<br>
            <b>2</b>&ensp;We are writing to # for the late delivery of your order.<br>
            <b>3</b>&ensp;We regret to # you that the item you requested is no longer in stock.<br>
            <b>4</b>&ensp;Just thought I’d better # that we can’t make your party.<br>
            <b>5</b>&ensp;We regret to announce that the meeting has been #.<br>
            <b>6</b>&ensp;We’ll have to # that dinner till next week.<br>
        </div>
        
        `,
        answer: [
          "Sorry (informal)|(informal)",
          "apologise (formal)|(formal)",
          "inform (formal)|(formal)",
          "let you know (informal)|(informal)",
          "postponed (formal)|(formal)",
          "put off (informal)|(informal)",
        ],
      },
    ],
  },
  6: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E6",
    exerciseKey: "img/FriendsPlus/Page129/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E7",
    exerciseKey: "img/FriendsPlus/Page129/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page129/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:15px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P129-E8",
    exerciseKey: "img/FriendsPlus/Page129/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page129/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
