import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E1",
    exerciseKey: "img/FriendsPlus/Page8/Key/E1answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Read the forum posts and
        discuss the opinions. Do you agree with them? Think of
        two more things that are important in a best friend.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    recorder: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page8/E1/1.jpg" style='margin-left:100px;height:19cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E2",
    exerciseKey: "img/FriendsPlus/Page8/Key/E2answerKey.png",
    component: T6,
    checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 200, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the highlighted verbs in the forum posts with the
        verb patterns below.`,
        color: "black",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page8/E1/1.jpg" style='margin-left:100px;height:19cm'/>
        
 <table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Verb + -ing form:</th>
    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Verb + infinitive:</th>
    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Verb + object + infinitive:</th>
    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Verb + object + preposition + -ing form:</th>
    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Verb + preposition + -ing form:</th>
  </tr>
  <tr>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
  </tr>
  <tr>
    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
  </tr>
  <tr>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
  </tr>
</table>
        
        `,
        InputRong: true,
        answer: [
          "spend time|mind|",
          "hope|decide|choose",
          "advise|",
          "warn|",
          "apologise|",
          "spend time|mind|",
          "hope|decide|choose",
          "advise|",
          "warn|",
          "apologise|",
          "spend time|mind|",
          "hope|decide|choose",
          "advise|",
          "warn|",
          "apologise|",
        ],
      },
    ],
  },

  3: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E3",
    exerciseKey: "img/FriendsPlus/Page8/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Choose the correct form to complete the sentences. ",
        color: "black",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "to_copy / copying",
          "to_study / studying",
          "to_visit / visiting",
          "to_go / going",
          "to_apply / applying",
          "to_volunteer / volunteering",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px'>
        <b>1</b>&ensp;Jack admitted <input id=0 type='Circle'/> the test.<br>
        <b>2</b>&ensp;Amy hopes <input id=1 type='Circle'/> medicine.<br>
        <b>3</b>&ensp;We really enjoyed <input id=2 type='Circle'/> the exhibition together.<br>
        <b>4</b>&ensp;I agreed <input id=3 type='Circle'/> to help him with his homework.<br>
        <b>5</b>&ensp;Mark encouraged me <input id=4 type='Circle'/> for the summer job.<br>
        <b>6</b>&ensp;They praised him for <input id=5 type='Circle'/>.<br>
      </div>
      `,
    },
  },

  4: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E4",
    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the dialogue with the correct form of the
        verbs below.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    component: T6,
    character: "/",
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 250, textAlign: "center", paddingTop: 10 },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex'>
          <div>
            <b>Max</b><br>
            <b>Lily</b><br><br>
            <b>Max</b><br>
            <b>Lily</b><br><br><br>
            <b>Max</b><br>
            <b>Lily</b><br><br><br>
            <b>Max</b>
          </div>
          <div style='margin-left:20px;width:90%'>
             Hi, Lily. You look a bit anxious. What’s the matter?<br>
             Well, I was <sup>1</sup># of going to Amy’s, but
             she’s just texted me to say she’s busy all weekend.
             I think she’s angry with me.<br>
             Why? I thought you were good friends.<br>
             We are, but she <sup>2</sup># me to go out with
             her last Saturday and I <br><sup>3</sup># her about
             Monday’s maths exam and said we should stay in
             and study.<br>
             That seems reasonable. So what’s the problem?<br>
             Well, she <sup>4</sup># on going out and of course
             she didn’t study. She didn’t do well in the exam and
             now she’s <sup>5</sup># me of being a bad friend
             because I didn’t stop her from going out!<br>
             That’s really unfair! She should be ashamed of
             herself. In my opinion, she should <br><sup>6</sup># for
             being so unreasonable!
          </div>
        </div>
      
      
        
        `,
        answer: [
          "thinking",
          "asked",
          "reminded",
          "insisted",
          "accused / accusing",
          "apologise",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E5",
    exerciseKey: "img/FriendsPlus/Page8/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Find adjectives in the dialogue that correspond with
        these nouns: <i>anger, anxiety, shame</i>.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <b>Max</b><br>
            <b>Lily</b><br><br>
            <b>Max</b><br>
            <b>Lily</b><br><br><br>
            <b>Max</b><br>
            <b>Lily</b><br><br><br>
            <b>Max</b>
          </div>
          <div style='margin-left:20px;width:90%'>
             Hi, Lily. You look a bit anxious. What’s the matter?<br>
             Well, I was thinking of going to Amy’s, but
             she’s just texted me to say she’s busy all weekend.
             I think she’s angry with me.<br>
             Why? I thought you were good friends.<br>
             We are, but she asked me to go out with
             her last Saturday and I reminded her about
             Monday’s maths exam and said we should stay in
             and study.<br>
             That seems reasonable. So what’s the problem?<br>
             Well, she insisted on going out and of course
             she didn’t study. She didn’t do well in the exam and
             now she’s accused me of being a bad friend
             because I didn’t stop her from going out!<br>
             That’s really unfair! She should be ashamed of
             herself. In my opinion, she should apologise for
             being so unreasonable!
          </div>
        </div>
        <div style='margin-top:20px'>
          anger-#<br>
          anxiety-#<br>
          shame-#<br>
        </div>
      
      
        
        `,
        answer: ["angry", "anxious", "ashamed"],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E6",
    exerciseKey: "img/FriendsPlus/Page8/Key/E6answerKey.png",
    component: T6,
    checkDuplicated: true,
    textAlign: "center",
    stylesTextInput: { borderBottom: "none", width: 170, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "continue",
          "forget",
          "go on",
          "like",
          "prefer",
          "remember",
          "start",
          "stop",
          "try",
        ],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#92278f'>Learn this!</span> box. Put the verbs below into the
        correct group (1 or 2) in the <span style='color:#92278f'>Learn this!</span> box. `,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='display:flex'>
          <img src="img/FriendsPlus/Page8/E6/1.jpg" style='margin-left:-40px;height:5cm'/>
          <div>
            <table style="width: 50%; border-collapse: collapse;">
              <tr>
                <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">1</th>
                <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">2</th>
              </tr>
              <tr>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
              </tr>
              <tr>
                <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
                <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
              </tr>
              <tr>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
              </tr>
              <tr>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
              </tr>
              <tr>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
              </tr>
            </table>
          </div>                                            
      </div>
        
        `,

        InputRong: true,
        answer: [
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E7",
    exerciseKey: "img/FriendsPlus/Page8/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title:
          "Choose the correct form of the verbs to complete the sentences. Sometimes both answers are correct.",
        color: "black",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "to_go / going",
          "to_tell / telling",
          "to_laugh / laughing",
          "to_talk / talking",
        ],
        answers: ["0-0", "0-4", "1-0", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px'>
        
        <b>1</b>&ensp;I don’t like <input id=0 type='Circle'/> to discos.<br>
        <b>2</b>&ensp;Did you remember <input id=1 type='Circle'/> Ewan about the party?<br>
        <b>3</b>&ensp;I tried not <input id=2 type='Circle'/>, but I couldn’t stop myself.<br>
        <b>4</b>&ensp;Anna told me about her holidays; then she went on <input id=3 type='Circle'/> about life at university.<br>
      </div>
      `,
    },
  },
  8: {
    unit: "Introduction",
    id: "SB12-2024-I-P8-E8",
    exerciseKey: "img/FriendsPlus/Page8/Key/E8answerKey.png",
    component: T6,
    recorder: true,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Tell your partner about
        something you did recently. Use verbs from this lesson.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
