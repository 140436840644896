import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E1",
    exerciseKey: "img/FriendsPlus/Page115/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the present perfect simple
        or present perfect continuous form of the verbs in
        brackets.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 300, paddingTop: 10 },
    hintBox: [
      {
        src: ["be in charge of", "cut", "request", "resign"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 900,
        marginLeft: 200,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;I #(search) for hours but I #(not find) my phone yet.<br>
            <b>2</b>&ensp;I #(visit) the USA a few times but I #(never see) a baseball game.<br>
            <b>3</b>&ensp;#(you / see) my watch? I #(look) for it since this morning.<br>
            <b>4</b>&ensp;I #(just / talk) to Abby on the phone. Do you want a quick word with her?<br>
            <b>5</b>&ensp;I’ve #(eat) all morning. I’m so full!<br>
            <b>6</b>&ensp;‘ #(you / see) Martha?’ ‘Yes. She ‘ #(work) in the library. You can go along and see her.’<br>
            <b>7</b>&ensp;I’m tired because I #(play) three tennis matches today.<br>
            <b>8</b>&ensp;We #(watch) Twilight. Do you want to see the end with us?<br>
            <b>9</b>&ensp;Fantastic! England #(win) the world championship!<br>
            <b>10</b>&ensp;you ever #(take) part in a sports competition?<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "'ve been searching",
          "haven't found",
          "'ve visited",
          "'ve never seen",
          "Have you seen",
          "'ve been looking",
          "'ve just been talking",
          "been eating",
          "Have you seen",
          "'s been working",
          "'ve played",
          "'ve been watching",
          "have won",
          "Have taken",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E2",
    exerciseKey: "img/FriendsPlus/Page115/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the
        verbs below. Use each verb twice, once in a simple and
        once in a continuous form.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["have", "look", "see", "think"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:26cm'>
            <b>1</b>&ensp;I noticed that a man # at our car.<br>
            <b>2</b>&ensp;My great-grandfather # a clothes shop in London in the 1950s.<br>
            <b>3</b>&ensp;I # the doctor tomorrow about my cough.<br>
            <b>4</b>&ensp;I missed my stop because I # about dinner.<br>
            <b>5</b>&ensp;When we arrived, the teachers # a meeting.<br>
            <b>6</b>&ensp;It’s raining, but I # it will stop soon.<br>
            <b>7</b>&ensp;I # that you’ve been eating my crisps!<br>
            <b>8</b>&ensp;That game # fun. Can I have a go?<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "was looking",
          "had",
          "'m seeing|am seeing",
          "was thinking",
          "were having",
          "think",
          "see",
          "looks",
        ],
      },
    ],
  },
  3: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E3",
    exerciseKey: "img/FriendsPlus/Page115/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the correct form of the
        verbs in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about", "at", "by", "from", "of", "on", "to", "with"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 250, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

        <div style='width:27cm'>
        <div style='display:flex'>
              <b>1</b>
              <div style='margin-left:20px'>
                ‘Stop it! You #(behave) selfishly.’ <br>
                ‘Why? I #(not understand) what you #(mean).’
              </div>
            </div>
            <div style='display:flex'>
              <b>2</b>
              <div style='margin-left:20px'>
                ‘Why #(you / laugh) at my T-shirt?’ <br>
                ‘Because it #(not fit) you!’
              </div>
            </div>
            <div style='display:flex'>
              <b>3</b>
              <div style='margin-left:20px'>
                ‘This bag that I #(carry) weighs a tonne!’<br>
                ‘I #(know). It #(contain) all my books.’
              </div>
            </div>
            <div style='display:flex'>
              <b>4</b>
              <div style='margin-left:20px'>
                ‘ #(you / like) this play?’ <br>
                ‘Not really, but it #(not matter). I #(not mind) staying until the end.’
              </div>
            </div>
            <div style='display:flex'>
              <b>5</b>
              <div style='margin-left:20px'>
                ‘We #(need) to go home. It’s midnight!’ <br>
                ‘I #(not care). I #(enjoy) myself!’
              </div>
            </div>
            </div>
            
        `,
        // InputRong: true,
        answer: [
          "'re behaving",
          "don't understand",
          "mean",
          "are you laughing",
          "doesn't fit",
          "'m carrying",
          "know",
          "contains",
          "Do you like",
          "doesn't matter",
          "don't mind",
          "need",
          "don't care",
          "'m enjoying",
        ],
      },
    ],
  },
  4: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E4",
    exerciseKey: "img/FriendsPlus/Page115/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with adjectives formed from the
        nouns in brackets.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "265px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          color: "white",
          //   border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          //   border: "2px solid",
          //   borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `
      
     <div style='line-height:50px;display:flex'>
            <div style='margin-right:250px'>
                <b>1</b>&ensp;let someone know<br>
                <b>2</b>&ensp;look forward to<br>
                <b>3</b>&ensp;put off<br>
                <b>4</b>&ensp;hand in<br>
                <b>5</b>&ensp;stand for<br>
                <b>6</b>&ensp;leave out<br>
            </div>
            <div>
                <b>a</b>&ensp;represent<br>
                <b>b</b>&ensp;submit<br>
                <b>c</b>&ensp;inform<br>
                <b>d</b>&ensp;anticipate<br>
                <b>e</b>&ensp;omit<br>
                <b>f</b>&ensp;postpone<br>
            </div>
     </div>
      `,
    },
  },
  5: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E5",
    exerciseKey: "img/FriendsPlus/Page115/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the words
        and phrases below. Say which sentence is formal or informal.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "apologise",
          "inform",
          "let you know",
          "postpone",
          "put off",
          "sorry",
        ],
        styleElement: {
          color: "#91268e",
          marginBottom: 10,
          marginRight: "40px",
        },
        width: "90%",
      },
    ],
    character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
            <b>1</b>&ensp; # about breaking your vase!<br>
            <b>2</b>&ensp;We are writing to # for the late delivery of your order.<br>
            <b>3</b>&ensp;We regret to # you that the item you requested is no longer in stock.<br>
            <b>4</b>&ensp;Just thought I’d better # that we can’t make your party.<br>
            <b>5</b>&ensp;We regret to announce that the meeting has been #.<br>
            <b>6</b>&ensp;We’ll have to # that dinner till next week.<br>
        </div>
        
        `,
        answer: [
          "Sorry (informal)|(informal)",
          "apologise (formal)|(formal)",
          "inform (formal)|(formal)",
          "let you know (informal)|(informal)",
          "postponed (formal)|(formal)",
          "put off (informal)|(informal)",
        ],
      },
    ],
  },
  6: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E6",
    exerciseKey: "img/FriendsPlus/Page115/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E7",
    exerciseKey: "img/FriendsPlus/Page115/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page115/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:15px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P115-E8",
    exerciseKey: "img/FriendsPlus/Page115/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page115/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
