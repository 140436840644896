import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import Recorder from "../../components/Recorder";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E1",
    exerciseKey: "img/FriendsPlus/Page105/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Think of two advantages and
        two disadvantages of banning traffic from town centres.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    character: ",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
       
       

        `,
        answer: [
          "biscuits, cakes, chocolate, sweets, ice cream, candyfloss",
          "Sugar contains a lot of calories. It can cause tooth decay and it makes you gain weight.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E2",
    exerciseKey: "img/FriendsPlus/Page105/Key/E2answerKey.png",
    // checkDuplicated: true,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the task below. Identify the elements that need to
        be included in the article. How many paragraphs do you
        think it should have?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page105/E1/1.jpg" style='height:7cm'/>
          <div style='line-height:50px'>
            <b>1</b>&ensp;Which points in the report did you also mention in exercise 1?<br>
            <b>2</b>&ensp;Are there any other points in the report that you think are good?<br>
          </div>
          
        `,
        // InputRong: true,
        answer: ["Paragraphs 2, 4", "Paragraphs 3"],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E3",
    exerciseKey: "img/FriendsPlus/Page105/Key/E3answerKey.png",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#2683c6' >Writting Strategy</span>.  Did the writer follow all
        of the advice? Find and underline evidence for your
        opinions.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Language focus: formal language</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 450, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page105/E3/1.jpg" style='height:10cm'/>
        <textarea id=0 rows=6></textarea>
        `,
        answer: [
          "The writer writes in a formal style which is suitable for a newspaper. The first sentence states the purpose (The aim of this report is to assess the plan to ban traffic from the town centre.) and the final paragraph ends with a suggestion (I would suggest that the council press ahead with its plan as it will improve the quality of life of both residents and visitors). The writer has included four headings.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E4",
    exerciseKey: "img/FriendsPlus/Page105/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Look at the phrases below. Which are in
        the report?
        `,
        color: "black",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Introducing the subject</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
      {
        src: ["<b>Reporting views</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
      {
        src: ["<b>Summing up</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
      {
        src: ["<b>Recommending / Suggesting</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 40,
        listWords: [
          `The_aim_of_this_report_is_to_…<br>
The_report_is_based_on_/_draws_on_…<br>`,
          `It_would_seem_that_…<br>
According_to_…<br>
It_was_reported_that_…<br>
(People)|_were_of_the_opinion_that_…<br>`,
          `To_sum_up,_…<br>
On_balance_…<br>
In_conclusion_…<br>
Taking_all_the_points_into_consideration,_…<br>`,
          `It_is_recommended_that_…<br>
I_would_suggest_…<br>
In_my_opinion,_it_would_be_worth_…<br>`,
        ],
        answers: ["0-0", "1-2", "3-2", "1-0"],
        initialValue: [],
      },
      Layout: `

    <div style='margin-bottom:10px;border-left:5px solid rgb(38,131,198);padding-left:20px'>
      <hintbox id=0></hintbox>
      <input id=0 type=Circle />
      <hintbox id=1></hintbox>
      <input id=1 type=Circle />
      <hintbox id=2></hintbox>
      <input id=2 type=Circle />
      <hintbox id=3></hintbox>
      <input id=3 type=Circle />
    </div>
      `,
    },
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E5",
    exerciseKey: "img/FriendsPlus/Page105/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with <i>Moreover, However, Therefore, What’s</i> more.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 200,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Comment adverbs</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,

    component: T6,
    questions: [
      {
        title: `
      <div style='width:26cm'>
        <b>1</b>&ensp;The roads around the school are quite dangerous. #, students probably wouldn’t cycle to school.<br>
        <b>2</b>&ensp;Bus tickets are not very expensive. #, buying a season ticket is even more economical.<br>
        <b>3</b>&ensp;It’s difficult to cross the road by the school. #, there’s a plan to put in a pedestrian crossing.<br>
        <b>4</b>&ensp;Too many parents drive their children to school. #, they all arrive at about the same time.<br>
      </div>

        `,
        answer: ["Therefore", "Moreover", "However", "What's more"],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E6",
    exerciseKey: "img/FriendsPlus/Page105/Key/E6answerKey.png",
    checkDuplicated: true,

    titleQuestion: [
      {
        num: "6",
        title: `Read the task below. Write your report (180–200 words).`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // character: ",",
    hideBtnFooter: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    component: T6,
    questions: [
      {
        title: `
      <img src="img/FriendsPlus/Page105/E6/1.jpg" style='height:4cm'/>

      <textarea id=0 rows=5 ></textarea>

        `,
        answer: ["Therefore", "Moreover", "However", "What's more"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E7",
    exerciseKey: "img/FriendsPlus/Page105/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Read the task below. Make notes on your opinions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 700,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <img src="img/FriendsPlus/Page105/E7/1.jpg" style='height:5cm'/>
       
        <textarea id=0 rows=3></textarea>
        `,
        answer: [
          `I think I'll choose to travel by coach , mainly because …  
The best option would (again) be the coach.  
That's because you travel …`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E8",
    exerciseKey: "img/FriendsPlus/Page105/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Plan your article in your notebook. Organise your ideas
        into paragraphs and link them. Then write your article
        (180–200 words).`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=3></textarea>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P105-E9",
    exerciseKey: "img/FriendsPlus/Page105/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
