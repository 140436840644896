import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import Recorder from "../../components/Recorder";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E1",
    exerciseKey: "img/FriendsPlus/Page81/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          <b>1</b>&ensp;Do you have a sweet tooth? What are your favourite sweet treats?<textarea id=0 rows=2></textarea>
          <b>2</b>&ensp;Why is too much added sugar bad for you? What problems can it cause?<textarea id=1 rows=2></textarea>
        </div>
       

        `,
        answer: [
          "biscuits, cakes, chocolate, sweets, ice cream, candyfloss",
          "Sugar contains a lot of calories. It can cause tooth decay and it makes you gain weight.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E2",
    exerciseKey: "img/FriendsPlus/Page81/Key/E2answerKey.png",
    // checkDuplicated: true,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the task below. Identify the elements that need to
        be included in the article. How many paragraphs do you
        think it should have?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      // resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character: ",",
    hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
          <div>
            <img src="img/FriendsPlus/Page81/E2/1.jpg" style='height:5cm'/><br>
          
          </div>
          <textarea id=0 rows=2 ></textarea>
        `,
        // InputRong: true,
        answer: ["Paragraphs 2, 4", "Paragraphs 3"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E3",
    exerciseKey: "img/FriendsPlus/Page81/Key/E3answerKey.png",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the article and compare it to your ideas in exercise 2.
        Are there any differences? 
         Find formal expressions in
        the letter for the informal words and phrases below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Language focus: formal language</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 450, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page81/E3/1.jpg" style='height:27cm'/>
        
        `,
        answer: [
          "I would be grateful for",
          "have previous experience of working",
          "I am writing to express my interest in",
          "make me a perfect fit",
          "was in charge of giving",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E4",
    exerciseKey: "img/FriendsPlus/Page81/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#2683c6' >Writting Strategy</span>. Underline the words the
        writer uses in the article to link ideas together.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 40,
        listWords: [
          `I don’t know anyone who doesn’t love sugar|. Humans have
          a natural sweet tooth and it can be difficult to control our
          sugar-eating habits|. But how can something so nice be so
          bad for us|?<br>
          Obviously|, naturally occurring sugar|, for example in fruit|,
          is considered healthy sugar|. However||, refined sugar is not
          healthy and is added to many processed foods to make them
          taste better|. Surprisingly|, this ’hidden’ sugar can be found
          in many savoury foods like bread|, tins of vegetables and
          sauces|. Moreover|, refined sugar has no nutritional value
          and therefore offers ’empty’ calories|. It also has no fibre|,
          unlike the natural sugar in fruit|, and therefore is easy to eat
          a lot of|. Consequently|, excess calories cause weight gain|.
          Too much sugar also encourages childhood tooth decay and
          diabetes in later life|.<br>
          So|, how can we reduce sugar|, especially hidden sugars in
          our diets|? I think the government is responsible|. Ideally|,
          it should regulate the amount of sugar that companies can
          add to processed foods|. Furthermore|, sugar’s health risks
          should be taught in school|. This approach has worked for
          smoking|, after all|.<br>
          Personally|, I think it is extremely important that young
          people know about the dangers of sugar so_that we can
          make informed choices|. Although it is delicious|, we must
          not put our future health at risk|.
          `,
        ],
        answers: [
          "0-50",
          "0-92",
          "0-244",
          "0-346",
          "0-408",
          "0-466",
          "0-558",
          "0-208",
          "0-372",
          "0-468",
          "0-410",
          "0-506",
          "0-748",
          "0-904",
          "0-938",
        ],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative;width:25cm'>
        <img src="img/FriendsPlus/Page81/E4/2.jpg" style='height:28cm'/><br>
        <div style='line-height:35px;font-size:22px;position:absolute;top:128px;left:47px;width:170mm'>
          <input id=0 type=Circle />
        </div>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E5",
    exerciseKey: "img/FriendsPlus/Page81/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Look at the phrases. Which ones are
        included in the article?
        `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 450,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Comment adverbs</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 4,
        listWords: [
          `Personally|, (I think) …<br>
          Surprisingly|, …<br>
          Obviously|, …<br>
          Consequently|, …<br>`,
          `Ideally|, …<br>
          Amazingly|, …<br>
          Admittedly|, …<br>
          Basically|, …<br>`,
        ],
        answers: ["0-56", "0-30", "0-82", "1-0", "0-0"],
        initialValue: [],
      },
      Layout: `
      <div style='margin-bottom:10px;border-left:5px solid rgb(38,131,198);padding-left:20px'>
          <hintbox id=0></hintbox>
          <div style='display:flex'>
            <div style='margin-right:200px'>
               <input id=0 type=Circle />
              </div>
              <div>
               <input id=1 type=Circle />
              </div>
          </div>
        </div>
        <img src="img/FriendsPlus/Page81/E3/1.jpg" style='height:27cm'/>
      `,
    },
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E6",
    exerciseKey: "img/FriendsPlus/Page81/Key/E6answerKey.png",
    recorder: true,
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in groups. Discuss the opinions below.
        Decide which view you hold and use a comment adverb
        to introduce your view. Then add a reason.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: ",",
    hideBtnFooter: true,

    component: T6,
    questions: [
      {
        title: `
      <div style='line-height:50px'>
        <b>1</b>&ensp;It is / isn’t OK to have some fat in your diet.<br>
        <b>2</b>&ensp;Additives in food are / aren’t bad for you.<br>
        <b>3</b>&ensp;Fast food should / shouldn’t be banned in leisure centres.<br>
      </div>
      <img src="img/FriendsPlus/Page81/E6/1.jpg" style='height:4cm'/>

        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E7",
    exerciseKey: "img/FriendsPlus/Page81/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Read the task below. Make notes on your opinions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 700,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <img src="img/FriendsPlus/Page81/E7/1.jpg" style='height:5cm'/>
       
        <textarea id=0 rows=3></textarea>
        `,
        answer: [
          `I think I'll choose to travel by coach , mainly because …  
The best option would (again) be the coach.  
That's because you travel …`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E8",
    exerciseKey: "img/FriendsPlus/Page81/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Plan your article in your notebook. Organise your ideas
        into paragraphs and link them. Then write your article
        (180–200 words).`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=3></textarea>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P81-E9",
    exerciseKey: "img/FriendsPlus/Page81/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
