import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E1",
    exerciseKey: "img/FriendsPlus/Page101/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Read the text. How can a social media campaign help the business?`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `'
        <img src="img/FriendsPlus/Page101/E1/1.jpg" style='height:27cm'/>
        

        
        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E2",
    exerciseKey: "img/FriendsPlus/Page101/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Then find examples of rules
        (1–3) in the text.`,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 650, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { textAlign: "center", width: 240, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          `Nowadays,_social_media_can_be_used_for_marketing_effort_known_as_a_social_media_campaign. 
In_a_campaign,_businessmen_determine_what_their_goal_is. 
Common_goals_in_business_may_include_having_users_give_feedback,_getting_customers_to_have_more_concern_for_the_brand_or_making_sales_figures_increase. 
The_businessmen_would_prefer_more_audience_to_access_their_products_through_suitable_media_platforms. 
Of_course_many_free_social_media_marketing_apps_have_been_made_to_track_shares,_replies,_likes_and_keywords_related_to_a_brand. 
Moreover,_certain_media_services_are_built_to_combine_with_social_media_accounts_to_see_who_is_seeing_media_posts_and_how_they_are_responding. 
These_tools_get_users_to_interact_with_social_media_posts,_ask_questions,_provide_reviews,_and_even_enter_contests. 
Positive_interaction,_personal_attention_and_prizes_are_quite_important_in_a_campaign because_they_get_the_audience_to_be_more_involved_in_the_campaign. 
And_this,_in_turn,_helps_businessmen_a_lot_in_adjusting_their_business_strategies.`,
        ],
        answers: ["0-8", "0-24", "0-30", "0-16", "0-12"],
        initialValue: [],
      },
      Layout: `

      <img src="img/FriendsPlus/Page101/E2/1.jpg" style='height:15cm'/> 
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page101/E2/2.jpg" style='height:27cm'/> 
        <div style='line-height:32px;font-size:22px;position:absolute;top:406px;left:40px;width:165mm'>
             <input id=0 type=Circle />
        </div>
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E3",
    exerciseKey: "img/FriendsPlus/Page101/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the correct form of the
        verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["answer", "interact", "enter", "try"],
        styleElement: { color: "#91268e", margin: 10, marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 140, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='width:20cm; padding:10px 20px; border: 5px solid rgb(8,169,152); border-radius:20px; box-shadow:5px 10px 10px gray'>
            Jack owns a little shop downtown. Years ago his business was
            not really good, but last year he learned about social media
            campaigns and started to benefit from them. He got his
            customers <sup>1</sup># with him and learned more about their
            preference and choice. He once had his customers <sup>2</sup>#
            a contest in which they were made <sup>3</sup># questions
            about his shop and products. With the help of different
            platforms, he has improved the image of his business and can
            earn quite a lot. However, he keeps getting himself <sup>4</sup>#
          </div>
        
        `,
        // InputRong: true,
        answer: ["to interact", "enter", "to answer", "trying"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E4",
    exerciseKey: "img/FriendsPlus/Page101/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Complete the sentences with
        the correct form of the verbs in brackets.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 180,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page101/E4/1.jpg" style='height:9.5cm'/>
      <div style='width:27cm;line-height:47px'>
        <b>1</b>&ensp;Everyone would prefer journalists #  . (not lie)<br>
        <b>2</b>&ensp;The film made me # how serious climate change is. (realise)<br>
        <b>3</b>&ensp;If you want these laws #  , sign our petition! (change)<br>
        <b>4</b>&ensp;Recent riots have left dozens of people #  . (injure)<br>
        <b>5</b>&ensp;The press should be made # for their actions. (apologise)<br>
      </div>
        `,
        answer: [
          "not to lie",
          "realise",
          "to change",
          "injured",
          "to apologise",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E5",
    exerciseKey: "img/FriendsPlus/Page101/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the correct form of the verbs below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 150,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["ban", "do", "hide", "throw", "wait"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
      <div style='width:27cm;line-height:47px'>
        <b>1</b>&ensp;Police have just found their suspect # in a derelict building.<br>
        <b>2</b>&ensp;He kept the crowd # for an hour to hear his speech.<br>
        <b>3</b>&ensp;The club will have him # out for being under age.<br>
        <b>4</b>&ensp;I managed to get the homework # by working all night.<br>
        <b>5</b>&ensp;There are some countries that want the internet # .<br>
      </div>
        `,
        answer: ["hiding", "waiting", "thrown", "done", "banned"],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E6",
    exerciseKey: "img/FriendsPlus/Page101/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",
    recorder: true,
    stylesTextInput: { width: 150 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Take turns to tell each other
        about something that:
        `,
        color: "black",
        width: "28cm",
        left: 40,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["minded", "populated", "reaching", "saving", "watering"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <ul>
          <li>you haven’t managed to get done this week.</li>
          <li>has had you laughing recently.</li>
        </ul>
        `,
        answer: ["minded", "saving", "watering", "reaching", "populated"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E7",
    exerciseKey: "img/FriendsPlus/Page101/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Discuss the questions in
        exercise 4. Give reasons and examples.
        `,
        color: "black",
        left: 40,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        

      <div style='width:27cm;line-height:47px'>
        <b>1</b>&ensp;Everyone would prefer journalists  . (not lie)<br>
        <b>2</b>&ensp;The film made me how serious climate change is. (realise)<br>
        <b>3</b>&ensp;If you want these laws  , sign our petition! (change)<br>
        <b>4</b>&ensp;Recent riots have left dozens of people  . (injure)<br>
        <b>5</b>&ensp;The press should be made for their actions. (apologise)<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P101-E8",
    exerciseKey: "img/FriendsPlus/Page101/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Using adjectives and linking
        verbs, tell your partner about a famous person you would
        like to meet.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <ul>
          <li>Give some information about them and what they do.</li>
          <li>Say why you’d like to meet them and how it would make you feel. </li>
        </ul>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
