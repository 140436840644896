import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E1",
    exerciseKey: "img/FriendsPlus/Page75/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Answer the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page75/E1/1.jpg" style='height:8cm'/>
        <div style='line-height:50px'>
          <b>1</b>&ensp;Describe the photo. What do you think is happening?<br>
          <b>2</b>&ensp;If you were an athlete, what would be your chosen sport and why?<br>
        </div>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E2",
    exerciseKey: "img/FriendsPlus/Page75/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#c50d47'>Listening Strategy</span> and the sentence below.
        Think of other ways to express the underlined
        information.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 100, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Travel collocations</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
      {
        src: [
          "do",
          "get",
          "go",
          "have",
          "help",
          "light",
          "put up",
          "sleep",
          "taste",
          "travel",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
      },
    ],
    character: ",",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page75/E2/1.jpg" style='height:4cm'/>
        <div>
          The speaker <u>could not have worked harder</u> on the exercise bike. 
        </div>
        `,
        // InputRong: true,
        answer: [
          "light",
          "taste",
          "have",
          "sleep",
          "put up",
          "help",
          "get",
          "travel",
          "go",
          "do",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E3",
    exerciseKey: "img/FriendsPlus/Page75/Key/E3answerKey.png",
    audio: "Audios/2.19.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone name='2.19' colorimg=#eab5b2  color='white'></headphone> Listen to the first part of a radio programme
        about fitness. Is the sentence in exercise 2 true or false?
        What words did the speaker actually use?`,
        color: "black",
        left: 0,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      width: 700,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    stylesTextInput: { width: 100, textAlign: "center" },

    component: T6,
    // character: "|",
    questions: [
      {
        title: `
        The speaker <u>could not have worked harder</u> on the exercise bike. 
         <div style='width:27cm'>
            <b>Is the sentence in exercise 2 true or false?</b>#<br>
            <b>What words did the speaker actually use?</b><textarea id=1 rows=2></textarea><br>
          </div>
          
        `,
        // InputRong: true,
        answer: [
          "True|T",
          "I was pedalling as hard as I could on this exercise bike.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E4",
    exerciseKey: "img/FriendsPlus/Page75/Key/E4answerKey.png",
    audio: "Audios/2.20.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.20' colorimg=#eab5b2  color='white'></headphone> Now listen to the rest of the programme.
        Are the sentences about High Intensity Training true or
        false? Write T or F.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 0,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["save", "learn", "have", "lie", "rise", "go", "work"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 100,
      textAlign: "center",
    },
    component: T6,
    // character: "|",
    questions: [
      {
        title: `
         
        <b>1</b>&ensp;Your heart rate and breathing quickly recover afterwards.#<br>
        <b>2</b>&ensp;It’s better than many other ways of exercising.#<br>
        <b>3</b>&ensp;The benefits aren’t yet known for serious sports training.#<br>
        <b>4</b>&ensp;Increasing the mitochondria in your body makes you fitter.#<br>
        <b>5</b>&ensp;Your body fat is reduced.#<br>
        <b>6</b>&ensp;You feel hungry afterwards.#<br>
        <b>7</b>&ensp;It takes much less time than other forms of exercise.#<br>
        `,
        // InputRong: true,
        answer: [
          "T|True",
          "T|True",
          "F|False",
          "T|True",
          "T|True",
          "F|False",
          "T|True",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E5",
    exerciseKey: "img/FriendsPlus/Page75/Key/E5answerKey.png",
    audio: "Audios/2.21.mp3",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='2.21' colorimg=#eab5b2  color='white'></headphone> Complete the sentences (1–4)
        with the correct form of the words. Then listen and check.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 150,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Exercise verbs</b>", "ache", "burn", "convert", "pump"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
      {
        src: [
          "<b>Exercise nouns </b>",
          "adrenalin",
          "burst",
          "energy",
          "intensity",
        ],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    // character: "|",
    questions: [
      {
        title: `

         <div style='width:25cm'>
            <b>1</b>&ensp;I was # exercising so hard that my heart was , and my leg muscles were # .<br>
            <b>2</b>&ensp;It’s good to push yourself to the absolute limit with short # of high-# exercise.<br>
            <b>3</b>&ensp;Mitochondria # fat and sugar in the body into # .<br>
            <b>4</b>&ensp;This type of exercise also releases # – a hormone which # fat.<br>
          </div>
        `,
        // InputRong: true,
        answer: [
          "pumping",
          "aching",
          "bursts",
          "intensity",
          "convert",
          "energy",
          "adrenalin",
          "burns",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E6",
    exerciseKey: "img/FriendsPlus/Page75/Key/E6answerKey.png",
    audio: "Audios/2.22.mp3",
    // checkDuplicated: true,
    // textAlign: "center",

    textareaStyle: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='2.22' colorimg=#eab5b2  color='white'></headphone> Listen to three speakers talking about their
        preferred form of exercising. Choose the correct answers
        (a–c). Remember the advice in the strategy`,
        color: "black",
        width: "28cm",
        left: -30,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "two",
          "theirs know",
          "cues",
          "hear",
          "one",
          "sow",
          "sea",
          "hour",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c `, `a <br> b <br> c `, `a <br> b <br> c `],
        answers: ["0-4", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: ` 
      <b>1</b>&ensp;Speaker 1 says that<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:5px'>
            <input id=0 type='Circle' />
          </div>
          <div>
            he usually plays twice a week in winter.<br>
            his attitude makes up for his deficiencies as a player.<br>
            it’s more important to enjoy yourself than to win.<br>
          </div>
         </div>
        <b>2</b>&ensp;The intention of speaker 2 is to<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:5px'>
            <input id=1 type='Circle' />
          </div>
          <div>
             detail the exercise options available at her gym.<br>
             explain how she chooses to exercise and why.<br>
             advise on the best way to exercise.<br>
          </div>
         </div>
        <b>3</b>&ensp;Speaker 3 is being interviewed about<br>
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:5px'>
            <input id=2 type='Circle' />
          </div>
          <div>
             her dedication to her sport.<br>
             her reasons for choosing this particular sport.<br>
             her latest fitness craze.<br>
          </div>
         </div>
      `,
    },
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E7",
    exerciseKey: "img/FriendsPlus/Page75/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Complete the collocations that the
        speakers used in exercise 6 with the verbs below. Use
        each verb once, and choose the best verb for each noun.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Exercise collocations</b>"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
      {
        src: ["attend", "be", "beat", "do", "lift", "push", "pedal"],
        styleElement: { color: "#c50d47", marginRight: "30px" },
        // width: "70%",
      },
    ],
    stylesTextInput: { width: 100, textAlign: "center" },
    questions: [
      {
        title: `
        <div style='margin-bottom:10px;border-left: 5px solid rgb(220,30,116); padding-left: 10px; width:27cm'>
            <hintbox id=0></hintbox>
            <hintbox id=1></hintbox>
            <b>1</b>&ensp;# myself to the limit<br>
            <b>2</b>&ensp;# aerobics<br>
            <b>3</b>&ensp;# on an exercise bike<br>
            <b>4</b>&ensp;# competitive<br>
            <b>5</b>&ensp;# weights<br>
            <b>6</b>&ensp;# a fitness class<br>
            <b>7</b>&ensp;# an opponent<br>
        </div>
        `,
        answer: ["push", "do", "pedal", "be", "lift", "attend", "beat"],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P75-E8",
    exerciseKey: "img/FriendsPlus/Page75/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Give personal opinions about
        sports and fitness activities using the collocations in
        exercise 7. Include reasons and examples.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page75/E8/1.jpg" style='height:6cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
