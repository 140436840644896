import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E4",
    exerciseKey: "img/FriendsPlus/Page67/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the holiday activities (1–14) with the verbs
        below.`,
        color: "black",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 150,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex'>
          <div>
            <b>1</b>&ensp;#the sights<br>
            <b>2</b>&ensp;#it easy<br>
            <b>3</b>&ensp;#out in a restaurant<br>
            <b>4</b>&ensp;#from it all<br>
            <b>5</b>&ensp;#your horizons<br>
            <b>6</b>&ensp;#new experiences<br>
            <b>7</b>&ensp;#the sun<br>
          </div>
          <div style='margin-left:100px'>
            <b>8</b>&ensp;#local food<br>
            <b>9</b>&ensp;#physically active<br>
            <b>10</b>&ensp;#yourself at a spa<br>
            <b>11</b>&ensp;#with your friends<br>
            <b>12</b>&ensp;#the nightlife<br>
            <b>13</b>&ensp;#people<br>
            <b>14</b>&ensp;#your batteries<br>
          </div>
        </div>
        `,
        answer: [
          "see",
          "take",
          "eat",
          "get away",
          "broaden",
          "have",
          "soak up",
          "try",
          "be",
          "pamper",
          "hang out",
          "enjoy",
          "meet",
          "recharge",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E5",
    exerciseKey: "img/FriendsPlus/Page67/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Which sound(s) are you likely to make when you …
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 200,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: [
          "<b>Holidays and holiday accommodation</b>",
          "adventure holiday",
          "B&B",
          "backpacking",
          "beach holiday",
          "beach house",
          "cabin",
          "camper van",
          "camping",
          "campsite",
          "caravan",
          "city break",
          "cottage",
          "couch-surfing",
          "cruise",
          "cycling holiday",
          "ecotourism",
          "guest house",
          "holiday camp",
          "holiday home",
          "hotel",
          "houseboat",
          "house swap",
          "package holiday",
          "self-catering apartment",
          "tent",
          "time-share apartment",
          "villa",
          "volunteering",
          "winter sports holiday",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Holiday activities</b>",
          "be",
          "broaden",
          "eat",
          "enjoy",
          "get away",
          "hang out",
          "have",
          "meet",
          "pamper",
          "recharge",
          "see",
          "soak up",
          "take",
          "try",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <div style='display:flex'>
          <div style='margin-right:20px'>
            <b>1</b>&ensp;#<br>
            <b>2</b>&ensp;#<br>
            <b>3</b>&ensp;#<br>
            <b>4</b>&ensp;#<br>
            <b>5</b>&ensp;#<br>
            <b>6</b>&ensp;#<br>
            <b>7</b>&ensp;#<br>
            <b>8</b>&ensp;#<br>
            <b>9</b>&ensp;#<br>
            <b>10</b>&ensp;#<br>
            <b>11</b>&ensp;#<br>
            <b>12</b>&ensp;#<br>
          </div>
          <img src="img/FriendsPlus/Page67/E5/1.jpg" style='height:26cm'/>

        </div>
        `,
        answer: [
          "soaking",
          "physically",
          "house",
          "sights",
          "ecotourism",
          "self-catering",
          "local",
          "volunteering",
          "package",
          "city break",
          "cruise",
          "couch-surfing",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E6",
    exerciseKey: "img/FriendsPlus/Page67/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "6",
        title: `Write a short summary of the text. Use the following
        questions as an outline.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Compound adjectives</b>",
          "bad-mannered",
          "easy-going",
          "hard-working",
          "light-hearted",
          "open-minded",
          "quick-witted",
          "self-confident",
          "single-minded",
          "thick-skinned",
          "well-behaved",
        ],
        width: "90%",
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

        <div style='width:27cm'>
          <ul>
            <li>How has the world of work been?</li>
            <li>What causes most concern among 18-year-olds? Where can they turn to for help regarding choosing a career?</li>
            <li>What is of vital importance to getting on in your career?</li>
            <li>Why is it important to be flexible?</li>
            <li>What prevents the conquest of robots in the world of work?</li>
            <li>What affects one’s future career most</li>
          </ul>
        </div>
        <textarea id=0 rows=12></textarea>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>B</b>
          <div style='margin-left:15px'>
              On leaving school, 18-year-olds are faced with one
              of the toughest decisions of their life: career choice.
              Which to pursue requires sensible decision basing
              on their in-depth self-understanding. Guidance
              from school counsellors and parents together with
              <span style='background:#b7b3d8'>insights</span> into their potentials from character tests will
              empower them to make informed decisions. It is
              also worth noticing that, in this ever-advancing world,
              school-leavers need to be well-prepared for changes
              and once-in-a-lifetime opportunity.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>C</b>
          <div style='margin-left:15px'>
              However bewildering career choice may be, it is
              no more than the beginning of a person’s career. To
              further it, they have no alternative but to become
              better day by day. In essence, career development is
              a continual and lifelong process in which they need
              to specify their <span style='background:#b7b3d8'>goals</span>, boost their own confidence,
              improve communication skills, and expand personal
              <span style='background:#b7b3d8'>networking</span>. Actually, university graduation simply
              celebrates the completion of adults’ mainstream
              education, signifying their non-stop self-improvement. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>D</b>
          <div style='margin-left:15px'>
              Of all the qualities, a person’s flexibility will work in
              this full-of-changes world. Flexibility manifests itself
              in professional <span style='background:#b7b3d8'>agility</span>, problem-solving skills, change
              <span style='background:#b7b3d8'>management</span> and innovative thinking. As long as they
              are not set in their way of thinking, they can easily get
              a task done. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>E</b>
          <div style='margin-left:15px'>
              AI has been replacing certain blue-collar workers,
              but it can never establish a role in a field that has
              something to do with emotional intelligence (EQ).
              Automated learning machines can repeatedly lecture
              a subject, but they can never become an inspiring
              story for students. Robots in hospitals can accurately
              perform operations, but at no time can they comfort
              the lonely and suffering patients like a gentle nurse.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>F</b>
          <div style='margin-left:15px'>
            Technology has taken mankind to a world of
            constant change. School-leavers are expected to
            improve themselves and own the right skills to meet
            the social demands.
          </div>
        </div>
        `,
        answer: [
          `The world of work has never been changing so much as nowadays. Robots are replacing people in certain jobs such as waiters or check-in staff. At the age of 18, students have to choose their future career and this is difficult in today's situation. However, students can get help from school counsellors and parents and/or character tests. Guidance and help from these resources allow students to understand themselves better and be adaptable. Once a person has settled on a career, it is necessary to keep learning and updating oneself in order to advance in his or her career. Besides, he or she needs to be flexible in order to get things done, acquire new knowledge or skills. Equipped with EQ, one can never be replaced by robots. In all, one's determination shapes one's future.`,
        ],
      },
    ],
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E7",
    exerciseKey: "img/FriendsPlus/Page67/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Match the highlighted nouns in the text
        with definitions (1–7).`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Work and jobs</b>"],
        styleElement: { color: "#514ea7", marginRight: "30px" },
        width: "80%",
      },
    ],
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    character: ",",
    // checkDuplicated: true,
    questions: [
      {
        title: `
        <div style='margin-bottom:10px;border-left: 5px solid rgb(81,78,167); padding-left: 10px; width:27cm'>
            <hintbox id=0></hintbox>
            <b>1</b>&ensp;a system of trying to meet and talk to other people #<br>
            <b>2</b>&ensp;an understanding of what something or someone is #<br>
            <b>3</b>&ensp;the ability to think quickly and intelligently #<br>
            <b>4</b>&ensp;the act of dealing with something successfully #<br>
            <b>5</b>&ensp;work done physically or by hands #<br>
            <b>6</b>&ensp;all the workers / employees in a company or an organisation #<br>
            <b>7</b>&ensp;something one hopes to achieve #<br>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>B</b>
          <div style='margin-left:15px'>
              On leaving school, 18-year-olds are faced with one
              of the toughest decisions of their life: career choice.
              Which to pursue requires sensible decision basing
              on their in-depth self-understanding. Guidance
              from school counsellors and parents together with
              <span style='background:#b7b3d8'>insights</span> into their potentials from character tests will
              empower them to make informed decisions. It is
              also worth noticing that, in this ever-advancing world,
              school-leavers need to be well-prepared for changes
              and once-in-a-lifetime opportunity.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>C</b>
          <div style='margin-left:15px'>
              However bewildering career choice may be, it is
              no more than the beginning of a person’s career. To
              further it, they have no alternative but to become
              better day by day. In essence, career development is
              a continual and lifelong process in which they need
              to specify their <span style='background:#b7b3d8'>goals</span>, boost their own confidence,
              improve communication skills, and expand personal
              <span style='background:#b7b3d8'>networking</span>. Actually, university graduation simply
              celebrates the completion of adults’ mainstream
              education, signifying their non-stop self-improvement. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>D</b>
          <div style='margin-left:15px'>
              Of all the qualities, a person’s flexibility will work in
              this full-of-changes world. Flexibility manifests itself
              in professional <span style='background:#b7b3d8'>agility</span>, problem-solving skills, change
              <span style='background:#b7b3d8'>management</span> and innovative thinking. As long as they
              are not set in their way of thinking, they can easily get
              a task done. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>E</b>
          <div style='margin-left:15px'>
              AI has been replacing certain blue-collar workers,
              but it can never establish a role in a field that has
              something to do with emotional intelligence (EQ).
              Automated learning machines can repeatedly lecture
              a subject, but they can never become an inspiring
              story for students. Robots in hospitals can accurately
              perform operations, but at no time can they comfort
              the lonely and suffering patients like a gentle nurse.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>F</b>
          <div style='margin-left:15px'>
            Technology has taken mankind to a world of
            constant change. School-leavers are expected to
            improve themselves and own the right skills to meet
            the social demands.
          </div>
        </div>
        

        `,
        answer: [
          "networking",
          "insight",
          "agility",
          "management",
          "manual jobs",
          "staff",
          "goals",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E8",
    exerciseKey: "img/FriendsPlus/Page67/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { textAlign: "center", width: 400, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    character: ",",
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:26cm;line-height:50px'>
          <b>1</b>&ensp;What jobs do you think AI can’t do properly?<br>
          <b>2</b>&ensp;What do you think is more important when it comes to choosing a job: the salary or the job satisfaction?<br>
        </div>
        `,
        answer: [
          "sremote",
          "breathtaking, inaccessible",
          "overcrowded, commercialised",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E9",
    exerciseKey: "img/FriendsPlus/Page67/Key/E9answerKey.png",
    component: T6,
    audio: "Audios/2.13.mp3",
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `<headphone name='2.02' colorimg=#d1b6d5  color='white'></headphone> `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;width:25cm'>
          <b style='color:red'>A</b>
          <div style='margin-left:15px'>
              The world of work has been undergoing dramatic
              changes. Step by step, robots are taking over <span style='background:#b7b3d8'>manual jobs</span>
              that used to be done by semi-skilled workers.
              AI assistants in the forms of holograms have been
              on trial at Changi Airport and Boston Airport. Their
              performance as perfectly efficient check-in <span style='background:#b7b3d8'>staff</span> has
              amazed users. Certain restaurants in South Korea
              have introduced tablets for self-order and have even
              made use of maid robots in place of waiters and
              waitresses. Getting a secure job or a career can’t have
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>B</b>
          <div style='margin-left:15px'>
              On leaving school, 18-year-olds are faced with one
              of the toughest decisions of their life: career choice.
              Which to pursue requires sensible decision basing
              on their in-depth self-understanding. Guidance
              from school counsellors and parents together with
              <span style='background:#b7b3d8'>insights</span> into their potentials from character tests will
              empower them to make informed decisions. It is
              also worth noticing that, in this ever-advancing world,
              school-leavers need to be well-prepared for changes
              and once-in-a-lifetime opportunity.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>C</b>
          <div style='margin-left:15px'>
              However bewildering career choice may be, it is
              no more than the beginning of a person’s career. To
              further it, they have no alternative but to become
              better day by day. In essence, career development is
              a continual and lifelong process in which they need
              to specify their <span style='background:#b7b3d8'>goals</span>, boost their own confidence,
              improve communication skills, and expand personal
              <span style='background:#b7b3d8'>networking</span>. Actually, university graduation simply
              celebrates the completion of adults’ mainstream
              education, signifying their non-stop self-improvement. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>D</b>
          <div style='margin-left:15px'>
              Of all the qualities, a person’s flexibility will work in
              this full-of-changes world. Flexibility manifests itself
              in professional <span style='background:#b7b3d8'>agility</span>, problem-solving skills, change
              <span style='background:#b7b3d8'>management</span> and innovative thinking. As long as they
              are not set in their way of thinking, they can easily get
              a task done. 
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>E</b>
          <div style='margin-left:15px'>
              AI has been replacing certain blue-collar workers,
              but it can never establish a role in a field that has
              something to do with emotional intelligence (EQ).
              Automated learning machines can repeatedly lecture
              a subject, but they can never become an inspiring
              story for students. Robots in hospitals can accurately
              perform operations, but at no time can they comfort
              the lonely and suffering patients like a gentle nurse.
          </div>
        </div>
        <div style='display:flex;width:25cm'>
          <b style='color:red'>F</b>
          <div style='margin-left:15px'>
            Technology has taken mankind to a world of
            constant change. School-leavers are expected to
            improve themselves and own the right skills to meet
            the social demands.
          </div>
        </div>
        
        
        
        `,
        answer: [
          "She wishes they were already on holiday.",
          "He would be worried about meeting ghosts.",
          "This wouldn't be a great place to visit.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P67-E10",
    exerciseKey: "img/FriendsPlus/Page67/Key/E10answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 370, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Work in pairs. Which of the
        holidays in exercise 2 would you most like
        to go on? Why?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
          Which gesture or expression might you use if …<br>
            <b>1</b>&ensp;you wish someone good luck in an exam? <br>
            <b>2</b>&ensp;you are puzzled by a question?<br>
            <b>3</b>&ensp;you don’t know the answer to a question that someone has asked?<br>
        </div>
        <img src="img/FriendsPlus/Page67/E10/1.jpg" style='height:4cm'/>

        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
};

export default json;
