import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E1",
    exerciseKey: "img/FriendsPlus/Page89/Key/E1answerKey.png",
    recorder: true,
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Look at the pictures. Can you guess what 
          their jobs are? Do you think they earn a lot of money?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><img src="img/FriendsPlus/Page89/E1/1.jpg" style='height:20cm'/></div>
        
      
        
        `,
        answer: [
          "locate",
          "renovate",
          "excavate",
          "build",
          "unearth",
          "remove",
          "restore",
          "display",
          "bury",
          "destroy",
          "uncover",
          "examine",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page89/Key/E2answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    stylesTextInput: { width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Match the verbs (1–6) with the noun phrases (a–f). And 
      guess which job these activities are related to.`,
        color: "black",
        left: 0,
        width: "25cm",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: ["0-3", "11-2", "4-7", "1-6", "8-9", "10-5"],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 120, fontSize: 25 },
        answers: ["vloggers"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "3px solid",
          color: "#92278f",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: "1px 4px",
          borderRadius: "50%",
          color: "#92278f",
          border: "3px solid",
          borderColor: "gray",
        },
        limitSelect: 6,
        listWords: [
          ` announce broadcast cover dissemble_feelings disseminate disguise_the_truth distort_the_truth flatter mislead photoshop post provide report reveal_the_truth tell_a_lie transmit`,
        ],
        answers: ["0-8", "0-16", "0-30", "0-14", "0-18", "0-12"],
        initialValue: [],
      },
      Layout: `
    <input id='0' type='boxMatch'/>
    <input id='1' type='boxMatch'/>
    <input id='2' type='boxMatch'/>
    <input id='3' type='boxMatch'/>
    <input id='4' type='boxMatch'/>
    <input id='5' type='boxMatch'/>
    <input id='6' type='boxMatch'/>
    <input id='7' type='boxMatch'/>
    <input id='8' type='boxMatch'/>
    <input id='9' type='boxMatch'/>
    <input id='10' type='boxMatch'/>
    <input id='11' type='boxMatch'/>
    <div style='display:flex;margin-left:50px'>
      <div style='margin-right:200px'>
        <b>1</b>&ensp;plan <br>
        <b>2</b>&ensp;get<br>
        <b>3</b>&ensp;make <br>
        <b>4</b>&ensp;use <br>
        <b>5</b>&ensp;tag<br>
        <b>6</b>&ensp;reach out for<br>
      </div>
      <div>
        <b>a</b>&ensp;software to edit videos <br>
        <b>b</b>&ensp;what to film <br>
        <b>c</b>&ensp;more followers <br>
        <b>d</b>&ensp;short films with a camera or phone <br>
        <b>e</b>&ensp;videos with web links for products <br>
        <b>f</b>&ensp;permission to film
      </div>
    </div>
    <div style='margin-top:20px'>These activities are all related to <input id=0></input></div>
    `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E3",
    exerciseKey: "img/FriendsPlus/Page89/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    hideBtnFooter: true,
    stylesTextInput: { width: 50, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read the text and answer the questions.`,
        color: "black",
        left: 100,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:50px'>In which paragraph (A–D) does the writer …</div>
          <img src="img/FriendsPlus/Page90/E3/1.jpg" style='height:21cm'/>
        <div style='margin-left:50px'>
          <b>1</b> &ensp;give reasons for the popularity of vlogs?#<br>
          <b>2</b> &ensp;provide an example that demonstrates how popular vloggers have become?#<br>
          <b>3</b>&ensp;give an example of someone being dishonest?#
        </div>
      
        
        `,
        answer: ["B", "A", "D"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E4",
    exerciseKey: "img/FriendsPlus/Page89/Key/E4answerKey.png",
    video: "",

    // recorder: true,
    totalInput: 1,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#5f50a0'>Reading Strategy</span>. Then read the first question 
          in exercise 5 related to paragraph A. When you have 
          chosen the correct answer (a, b, c or d), read the 
          paragraph again and decide why the other options are 
          wrong.`,
        color: "black",
        left: 0,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: "25cm",
      paddingTop: 2,
      resize: "none",
      // marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    selectStyle: {
      marginTop: 5,
      border: "2px solid black",
      color: "black",
      width: 170,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    select: true,
    selectOptionRandom: true,
    selectOptionValues: ["is wrong", "is correct"],
    // character:",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page90/E4/1.jpg" style='height:9cm'/>

        <div style='line-height:47px'>
          <b>1</b>&ensp;There was a large police presence at a bookstore because
          <div style='display:flex'><select id=0></select><b>a</b>&ensp;fans of a famous writer were waiting to see him.</div>
          <div style='display:flex'><select id=1></select><b>b</b>&ensp;someone very famous was causing excitement.</div>
          <div style='display:flex'><select id=2></select><b>c</b>&ensp;the person who was coming was a pop sensation.</div>
          <div style='display:flex'><select id=3></select><b>d</b>&ensp;thousands of people wanted to buy a book.</div>
        </div>
        <div style='display:flex;width:25cm'>
        <b>A</b>
        <div style='margin-left:15px'>
          I am in a supermarket, doing my best to look like any
          other shopper browsing the shelves. My mission on this
          trip is to buy something I fancy from the bakery, which
          means I’ll have to interact with the person at the
          counter. I’m hoping to pass off the handwritten notes
          I’m carrying as a shopping list, because no one must
          know why I am here. I have to keep my identity secret
          because I am a mystery shopper.
        </div>
      </div>
      
        `,
        // InputRong: true,
        answer: ["is wrong", "is wrong", "is wrong", "is correct."],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P89-E5",
    exerciseKey: "img/FriendsPlus/Page89/Key/E5answerKey.png",
    component: Circle_Write,
    // maxLength:4,
    textAlign: "center",
    stylesTextInput: { width: 230, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Read the text. For questions (2–4), circle the correct 
          options (a–d).`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    // isHello:true,
    question: {
      leftContent: "100px",

      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { paddingTop: 0, width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
        ],
        answers: ["0-12", "1-18", "2-6", "3-6"],
        initialValue: [],
      },
      Layout: `
      
      <div>
            <b>1</b>&ensp;There was a large police presence at a bookstore because
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='0' type='Circle'/>
                </div>
                <div>
                  fans of a famous writer were waiting to see him.<br>
                  someone very famous was causing excitement.<br>
                  the person who was coming was a pop sensation.<br>
                  thousands of people wanted to buy a book.
                </div>
            </div>
      </div>
      <div>
            <b>2</b>&ensp;One of the common characteristics of vlogs is that followers can
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='1' type='Circle'/>
                </div>
                <div>
                  create relationships with other vlog followers.<br>
                  share their interest in vlogs created by media corporations.<br>
                  change the content of the vlogs they like.<br>
                  make their opinions on vloggers’ work known.
                </div>
            </div>
      </div>
      <div>
            <b>3</b>&ensp;Vloggers earn large sums of money by 
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='2' type='Circle'/>
                </div>
                <div>
                  improving the quality of modern technology. <br>
                  talking about a product.<br>
                  influencing YouTube authorities.<br>
                  criticising products advertised by other vloggers. 
                </div>
            </div>
      </div>
      <div>
            <b>4</b>&ensp;The incident with Zoella’s book
            <div style='margin-left:20px;display:flex'>
                <div>
                  <input id='3' type='Circle'/>
                </div>
                <div>
                  lost her a lot of fans.<br>
                  suggested that the world of vlogging is changing.<br>
                  showed that she can write as well as a novelist.<br>
                  demonstrated that dreams can come true.
                </div>
            </div>
      </div>
      
      `,
    },
  },
};

export default json;
