import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E3",

    exerciseKey: "img/FriendsPlus/Page97/Key/E3answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      // resize: "none",
      width: 800,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Look at the list of nutrition words below.
        Find one food on the eatwell plate that contains each
        thing in the list. `,
        color: "black",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Nutrition</b>",
          "additives",
          "calcium",
          "calories",
          "carbohydrate",
          "cholesterol",
          "fat",
          "fibre",
          "mineral",
          "nutrient",
          "preservative",
          "protein",
          "vitamins",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "80%",
      },
      {
        src: ["additives – sweets"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 150,
      textAlign: "center",
    },
    character: ",",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <img src="img/FriendsPlus/Page72/E1/1.jpg" style='height:12cm'/>
        <div style='line-height:50px'>
          <b>additives</b>: <textarea id=0 rows></textarea> 
          <b>calcium</b>: <textarea id=1 rows></textarea> 
          <b>calories</b>: <textarea id=2 rows></textarea> 
          <b>carbohydrate</b>: <textarea id=3 rows></textarea> 
          <b>cholesterol</b>: <textarea id=4 rows></textarea> 
          <b>fat</b>: <textarea id=5 rows></textarea> 
          <b>fibre</b>: <textarea id=6 rows></textarea> 
          <b>mineral</b>: <textarea id=7 rows></textarea> 
          <b>nutrient</b>: <textarea id=8 rows></textarea> 
          <b>preservative</b>: <textarea id=9 rows></textarea> 
          <b>protein</b>: <textarea id=10 rows></textarea> 
          <b>vitamins</b>: <textarea id=11 rows></textarea> 
        </div>
        `,
        answer: [
          "baked beans, tinned chickpeas, biscuits, fizzy drinks",
          "cheese, milk, yoghurt",
          "all foods",
          "bread, cereal, pasta, potatoes, rice",
          "eggs, meat",
          "meat, milk, nuts, oil, biscuits, cake",
          "apples, grapes, lemons, pears, asparagus, broccoli, cabbage, carrots, onions, peppers, tomatoes, bananas, sweetcorn, peas, mushrooms",
          "apples, bananas, grapes, lemons, pears, raspberries, asparagus, broccoli, cabbage, carrots, lettuce, onions, peppers, tomatoes, cheese, milk, yoghurt",
          "all food",
          "baked beans, biscuits, long life milk, tinned chickpeas",
          "cheese, milk, yoghurt, eggs, fish, meat, nuts",
          "cheese, milk, yoghurt, fish, meat, nuts, oil, apples, bananas, grapes, lemons, pears, raspberries, asparagus, broccoli, cabbage, carrots, lettuce, onions, peppers, tomatoes",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E4",
    audio: "Audios/3.16.mp3",
    exerciseKey: "img/FriendsPlus/Page97/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='3.16' colorimg=#d1b6d5  color='white'></headphone> Listen to three extracts. Match each of them
        with an event below. There are 2 extra events.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "a benefit concert",
          "a demonstration",
          "an interview",
          "signing a petition",
          "a speech in parliament",
        ],
        width: "70%",
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 250,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
      <hintbox id=0></hintbox>
      <div style='line-height:50px'>
        <b>1</b>#<br>
        <b>2</b>#<br>
        <b>3</b>#<br>
      </div>
        `,
        answer: ["signing a petition", "a benefit concert", "demonstration"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E5",
    exerciseKey: "img/FriendsPlus/Page97/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#92278f' >Recycle!</span> box. Combine the following pairs of
        sentences using non-defining relative clauses.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 200,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["<b>Holidays and holiday accommodation</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page97/E5/1.jpg" style='height:11cm'/>
        <div style='width:27cm'>
          <b>1</b>&ensp;Tina decided to sign the petition for a better cycling road behind the campus. Tina does not know how to ride a bike.<textarea id=0 rows=3></textarea>
          <b>2</b>&ensp;The collection for the local homeless centre early ended yesterday. Many people were regretful about it.<textarea id=1 rows=3></textarea>
          <b>3</b>&ensp;London Fashion Week met with protests against fur clothing. London Fashion Week took place in September 2017.<textarea id=2 rows=3></textarea>
          <b>4</b>&ensp;Citadel of the Ho Dynasty in Thanh Hoa, Viet Nam was recognised the UNESCO World Heritage Site in 2011. Citadel of the Ho Dynasty has an impressive arched gateway.<textarea id=3 rows=3></textarea>
        </div>

        `,
        answer: [
          "Tina, who decided to sign the petition for a better cycling road behind the campus, does not know how to ride a bike.",
          "The collection for the local homeless centre early ended yesterday, about which many people were regretful.",
          "London Fashion Week, which took place in September 2017, met with protests against fur clothing.",
          "Citadel of the Ho Dynasty in Thanh Hoa, Viet Nam, which has an impressive arched gateway, was recognised as the UNESCO World Heritage Site in 2011.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E6",
    exerciseKey: "img/FriendsPlus/Page97/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 200, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Complete the sentences with the correct
        form of the verbs below.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Protest collocations</b>",
          "campaign",
          "vote",
          "launch",
          "argue",
          "stand",
          "sign",
        ],
        width: "90%",
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:27cm'>
          <b>1</b>&ensp;Why did you # against her as the group leader? She is qualified, isn’t she?<br>
          <b>2</b>&ensp;The local residents are # a protest against the building a bypass around the town.<br>
          <b>3</b>&ensp;Tina is ready to # up to the bad children who bully her friends.<br>
          <b>4</b>&ensp;Conservationists have # against whaling for many years, but some countries still practise commercial hunting of large whales.<br>
          <b>5</b>&ensp;Scientists try to # young people out of smoking because it is not good for health.<br>
          <b>6</b>&ensp;In order to raise the public awareness of wild life protection, some E-petitions are # nowadays.<br>
        </div>

        `,
        answer: ["vote", "launching", "stand", "argued", "campaign", "signed"],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E7",
    exerciseKey: "img/FriendsPlus/Page97/Key/E7answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Which issue in exercise 3 do
        you think is the most important in your local area?
        Discuss two ideas:`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
    ],
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    character: ",",
    // checkDuplicated: true,
    questions: [
      {
        title: `
        <ul>
          <li>What is the cause?</li>
          <li>What action will you take?</li>
        </ul>


        `,
        answer: [
          "must",
          "mustn't",
          "has to",
          "doesn't have to",
          "had to",
          "don't have to",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E8",
    exerciseKey: "img/FriendsPlus/Page97/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { textAlign: "center", width: 400, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    character: ",",
    hintBox: [
      {
        src: [
          "<b>Describing places</b>",
          "breathtaking",
          "commercialised",
          "dingy",
          "dull",
          "inaccessible",
          "overcrowded",
          "remote",
          "unique",
          "unspoilt",
          "vibrant",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "80%",
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Use the information from the
        eatwell plate to answer the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:26cm'>
            <b>1</b>&ensp;Do you agree with the phrase ‘you are what you eat’?
            Why? / Why not?<br>
            <b>2</b>&ensp;Why do you think more and more people are
            becoming overweight? What can governments do to
            encourage people to eat more healthily?<br>
          </div>
        `,
        answer: [
          "sremote",
          "breathtaking, inaccessible",
          "overcrowded, commercialised",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E9",
    exerciseKey: "img/FriendsPlus/Page97/Key/E9answerKey.png",
    component: T6,
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='2.02' colorimg=#d1b6d5  color='white'></headphone> Read the <span style='color:#92278f' >Recycle!</span> box. Then
        listen again and answer the questions in
        writing.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page97/E9/1.jpg" style='height:13cm'/>
        <div style='width:25cm'>
            <b>1</b>&ensp;In dialogue 1, what does the woman wish?<br>#<br>
            <b>2</b>&ensp;In dialogue 2, how would the boy feel if he spent a few nights at Lindisfarne Castle?<br>#<br>
            <b>3</b>&ensp;In dialogue 3, what advice would the woman give if somebody wanted a relaxing holiday?<br>#<br>
        </div>
        
        
        
        `,
        answer: [
          "She wishes they were already on holiday.",
          "He would be worried about meeting ghosts.",
          "This wouldn't be a great place to visit.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P97-E10",
    exerciseKey: "img/FriendsPlus/Page97/Key/E10answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 370, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Work in pairs. Which of the
        holidays in exercise 2 would you most like
        to go on? Why?`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
          Which gesture or expression might you use if …<br>
            <b>1</b>&ensp;you wish someone good luck in an exam? <br>
            <b>2</b>&ensp;you are puzzled by a question?<br>
            <b>3</b>&ensp;you don’t know the answer to a question that someone has asked?<br>
        </div>
        <img src="img/FriendsPlus/Page97/E10/1.jpg" style='height:4cm'/>

        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
};

export default json;
