import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E1",
    exerciseKey: "img/FriendsPlus/Page80/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Describe the photo. How do you think the
        people are feeling?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page80/E1/1.jpg" style='height:8cm'/>
        

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E2",
    exerciseKey: "img/FriendsPlus/Page80/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Put the words below in the correct
        groups. Can you add any more words to each group? `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [
          { text: "VOCABULARY", icon: "", marginRight: 10, color: "#91268e" },
          // { text: "SPEAKING", icon: "", color: "#01a951" },
        ],
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    textareaStyle: {
      width: 1000,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    pointThieuWord: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Illnesses, injuries and symptoms</b>",
          "<s>ache</s>",
          "<s>bruise</s>",
          "<s>chest infection</s>",
          "be congested",
          "cough",
          "cut",
          "feel dizzy",
          "feel fatigued",
          "flu",
          "fracture",
          "heartburn",
          "insect sting",
          "feel nauseous",
          "nose bleed",
          "rash",
          "sore throat",
          "sprain",
          "feel stiff",
          "be swollen",
          "temperature",
          "virus",
          "wound",
        ],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
    ],
    character: /[,)]/,
    // replaceCharacter: /[:(]/g,
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
        <div style='line-height:50px'>
          <b>Illnesses:</b><textarea id=0 rows=3></textarea>
          <b>Injuries:</b><textarea id=1 rows=3></textarea>
          <b>Symptoms:</b><textarea id=2 rows=3></textarea>
        </div>
        `,
        // InputRong: true,
        initialValue: ["chest infection,", "bruise,", "ache,"],
        answer: [
          "chest infection, flu, virus (Also: asthma, chicken pox, gastroenteritis, heart disease, measles, mumps, tonsillitis, heart disease, malaria)",
          "bruise, cut, fracture, insect sting, nose bleed, sprain, wound (Also: graze)",
          "ache, be congested, cough, feel dizzy, feel fatigued, heartburn, feel nauseous, rash, sore throat, feel stiff, be swollen, temperature (Also: runny nose, diarrhoea)",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E3",
    exerciseKey: "img/FriendsPlus/Page80/Key/E3answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Work in pairs. Look at the illnesses and
        symptoms in exercise 2 and discuss the questions. `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Illnesses, injuries and symptoms</b>",
          "ache",
          "bruise",
          "chest infection",
          "be congested",
          "cough",
          "cut",
          "feel dizzy",
          "feel fatigued",
          "flu",
          "fracture",
          "heartburn",
          "insect sting",
          "feel nauseous",
          "nose bleed",
          "rash",
          "sore throat",
          "sprain",
          "feel stiff",
          "be swollen",
          "temperature",
          "virus",
          "wound",
        ],
        styleElement: { color: "#009345", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 300, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
          <b>1</b>&ensp;Are there any you have had repeatedly / more than once?<br>
          <b>2</b>&ensp;Are there any you have never suffered from?<br>
        </div>
        `,
        answer: ["absolutely, totally"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E4",
    exerciseKey: "img/FriendsPlus/Page80/Key/E4answerKey.png",
    audio: "Audios/2.24.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 600,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.24' colorimg=#b6d5b9  color='white'></headphone> Read the task above and listen to a student
        doing it. What does the doctor say is wrong with her?
        Which words from exercise 2 does the girl use?
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 800,
      // textAlign: "center",
    },

    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page80/E4/1.jpg" style='height:7cm'/><br>
        <div style='width:27cm'>
          <b>1</b>&ensp;What does the doctor say is wrong with her?<textarea id=0 rows=2></textarea>
          <b>2</b>&ensp;Which words from exercise 2 does the girl use?<textarea id=1 rows=2></textarea>
        </div>
        `,
        answer: [
          "The doctor says she has a virus.",
          "The speakers use cough, sore throat, ache (verb, not noun), temperature, feel dizzy and be swollen.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E5",
    exerciseKey: "img/FriendsPlus/Page80/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Check the meaning of the treatments
        and remedies in a dictionary. Find possible treatments /
        remedies for the illnesses, symptoms or injuries in exercise 2.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Treatments and remedies</b>",
          "antacid",
          "antibiotics",
          "antihistamine",
          "anti-inflammatories",
          "antiseptic cream",
          "bandage",
          "cough medicine",
          "lots of liquids",
          "painkillers",
          "rest",
          "tablets",
          "throat sweets",
          "X-ray",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
      {
        src: ["You could treat a sore throat with …"],
        styleElement: { color: "#6da6b5", marginRight: "30px" },
      },
    ],
    character: /[;/]/,
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=12></textarea>
        
        `,
        // initialValue: ["You could treat a sore throat with"],
        answer: [
          "heartburn with antacid.; a chest infection / a cut / a wound with antibiotics.; an insect sting / a rash / something swollen with antihistamine.; an insect sting / a sprain / feeling stiff / something swollen with antiinflammatories.; a cut / an insect sting / a wound with antiseptic cream.; a cut / a fracture / a sprain / a wound with a bandage.; a cough with cough medicine.; a chest infection / a fever / flu / a temperature / a virus with lots of liquids.; an ache / a fracture / a sprain with painkillers.; a chest infection / feeling dizzy / feeling fatigued / a fever / flu with rest.; an ache / a chest infection / feeling dizzy / flu / a temperature with a tablet.; a sore throat with throat sweets. You could diagnose a chest infection / a fracture with an X-ray",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E6",
    exerciseKey: "img/FriendsPlus/Page80/Key/E6answerKey.png",
    audio: "Audios/2.24.mp3",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='2.24' colorimg=#b6d5b9  color='white'></headphone> Listen again. What treatments are discussed?
        What does the doctor recommend?`,
        color: "black",
        width: "28cm",
        left: 40,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
          What treatments are discussed?<textarea id=0 rows=2></textarea>
          What does the doctor recommend?<textarea id=1 rows=2></textarea>
        </div>
        
        `,
        answer: [
          "The treatments discussed are painkillers that are also",
          "anti-inflammatories. The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E7",
    exerciseKey: "img/FriendsPlus/Page80/Key/E7answerKey.png",
    audio: "Audios/2.25.mp3",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='2.25' colorimg=#b6d5b9  color='white'></headphone> Listen to a second student doing the task in
        exercise 4. Make notes on the following topics.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 500,
      // textAlign: "center",
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page80/E4/1.jpg" style='height:8cm'/><br>
        <ul>
          <li>What the illness is</li>#
          <li>How long he has had it</li>#
          <li>Medicine he can take</li>#
          <li>How much medicine to take and how often</li>#
        </ul>
        `,
        answer: [
          `chest infection`,
          `nearly a week`,
          `antibiotics`,
          `one tablet three times a day`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E8",
    exerciseKey: "img/FriendsPlus/Page80/Key/E8answerKey.png",
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["didn’t", "mean", "mind", "same as", "something", "think"],
        styleElement: { color: "#01a951", marginRight: 30 },
      },
      {
        src: ["<b>Asking for clarification</b>"],
        styleElement: { color: "#01a951", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Read the <span style='color:#01a951' >Speaking Strategy</span>. Complete the
        phrases with the words below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },

    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
          Sorry, do you <sup>1</sup># you could repeat that?<br>
          I’m afraid I <sup>2</sup># quite catch that.<br>
          Would you <sup>3</sup># saying that again?<br>
          What do you <sup>4</sup># by … ?<br>
          Is that <sup>5</sup># like … ?<br>
          Is that the <sup>6</sup># … ?<br>
        </div>
        
        `,
        answer: ["think", "didn't", "mind", "mean", "something", "same as"],
      },
    ],
  },
  9: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E9",
    exerciseKey: "img/FriendsPlus/Page80/Key/E9answerKey.png",
    audio: "Audios/2.25.mp3",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='2.25' colorimg=#b6d5b9  color='white'></headphone> Listen to the second dialogue again and answer
        the questions.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
        
      <div style='line-height:50px'>
          <b>1</b>&ensp;What two things does the student ask the examiner to repeat or explain?<textarea id=0 rows=2></textarea>
          <b>2</b>&ensp;How does she / he ask for clarification?<textarea id=1 rows=2></textarea>
      </div>
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E10",
    exerciseKey: "img/FriendsPlus/Page80/Key/E10answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Read the task below. Make notes on each section
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
      <img src="img/FriendsPlus/Page80/E10/1.jpg" style='height:8cm'/>
      <textarea id=0 rows=10></textarea>
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  11: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E11",
    exerciseKey: "img/FriendsPlus/Page80/Key/E11answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "11",
        title: `Work in pairs. Take turns to be the patient and
        the doctor. Remember to ask for clarification if you are
        unsure about something
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
        
      
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
  12: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P80-E12",
    exerciseKey: "img/FriendsPlus/Page80/Key/E12answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "12",
        title: `Work in pairs. Discuss this quote. Say to what
        extent you believe it is true, and why.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page80/E12/1.jpg" style='height:1.2cm'/>
        `,
        answer: [
          "The treatments discussed are painkillers that are also anti-inflammatories.",
          "The doctor recommends rest and drinking lots of liquids.",
        ],
      },
    ],
  },
};

export default json;
