import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import Recorder from "../../components/Recorder";
const json = {
  1: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E1",
    exerciseKey: "img/FriendsPlus/Page100/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Read the text. Are there any similar charities
        or events in your country?`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { marginBottom: -23, width: 510, paddingTop: 10 },
    textareaStyle: {
      width: 510,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page100/E1/1.jpg" style='height:23cm'/>
        <div style='line-height:50px'>
          <b>1</b>&ensp;If you wake a sleepwalker, they will have a heart attack.<br>
          <b>2</b>&ensp;You should drink at least eight glasses of water a day.<br>
          <b>3</b>&ensp;Being slightly fat is good for you.<br>
          <b>4</b>&ensp;Sleeping longer at weekends makes up for lost sleep.<br>
        </div>

        `,
        answer: [
          "He is extremely intelligent and he did unusual things at a very young age.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E2",
    exerciseKey: "img/FriendsPlus/Page100/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples of mixed
        conditionals in exercise 1.`,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 450, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,

    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          //   border: "2px solid white",
          //   fontWeight: "bold",
        },
        selectWordStyle: {
          //   fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          `In_2015,_the_charity_Comic_Relief_celebrated_its_30th_anniversary|. 
They_started_‘Red_Nose_Day’,_a_day_when_people_across_the_UK_buy_red_noses_and_all_of_the_money_from_this_goes_towards_Comic_Relief|. 
The_money_raised_by_Comic_Relief_helps_people_living_in_difficult_conditions_in_the_UK_and_around_the_world,_and_it’s_estimated_that_over_50_million_people_would_be_worse_off_today_if_the_charity_hadn’t_been_set_up|. 
The_number_of_children_attending_primary_school_in_Africa_might_not_have_increased_by_60_million_if_the_charity_didn’t_exist|. 
In_2015,_the_British_public_donated_a_staggering_£78.1_million, 
bringing_the_total_raised_by_Comic_Relief_in_its_thirty-year_history_to_over_£1_billion|. 
But_it_couldn’t_have_achieved_all_this_if_it_didn’t_have_the_support_of_the_comedians_who_take_part_and_inspire_the_public_to_give_so_generously. 
          `,
        ],
        answers: ["0-38", "0-22", "0-16", "0-12", "0-18", "0-34"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page100/E2/1.jpg" style='height:6.4cm'/>
      <div style='margin-top:10px;width:26cm'>
        <input id=0 type=Circle />
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E3",
    exerciseKey: "img/FriendsPlus/Page100/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Match the two halves of the mixed conditional
        sentences. `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 340, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    character: "/",
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "458px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "608px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "77px",
              left: "458px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "77px",
              left: "608px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "127px",
              left: "458px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "127px",
              left: "608px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "177px",
              left: "458px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "177px",
              left: "608px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "227px",
              left: "458px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "227px",
              left: "608px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: ["0-3", "2-7", "4-9", "5-8", "1-6"],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          //   border: "2px solid white",
          //   fontWeight: "bold",
        },
        selectWordStyle: {
          //   fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [``],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <input id=0 type=boxMatch />
      <input id=1 type=boxMatch />
      <input id=2 type=boxMatch />
      <input id=3 type=boxMatch />
      <input id=4 type=boxMatch />
      <input id=5 type=boxMatch />
      <input id=6 type=boxMatch />
      <input id=7 type=boxMatch />
      <input id=8 type=boxMatch />
      <input id=9 type=boxMatch />
      <div style='line-height:50px;display:flex'>
       <div style='margin-right:200px'>
        <b>1</b>&ensp;If he’d gone to bed earlier,<br>
        <b>2</b>&ensp;If it wasn’t so cold,<br>
        <b>3</b>&ensp;If I were him,<br>
        <b>4</b>&ensp;If you hadn’t been so rude,<br>
        <b>5</b>&ensp;If they’d remembered the address,<br>
       </div> 
       <div>
        <b>a</b>&ensp;she wouldn’t be angry.<br>
        <b>b</b>&ensp;he wouldn’t be so tired.<br>
        <b>c</b>&ensp;they’d be here by now.<br>
        <b>d</b>&ensp;we might have gone for a run.<br>
        <b>e</b>&ensp;I wouldn’t have made that decision.<br>
       </div> 
      </div>

      `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E4",
    exerciseKey: "img/FriendsPlus/Page100/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the dialogue. Use the verbs in their correct
        form to make mixed conditional sentences.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["answer", "buy", "go", "have", "leave"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 420,
      textAlign: "center",
    },
    character: "/",
    component: T6,
    questions: [
      {
        title: `
       <div style='display:flex'>
            <div>
                    <b>Lee</b><br>
                    <b>May</b><br>
                    <b>Lee</b><br>
                    <b>May</b><br>
                    <b>Lee</b><br><br>
                    <b>May</b><br><br>
                    <b>Lee</b>
            </div>
            <div style='margin-left:20px;width:24cm'>
                Are you going to the fundraising concert later?
                <br> No, but I <sup>1</sup># a ticket if I didn’t have so
                much homework.
                <br> That’s a shame. If you’d finished your homework,
                you <sup>2</sup># with me.
                <br> Anyway, isn’t it sold out?
                <br> It is now – and if I hadn’t seen Leo last night, I
                <sup>3</sup># a ticket either, but he had two
                spare ones.
                <br> Why didn’t you let me know? I <sup>4</sup># the
                homework so late if I’d known you were going.
                <br> Well, I might have called you if you <sup>5</sup>#
                your phone sometimes, but you’re always
                studying!
            </div>
        </div>

        `,
        answer: [
          "would / could / might have bought ",
          "could go",
          "wouldn't / might not have ",
          "wouldn't have left",
          "answered",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E5",
    exerciseKey: "img/FriendsPlus/Page100/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      marginLeft: "18px",
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Write mixed conditional sentences that
        mean the same as the first sentence. Use the words in
        brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "USE OF ENGLISH", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 800,
      marginLeft: "20px",
      paddingTop: 10,
    },
    recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["I would have replied if I could hear you properly."],
        styleElement: { color: "#09758f", marginLeft: "23px" },
        // width: "70%",
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <div style=''>
            <b>1</b>&ensp;I didn’t reply because I can’t hear you properly. (could)<br>
            <hintbox id=0></hintbox>
            <b>2</b>&ensp;Our car broke down this morning, so we have to walk to school now. (wouldn’t)<textarea id=0 rows=3></textarea>
            <b>3</b>&ensp;Beth failed her exams so she isn’t at college this year. (hadn’t)<textarea id=1 rows=3></textarea>
            <b>4</b>&ensp;Because Max didn’t go to university, he doesn’t have a degree. (had)<textarea id=2 rows=3></textarea>
            <b>5</b>&ensp;I didn’t help with the cooking because I’m a terrible cook. (weren’t)<textarea id=3 rows=3></textarea>
            <b>6</b>&ensp;The forecast isn’t good so they didn’t go out. (have)<textarea id=4 rows=3></textarea>
            <b>7</b>&ensp;She’s afraid of flying so she took a boat. (taken)<textarea id=5 rows=3></textarea>
            <b>8</b>&ensp;He didn’t help the tourist because he can’t speak Greek. (would)<textarea id=6 rows=3></textarea>
            <b>9</b>&ensp;9 Jay isn’t good at maths so he couldn’t help us. (could)<textarea id=7 rows=3></textarea>
        </div>

        `,
        answer: [
          "If our car hadn't broken down this morning, we wouldn't have to walk to school now. / We wouldn't have to walk to school now if our car hadn't broken down this morning.",
          "If Beth hadn't failed her exams, she would be at college this year. / Beth would be at college this year if she hadn't failed her exams.",
          "If Max had gone to university, he would have a degree. / Max would have a degree if he had gone to university.",
          "If I wasn't / weren't a terrible cook, I would have helped with the cooking. / I would have helped with the cooking if I wasn't / weren't a terrible cook.",
          "If the forecast was / were good, they would have gone out. / They would have gone out if the forecast was / were good.",
          "If she wasn't / weren't afraid of flying, she wouldn't have taken a boat. / She wouldn't have taken a boat if she wasn't/weren't afraid of flying.",
          "If he spoke Greek, he would have helped the tourist. / He would have helped the tourist if he spoke Greek.",
          "If Jay was / were good at maths, he could have helped us. / Jay could have helped us if he was / were good at maths.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E6",
    exerciseKey: "img/FriendsPlus/Page100/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",
    recorder: true,

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Take turns to ask and answer
        questions with What if … ? about these imaginary
        situations. Use mixed conditionals in your answers.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
            What if …<br>
            <b>1</b>&ensp;you’d been born a boy / girl instead?<br>
            <b>2</b>&ensp;the internet hadn’t been invented?<br>
            <b>3</b>&ensp;time travel were possible?<br>
            <b>4</b>&ensp;you had to live abroad for a year?<br>
            <b>5</b>&ensp;you were asked to give a speech to your school?<br>
        </div>
        <img src="img/FriendsPlus/Page100/E6/1.jpg" style='height:3cm'/>

        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E7",
    exerciseKey: "img/FriendsPlus/Page100/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page100/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 8",
    id: "SB12-2024-U8-P100-E8",
    exerciseKey: "img/FriendsPlus/Page100/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page100/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
