import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E1",
    exerciseKey: "img/FriendsPlus/Page46/Key/E1answerKey.png",
    recorder: true,

    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. What is the theatrical form in
        the photo? Have you seen any performances like this
        live or on TV?`,
        color: "black",
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        left: 50,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: ["film (n)", "flat", "lift (n)", "petrol", "tap", "toilet"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 800,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page46/E1/1.jpg" style='height:8cm'/><br>
        `,
        answer: ["movie", "apartment", "elevator", "gas", "faucet", "restroom"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E2",
    exerciseKey: "img/FriendsPlus/Page46/Key/E2answerKey.png",
    audio: "Audios/1.37.mp3",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `You are going to read an article about <i>Cải Lương</i>, a
        traditional Vietnamese theatrical art form. Match the
        headings (A–G) with paragraphs (1–5). There are two
        extra headings.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Gestures and expressions</b>", "bite your fingernails"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div>
          #<b>A</b>&ensp;The Development of Cải Lương<br>
          #<b>B</b>&ensp;The pioneers<br>
          #<b>C</b>&ensp;The early days<br>
          #<b>D</b>&ensp;A Vibrant Fusion of Arts<br>
          #<b>E</b>&ensp;The Future of Cải Lương<br>
          #<b>F</b>&ensp;Viet Nam’s rich heritage<br>
          #<b>G</b>&ensp;The instrumental ensemble<br>
        </div>
        <div style='width:27cm'>
              <b>1</b>&ensp;Cải Lương, a form of traditional Vietnamese theatre, emerged
              around the 1910s and gained popularity among the communities
              in the south, especially in the Mekong Delta region. In the early
              years, Cải Lương performances were held in makeshift theatres or
              open-air spaces, and the plays were often performed by itinerant
              troupes. Just like a Western circus, they travelled together from
              place to place, with their families. The children grew up under the
              influence of parents, aunts and uncles and became actors and
              actresses when they were kids.<br>
              <b>2</b>&ensp;Cải Lương uses a combination of music, singing, acting, and
              dance to depict a wide range of themes and stories. These include
              historical events, folklore, legends, and contemporary social issues.
              The performances are characterised by vibrant costumes, bold
              makeup, and expressive gestures. With its diverse repertoire,
              Cải Lương offers a reflection on Vietnamese culture, history, values,
              and societal concerns.<br>
              <b>3</b>&ensp;The distinctive music in Cải Lương is created by the combination
              of a variety of instruments. They include the đàn tranh, a 16-stringed
              zither with movable bridges, the đàn nguyệt, a two-stringed
              moon-shaped lute, the đàn cò, a two-stringed fiddle, and a bamboo
              flute. These instruments produce a melodic and unique sound that
              complements the storytelling and singing in Cải Lương.<br>
              <b>4</b>&ensp;Cải Lương owes its development to numerous talented
              individuals who have contributed their skills and creativity
              over the years. Prominent figures like Bảy Nam, Phùng Há
              and Út Trà Ôn, together with others, played crucial roles in
              shaping and popularising Cải Lương. Through their dedication
              and artistry, they elevated Cải Lương to become a cherished
              cultural tradition in Viet Nam. Their contributions continue
              to inspire generations of performers and ensure the
              preservation of this rich theatrical heritage.<br>
              <b>5</b>&ensp;At present, Cải Lương faces concerns about its future
              due to the declining interest among younger generations and
              the influence of modern entertainment. However, efforts to raise
              awareness, engage new audiences, and adapt to new tastes may
              safeguard the sustainability of Cải Lương in the years to come.
        </div>

        `,

        InputRong: true,
        answer: ["", "4", "1", "2", "5", "", "3"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E3",
    exerciseKey: "img/FriendsPlus/Page46/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Choose the correct answers (a–c).
        `,
        color: "black",
        left: -20,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },

    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    hintBox: [
      {
        src: ["annoyance", "anxiety"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-4", "1-0", "2-8", "3-4"],
        initialValue: [],
      },
      Layout: `
      <b>1</b>&ensp;Cải Lương
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=0 type='Circle' />
        </div>
        <div>
            has gained popularity among young audiences.<br>
            offers a depiction of Vietnamese history and culture.<br>
            is influenced by other Western art forms.face
        </div>
       </div>
      <b>2</b>&ensp;The children of Cải Lương performers
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=1 type='Circle' />
        </div>
        <div>
            started acting at a very young age.<br>
           did not want to pursue their parents’ career.<br>
            enjoyed modern entertainment.<br>
        </div>
       </div>
      <b>3</b>&ensp;Cải Lương owes its development to
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=2 type='Circle' />
        </div>
        <div>
            the audience in southern Viet Nam.<br>
           the preservation of this rich heritage.<br>
            the contributions of eminent figures over the years.<br>
        </div>
       </div>
      <b>4</b>&ensp;There is growing concern that    
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=3 type='Circle' />
        </div>
        <div>
          Cải Lương will lose its identity.<br>
          younger generations will lose interest in Cải Lương.<br>
          young Cải Lương performers will not be talented.<br>
        </div>
       </div>
      
      `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E4",
    exerciseKey: "img/FriendsPlus/Page46/Key/E4answerKey.png",
    audio: "Audios/1.38.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.38' colorimg=#9dc5d7  color='white'></headphone> Listen to a radio interview. Who is being
        interviewed?`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 380,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <b>Answer:</b>#

         `,
        answer: ["Mai Trinh, a Cải Lương actress."],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E5",
    exerciseKey: "img/FriendsPlus/Page46/Key/E5answerKey.png",
    audio: "Audios/1.38.mp3",

    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='1.38' colorimg=#9dc5d7  color='white'></headphone> Listen again. Decide whether these statements
        are true or false. Write T or F.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 100,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["concerned", "else", "now", "subject"],
        styleElement: { color: "#009147", marginRight: "30px" },
        // width: "70%",
      },
      {
        src: ["<b>Moving onto another topic</b>"],
        styleElement: { color: "#009147", marginRight: "30px" },
        // width: "70%",
      },
    ],

    component: T6,
    questions: [
      {
        title: `
        
          <b>1</b>&ensp;Mai Trinh’s involvement in Cải Lương began at a young age. #<br>
          <b>2</b>&ensp;Her first stage performance in Phù Đổng Thiên Vương left a lasting impression on her. #<br>
          <b>3</b>&ensp;Thanks to her parents, she got several significant roles, which shaped her career. #<br>
          <b>4</b>&ensp;The future of Cải Lương faces challenges due to the impact of modern entertainment. #<br>
        
        
        `,
        answer: ["true|T", "true|T", "F|false", "true|T"],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E6",
    exerciseKey: "img/FriendsPlus/Page46/Key/E6answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 700 },
    titleQuestion: [
      {
        num: "6",
        title: `Work in groups. Research and prepare a
        presentation about <i>Cải Lương</i>. Present it to the whole class.
        `,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Compound adjectives</b>"],
        styleElement: { color: "#42429c", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        
        `,
        answer: [
          "The student says phrases 1 and 3.",
          "The student doesn't mention the second bullet point.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E7",
    exerciseKey: "img/FriendsPlus/Page46/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Read the <span style='color:#009147'>Reading Strategy 2</span>. How could you
        describe the things below if you didn’t know or couldn’t
        remember the words?
        `,
        color: "black",
        left: 0,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 70,
      paddingTop: 10,
    },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page46/E7/1.jpg" style='height: 9.5cm'/><br>
            <div style='line-height:50px'>
              <b>1</b>&ensp;A party hat <br>
              <b>2</b>&ensp;A bow tie <br>
              <b>3</b>&ensp;Icing<br>
              <b>4</b>&ensp;A waistcoat<br>
          </div>
        </div>
        `,
        answer: ["Am", "Br", "Am", "Am", "Br", "Br"],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E8",
    exerciseKey: "img/FriendsPlus/Page46/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Read the task below, including the three
        question prompts. Choose a different festival each and
        plan what you are going to talk about for each prompt.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page46/E8/1.jpg" style='height: 15.5cm'/><br>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P46-E9",
    exerciseKey: "img/FriendsPlus/Page46/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "biscuit",
          "candy",
          "cell phone",
          "check",
          "motorway",
          "queue",
          "sidewalk",
          "sneakers",
          "torch",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
      {
        src: [
          "bill",
          "cookie",
          "flashlight",
          "highway",
          "line",
          "mobile",
          "pavement",
          "sweets",
          "trainers",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
        marginLeft: 20,
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Take it in turns to do the task in exercise 8.
        Listen carefully when your partner is speaking and check
        whether all three prompts are mentioned.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page46/E8/1.jpg" style='height: 15.5cm'/><br>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
