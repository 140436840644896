import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E1",
    exerciseKey: "img/FriendsPlus/Page119/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with the correct form of the
        verbs in brackets.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: ["be in charge of", "cut", "request", "resign"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: 900,
        marginLeft: 200,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;We saw him #(open) the box and #(take) out a camera.<br>
            <b>2</b>&ensp;My school doesn’t allow #(eat) in the classrooms.<br>
            <b>3</b>&ensp;I’m sure Greg didn’t mean #(upset) anyone.<br>
            <b>4</b>&ensp;I heard him slowly #(walk) down the stairs towards the front door.<br>
            <b>5</b>&ensp;I can’t remember #(lock) my bike but I’m sure I did!<br>
            <b>6</b>&ensp;Could you please stop #(shout)? – I’m trying #(read) my book.<br>
            <b>7</b>&ensp;#(smoke) isn’t as common as it was in the past.<br>
            <b>8</b>&ensp;We could feel the fire #(get) hotter and hotter as the flames got bigger.<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "open",
          "take",
          "eating",
          "to upset",
          "walking",
          "locking",
          "shouting",
          "to read",
          "Smoking",
          "getting",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E2",
    exerciseKey: "img/FriendsPlus/Page119/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Choose the best verb form to complete the sentences.`,
        color: "black",
        left: -30,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: {
      //   marginLeft: 20,
      textAlign: "center",
      width: 200,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "almost",
          "a lot more",
          "even",
          "not quite",
          "nowhere near",
          "slightly",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "265px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          //   color: "white",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-8", "1-8", "2-0", "3-4", "4-4", "5-4", "6-0"],
        initialValue: [],
      },
      Layout: `
 

    <div style='display:flex'>
      <div style='width:13cm'>
            <b>1</b>&ensp;We’re going into town – why not ______ with us?
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=0 type=Circle /></div> 
                    <div>
                            coming <br>
                            to come <br>
                            come
                    </div> 
            </div>
            <b>2</b>&ensp;Catching the plane means ______ home early tomorrow.
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=1 type=Circle /></div> 
                    <div>
                            to leave <br>
                            leave <br>
                            leaving
                    </div> 
            </div>
            <b>3</b>&ensp;He’d sooner ______ football than watch it on TV.
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=2 type=Circle /></div> 
                    <div>
                            play <br>
                            playing <br>
                            to play
                    </div> 
            </div>
            <b>4</b>&ensp;She tried ______ the vase but the shelf was too high.
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=3 type=Circle /></div> 
                    <div>
                            reaching <br>
                            to reach <br>
                            reach
                    </div> 
            </div>
      </div>
      <div style='margin-left:70px;width:13cm'>
            <b>5</b>&ensp;______ a gym is more expensive than running in the park.
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=4 type=Circle /></div> 
                    <div>
                            Join <br>
                            Joining <br>
                            To join
                    </div> 
            </div>
            <b>6</b>&ensp;You’d better ______ your teacher to explain this again.
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=5 type=Circle /></div> 
                    <div>
                            to ask <br>
                            ask <br>
                            asking
                    </div> 
            </div>
            <b>7</b>&ensp;Would you rather ______ the bathroom or do the washingup?
            <div style='display:flex'>
                    <div style='margin-left:15px'><input id=6 type=Circle /></div> 
                    <div>
                            clean <br>
                            cleaning <br>
                            to clean
                    </div> 
            </div>
      </div>
    </div>
      
      
      `,
    },
  },
  3: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E3",
    exerciseKey: "img/FriendsPlus/Page119/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the phrases with the correct preposition.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about", "at", "by", "from", "of", "on", "to", "with"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 100, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;I was going to have a shower, but # second thoughts, I’ll wait until later.<br>
            <b>2</b>&ensp;He hasn’t bought any new clothes # a while.<br>
            <b>3</b>&ensp;Jenny’s been training for months and # last she’s ready to run a marathon.<br>
            <b>4</b>&ensp;Were you # the impression that the test is next week?<br>
            <b>5</b>&ensp;I’m not sure about walking into town. # one thing, it’s pretty cold outside.<br>
            <b>6</b>&ensp;Despite some delays, # the whole we had a good journey.<br>
            <b>7</b>&ensp;He tried # vain to open the window but it was completely stuck.<br>
            <b>8</b>&ensp;It seems his decision to go to university is now # doubt.<br>
        </div>
        `,
        // InputRong: true,
        answer: ["on", "for", "at", "under", "For", "on", "in", "in"],
      },
    ],
  },
  4: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E4",
    exerciseKey: "img/FriendsPlus/Page119/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with adjectives formed from the
        nouns in brackets.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "265px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          //   padding: "0px 7px",
          color: "white",
          //   border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          //   padding: "0px 7px",
          //   borderRadius: "50%",
          color: "black",
          //   border: "2px solid",
          //   borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [`✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`, `✓`],
        answers: [],
        initialValue: [],
      },
      Layout: `
      
     <div style='line-height:50px;display:flex'>
            <div style='margin-right:250px'>
                <b>1</b>&ensp;let someone know<br>
                <b>2</b>&ensp;look forward to<br>
                <b>3</b>&ensp;put off<br>
                <b>4</b>&ensp;hand in<br>
                <b>5</b>&ensp;stand for<br>
                <b>6</b>&ensp;leave out<br>
            </div>
            <div>
                <b>a</b>&ensp;represent<br>
                <b>b</b>&ensp;submit<br>
                <b>c</b>&ensp;inform<br>
                <b>d</b>&ensp;anticipate<br>
                <b>e</b>&ensp;omit<br>
                <b>f</b>&ensp;postpone<br>
            </div>
     </div>
      `,
    },
  },
  5: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E5",
    exerciseKey: "img/FriendsPlus/Page119/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the words
        and phrases below. Say which sentence is formal or informal.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "apologise",
          "inform",
          "let you know",
          "postpone",
          "put off",
          "sorry",
        ],
        styleElement: {
          color: "#91268e",
          marginBottom: 10,
          marginRight: "40px",
        },
        width: "90%",
      },
    ],
    character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
            <b>1</b>&ensp; # about breaking your vase!<br>
            <b>2</b>&ensp;We are writing to # for the late delivery of your order.<br>
            <b>3</b>&ensp;We regret to # you that the item you requested is no longer in stock.<br>
            <b>4</b>&ensp;Just thought I’d better # that we can’t make your party.<br>
            <b>5</b>&ensp;We regret to announce that the meeting has been #.<br>
            <b>6</b>&ensp;We’ll have to # that dinner till next week.<br>
        </div>
        
        `,
        answer: [
          "Sorry (informal)|(informal)",
          "apologise (formal)|(formal)",
          "inform (formal)|(formal)",
          "let you know (informal)|(informal)",
          "postponed (formal)|(formal)",
          "put off (informal)|(informal)",
        ],
      },
    ],
  },
  6: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E6",
    exerciseKey: "img/FriendsPlus/Page119/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E7",
    exerciseKey: "img/FriendsPlus/Page119/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page119/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:15px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P119-E8",
    exerciseKey: "img/FriendsPlus/Page119/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page119/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
