import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E1",
    exerciseKey: "img/FriendsPlus/Page95/Key/E1answerKey.png",
    recorder: true,
    component: T6,
    maxLength: 1,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 50, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the text. Four sentences have been removed. 
          Match missing sentences (A–E) with gaps (1–4). There 
          is one extra sentence.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix:[{text:'SPEAKING',icon:'',color:'#01a951'}]
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:23cm'>
          <b>Dying for a selfie</b>
          <div >
            Most of us have taken a selfie at one time or another, 
            be it a photo of ourselves in a beautiful place, during a 
            performance by our favourite band, or on a memorable 
            night out with friends. Even monarchs, prime ministers 
            and presidents have been seen in the news grinning 
            inanely at the screen of a smartphone. <sup>1</sup># In several 
            cases, this action has had disastrous consequences.<br>
            Take, for example, the Spaniard who was killed by a bull 
            while he was taking a selfie during the annual bull run 
            in a village near his hometown. <sup>2</sup># Incidents such as 
            these led to the death toll from selfie-related incidents 
            reaching double figures for the first time in 2015, 
            overtaking the number of victims of shark attacks. Most of 
            the cases involve people taking selfies while posing with 
            animals, or falling from high places.<br>
            Despite the risks, social media is full of pictures featuring 
            near misses that could have been fatal under other 
            circumstances. Under the caption ‘Most dangerous selfie 
            ever’, a woman posing at a stadium can be seen narrowly 
            avoiding being hit in the head by a baseball approaching 
            her. <sup>3</sup># What is even more worrying, however, is 
            that the woman herself dares others to better the shot, 
            seemingly overlooking the fact that by doing so, she is 
            endangering the lives of her ‘friends’ as well.<br>
            In response to the increasing threat posed by the selfie, 
            the authorities have started to take action. National 
            parks, such as the Waterton Canyon in Denver, Colorado, 
            have closed their gates to visitors who have been getting 
            too close to the bears to get them in the photo. <sup>4</sup># But if instructions like these are really necessary, then 
            perhaps it is about time we asked ourselves which is more 
            important, a ‘like’ or a life?
          
          </div>
          <div style='margin-top:20px; '>
            <b>A</b>&ensp;Or the Singaporean tourist who died when he fell from cliffs on the coast of Bali. <br>
            <b>B</b>&ensp;The alternative to taking unnecessary risks is to use Photoshop to create outrageous pictures.<br>
            <b>C</b>&ensp;The Russian Interior Ministry has launched a public education campaign advising selfie-takers against balancing on dangerous surfaces or posing with their pets, among other things.<br>
            <b>D</b>&ensp;But today, more and more people are putting their lives at risk in an attempt to get the most shocking picture to share on social media. <br>
            <b>E</b>&ensp;Yet the photo has received thousands of ‘likes’, and the comments below it suggest that this behaviour is something to be admired.<br>
          </div>
        </div>

        `,
        answer: ["D", "A", "E", "C"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E2",
    audio: "Audios/3.15.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page95/Key/E2answerKey.png",
    component: T6,
    inputSize: 500,
    stylesTextInput: { width: 900 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: `<headphone name='3.15' colorimg='#94c7d4' color='white' marginleft='-50px' ></headphone> Listen and answer the following questions.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    questions: [
      {
        title: `
      <div >
        <b>1</b>&ensp; Why did we believe that butter was bad for us?<br>#<br>
        <b>2</b>&ensp; When did Nikolai Anichkov start his research on rabbits?<br>#<br>
        <b>3</b>&ensp; In how many countries did Ancel Keys examine data?<br>#<br>
        <b>4</b>&ensp; According to recent study, what is wrong about the warnings against eating butter?<br>#<br>
      </div>
      `,
        answer: [
          "We believed that butter was bad for us because of its high fat content.",
          "1913",
          "6 countries",
          "When consumed in moderation, butter is no worse for us than any other food.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E3",
    exerciseKey: "img/FriendsPlus/Page95/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: { width: 56 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#25839f " }],
      },
    ],

    recorder: true,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Work in pairs. Discuss the following questions.</b>
        <div style=''>
          <b>1</b>&ensp; What are the advantages of blogs or vlogs over traditional diaries?<br>
          <b>2</b>&ensp; What makes a traditional diary still attractive to young people? 
        </div>
      
        
        `,
        answer: ["B", "C", "D", "A"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E4",
    exerciseKey: "img/FriendsPlus/Page95/Key/E4answerKey.png",
    video: "",

    component: T6,
    // recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    textareaStyle: { width: 500 },
    totalInput: 1,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "30cm",
        prefix: [{ text: "WRITING", icon: "", color: "#25839f " }],
      },
    ],
    questions: [
      {
        title: `
        <b>
          In about 120–150 words, summarise the information in the 
         table below. <br>
          The number of schools in Viet Nam from 2019 to 2021
        </b>
        <div style='display:flex'>
          <div><textarea id=0 rows=14></textarea></div>
          <img src="img/FriendsPlus/Page95/E4/1.jpg" style='margin-left:20px;height:14cm'/>
        </div>
      
        
        `,
        answer: ["F|false", "T|true", "F|false", "F|false", "F|false"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E5",
    exerciseKey: "img/FriendsPlus/Page95/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    textAlign: "center",
    stylesTextInput: { width: 320, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "almost",
          "considerably",
          "suddenly",
          "slowly",
          "slightly",
          "rise",
          "change",
          "fall",
          "double",
          "plunge",
        ],
        width: 800,
        borderColor: "white",
        colorHint: "#942690",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", color: "#942690", icon: "" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:25cm'>
            Lifelong learning is following <sup>1</sup># and developing 
            particular skills which are necessary for one person’s 
            job or life. Lifelong learning can only be effective if 
            the learner is self-motivated, <sup>2</sup># and focused. The 
            learner should set his own goal on his own interests. 
            He is also willing to gain more skills though it may take 
            time. He is persistent enough <sup>3</sup># his plans of leaning 
            step by step. He is the one who decides his long journey. 
            No one else can decide what he needs to improve, 
            which course he has to take and <sup>4</sup># for it. 
            Lifelong learning is regarded as one of important 
            characteristics that the employers nowadays look for. 
            It is essential for the employees to seek ways to grow 
            personally and <sup>5</sup>#  this helps them to stand out in 
            the job market. Employers are happy to have lifelong 
            learners at work because they are likely to pick up skills 
            at time, anywhere, train themselves in order to meet 
            the work demands
          </div>
          
      

        `,
        answer: [
          "additional education",
          "voluntary",
          "to carry on",
          "how much time he needs",
          "professionally",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E6",
    exerciseKey: "img/FriendsPlus/Page95/Key/E6answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 655, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Discuss the following question.`,
        color: "black",
        width: "28cm",
        left: 150,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <i>Can you guess why Fermat never considered doing 
          research his job?</i>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P95-E7",
    exerciseKey: "img/FriendsPlus/Page95/Key/E7answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 655, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <img src="img/FriendsPlus/Page95/E6/3.jpg" style='height:60mm'/>

          <div></div>
          <textarea id='0' rows=5></textarea>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
