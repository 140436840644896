import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E1",
    exerciseKey: "img/FriendsPlus/Page20/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Work in pairs. Think of three things that
        might cause friends to fall out.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        
        
        `,
        answer: [
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E2",
    exerciseKey: "img/FriendsPlus/Page20/Key/E2answerKey.png",

    titleQuestion: [
      {
        num: "2",
        title: `<span style='color:#91268e'>KEY PHRASES</span> Read the <span style='color:#1a9955'>Speaking Strategy</span>. and complete
        the phrases with the words below.`,
        color: "black",
        left: 0,
        width: "90%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page20/E2/1.jpg" style='margin-top:20px;height:5.5cm'/>
        <div style='line-height:50px;border-left:4px solid rgb(26,153,85);padding-left:15px'>
          <hintbox id=1></hintbox>
              <b>1</b>&ensp;He’s yawning, so he # be tired or bored.<br>
              <b>2</b>&ensp;She’s laughing, so she # be sad.<br>
              <b>3</b>&ensp;He may / might / could # lonely.<br>
              <b>4</b>&ensp;It looks # if …<br>
              <b>5</b>&ensp;The photo # to show (a living room).<br>
              <b>6</b>&ensp;She looks # a (nice person).<br>
              <b>7</b>&ensp;Judging by (their expressions), I’d # that …<br>
              <b>8</b>&ensp;I can’t be # , but …<br>
              <b>9</b>&ensp;I might be # , but …<br>
              <b>10</b>&ensp;The boy # be (accusing him of something).<br>
        </div>
        `,
        answer: [
          "must",
          "can’t",
          "be",
          "as",
          "appears",
          "like",
          "say",
          "certain",
          "wrong",
          "seems to",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E3",
    exerciseKey: "img/FriendsPlus/Page20/Key/E3answerKey.png",
    recorder: true,

    titleQuestion: [
      {
        num: "3",
        title: `Work in pairs. Describe photos A and B. Use
        phrases from exercise 2 to speculate about the people.`,
        color: "black",
        left: 50,
        width: "90%",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page20/E3/1.jpg" style='margin-top:20px;height:14cm'/>
          
        `,
        answer: ["verb", "verb", "noun", "noun", "noun"],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E4",
    exerciseKey: "img/FriendsPlus/Page20/Key/E4answerKey.png",
    textareaStyle: { width: 800, paddingTop: 5 },

    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:rgb(8,144,73)'>Learn this!</span> box. Make up two more sentences
        about your photo in exercise 3 beginning with <i>must have, might have</i> or <i>can’t have</i>. `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=6></textarea>
          
        `,
        answer: ["verb", "verb", "noun", "noun", "noun"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E5",
    exerciseKey: "img/FriendsPlus/Page20/Key/E5answerKey.png",
    audio: "Audios/1.11.mp3",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='1.11' colorimg=#b3d3b6  color='white'></headphone> Listen to a student doing the task below using
        the photos in exercise 3. Answer the questions below.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page20/E5/1.jpg" style='margin-top:20px;height:4cm'/>
        <div style='display:flex'>
          <div>
          <img src="img/FriendsPlus/Page20/E3/1.jpg" style='margin-top:20px;height:14cm'/>
          </div>
          <div style='width:60%'>
            <b>1</b>&ensp;Are his speculations about the photos similar to yours?<br>#<br>
            <b>2</b>&ensp;What phrases from exercise 2 does he use?<br>#<br>
            <b>3</b>&ensp;What is his overall opinion about friendships and arguments? Do you agree? Why / why not?<br><textarea id=2 rows=6></textarea><br>
          </div>
        </div>
          
        `,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E6",
    exerciseKey: "img/FriendsPlus/Page20/Key/E6answerKey.png",
    recorder: true,
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      margihLeft: 20,
      width: 700,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Student A describes photo A,
        student B describes photo B. Then do the task below.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page20/E6/1.jpg" style='margin-top:20px;height:13cm'/>
        
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E7",
    exerciseKey: "img/FriendsPlus/Page20/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P20-E8",
    exerciseKey: "img/FriendsPlus/Page20/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page20/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
