import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Starter",
    id: "SB12-2024-Starter-P4-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize:500,
    hideBtnFooter:true,
    stylesTextInput:{width:100,paddingTop:10},
    hintBox:[{
      src:["belong to","come across","keep","forget","look forward","recognise","remind","leave"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:150
    }
  ],
    titleQuestion: [
      {
        num: "1",
        title:
          `Complete the sentences with the correct form 
          of the verbs. There are two extra verbs.`,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='line-height:50px;margin:10px 150px'>
          <b>1</b>&ensp;I#an old diary yesterday.<br>
          <b>2</b>&ensp;I#the handwriting – it was mine!<br>
          <b>3</b>&ensp;I'll never#the first time I flew in a plane.<br>
          <b>4</b>&ensp;The diary#me of when I was twelve.<br>
          <b>5</b>&ensp;Now, I’ #to reading all of it.<br>
          <b>6</b>&ensp;On the front of the diary it says ‘This diary#Marina’.
        </div>
      
        
        `,
        answer: ["They went to the dinosaur museum.","She bought a dinosaur model.","They saw dinosaur bones.","She thought it was alive.","He knew it was a model."],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB12-2024-Starter-P4-E2",
    audio: "",
    video: "",
    hideBtnFooter:true,
    component: MatchDots,
    titleQuestion: [
      {
        num: "2",
        title: `Match the first half to the correct second half 
        of the sentence.`,
        color: "black",
        left:-50
      },
    ],
      question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "401px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "512px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
        ],
        answers: ["0-5", "1-4", "2-7", "3-6"],
        initialValue: [],
      },
      Layout: `
      <input id='0' type='boxMatch'/>
      <input id='1' type='boxMatch'/>
      <input id='2' type='boxMatch'/>
      <input id='3' type='boxMatch'/>
      <input id='4' type='boxMatch'/>
      <input id='5' type='boxMatch'/>
      <input id='6' type='boxMatch'/>
      <input id='7' type='boxMatch'/>
      <input id='8' type='boxMatch'/>
      <input id='9' type='boxMatch'/>
      <div style='display:flex'>
        <div style='margin-right:150px'>
          <b>1</b> Another popular way of getting <br>
          <b>2</b> People can rely <br>
          <b>3</b> The use of motorbikes in the<br> countryside results <br>
          <b>4</b> The new highway will lead<br>
          <b>5</b> Việt Nam has heavily invested <br>
        </div>
        <div>
          <b>a</b> on trains for covering long distances.<br>
          <b>b</b> to an increase in the number of motor vehicles.<br>
          <b>c</b> in transport particularly roads, airports, and<br> seaports.<br>
          <b>d</b> around Hà Nội is by bus. <br>
          <b>e</b> from long and narrow paths.<br>
        </div>
      </div>
      `,
    },
  },
  3: {
    unit: "Starter",
    id: "SB12-2024-Starter-P4-E3",
    
    component: T6,
    inputSize:500,
    hideBtnFooter:true,
    stylesTextInput:{width:100,paddingTop:10},
    hintBox:[{
      src:["finish","leave","study","not read","close","not use"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:150
    }
  ],
    titleQuestion: [
      {
        num: "3",
        title:
          `Complete the sentences with the correct past 
          perfect form of the verbs in the box.`,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='line-height:50px;margin-left:150px'>
          <b>1</b> I realised that I#my history book at home.<br>
          <b>2</b> #he shop#by the time you got there? <br>
          <b>3</b> When I#the book, I returned it to the library. <br>
          <b>4</b> Nina#a computer until her dad bought one.<br>
          <b>5</b> How much#they#before the exam? <br>
          <b>6</b> I#the play before I went to the theatre to see it.<br>
        </div>
      
        
        `,
        answer: ["They went to the dinosaur museum.","She bought a dinosaur model.","They saw dinosaur bones.","She thought it was alive.","He knew it was a model."],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB12-2024-Starter-P4-E4",
    
    component: T6,
    inputSize:500,
    textAlign:"center",
    maxLength:5,
    hideBtnFooter:true,
    stylesTextInput:{width:100,paddingTop:10},
    hintBox:[{
      src:["finish","leave","study","not read","close","not use"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:150
    }
  ],
    titleQuestion: [
      {
        num: "4",
        title:
          `<headphone name=1.16 src='' color='black'  typeImg="sound2" ></headphone> Listen to the dialogue. Write true or 
          false. `,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b> The genre of the dialogue is a radio news programme.# <br>
          <b>2</b> The collection included a lot of very common Action Man toys.# <br>
          <b>3</b> The collector had worked for a film company.# <br>
          <b>4</b> The toys in the collection were unused.# <br>
          <b>5</b> Some empty boxes in the collection were valuable, too.# <br>
          <b>6</b> Some rare Action Man clothes sold for  £5,480#.<br>
        </div>
      
        
        `,
        answer: ["They went to the dinosaur museum.","She bought a dinosaur model.","They saw dinosaur bones.","She thought it was alive.","He knew it was a model."],
      },
    ],
  },
};

export default json;
