import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P37-E6",
    exerciseKey: "img/FriendsPlus/Page37/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 170, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Replace the underlined words with some
        collocations from exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Gestures and expressions</b>",
          "bite your fingernails",
          "bow your head",
          "fidget",
          "fold your arms",
          "frown",
          "give a thumbs up",
          "grimace",
          "grin",
          "nod your head",
          "point",
          "pout",
          "purse your lips",
          "raise your eyebrows",
          "shake your head",
          "shrug your shoulders",
          "wink",
          "yawn",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <b>1</b>&ensp;If you raise your <sup>1</sup># in Turkey, people will understand that you mean<br>
          <div style='margin-left:20px'><b>A</b>&ensp;‘no’. &emsp;&emsp; <b>B</b>&ensp;‘I’m confused’. &emsp;&emsp; <b>C</b>&ensp;‘yes’.</div>
        <b>2</b>&ensp;If someone says ‘yes’ with a gesture in Bulgaria, they will<br>
          <div style='margin-left:20px'><b>A</b>&ensp;nod their head. &emsp;&emsp; <b>B</b>&ensp;<sup>2</sup># their head. &emsp;&emsp; <b>C</b>&ensp;shrug their <sup>3</sup># .</div>
        <b>3</b>&ensp;People in Indonesia will feel you are rude if you<br>
          <div style='margin-left:20px'><b>A</b>&ensp;<sup>4</sup># at people. &emsp;&emsp; <b>B</b>&ensp;scratch your head. &emsp;&emsp; <b>C</b>&ensp;lick your lips.</div>
        <b>4</b>&ensp;It’s perfectly polite to <sup>5</sup># noisily while drinking your tea in<br>
          <div style='margin-left:20px'><b>A</b>&ensp;Brazil. &emsp;&emsp; <b>B</b>&ensp;Canada. &emsp;&emsp; <b>C</b>&ensp;Japan.</div>
        <b>5</b>&ensp;Giving a <sup>6</sup># is considered childish in<br>
          <div style='margin-left:20px'><b>A</b>&ensp;Thailand. &emsp;&emsp; <b>B</b>&ensp;Australia. &emsp;&emsp; <b>C</b>&ensp;Italy.</div>

        `,
        answer: [
          "eyebrows",
          "shake",
          "shoulders",
          "point",
          "slurp",
          "thumbs up",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P37-E7",
    exerciseKey: "img/FriendsPlus/Page37/Key/E7answerKey.png",
    audio: "Audios/1.26.mp3",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='1.26' colorimg=#d1b6d5  color='white'></headphone> Listen and check the answers to the quiz. Are
        you surprised by any of the customs and taboos?`,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Gestures and expressions</b>",
          "bite your fingernails",
          "bow your head",
          "fidget",
          "fold your arms",
          "frown",
          "give a thumbs up",
          "grimace",
          "grin",
          "nod your head",
          "point",
          "pout",
          "purse your lips",
          "raise your eyebrows",
          "shake your head",
          "shrug your shoulders",
          "wink",
          "yawn",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
          `A <br> B <br> C`,
        ],
        answers: ["0-0", "1-4", "2-0", "3-8", "4-0"],
        initialValue: [],
      },
      Layout: `

      <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <b>1</b>&ensp;If you raise your eyebrows in Turkey, people will understand that you mean<br>
          <div style='display:flex'>
            <div style='margin-left:15px;margin-right:10px'>
                <input id=0 type=Circle />
            </div>
            <div>‘no’. <br> ‘I’m confused’. <br> ‘yes’.</div>
          </div>
        <b>2</b>&ensp;If someone says ‘yes’ with a gesture in Bulgaria, they will<br>
          <div style='display:flex'>
            <div style='margin-left:15px;margin-right:10px'>
                <input id=1 type=Circle />
            </div>
            <div>nod their head. <br> shake their head. <br> shrug their shoulders .</div>
          </div>
        <b>3</b>&ensp;People in Indonesia will feel you are rude if you<br>
          <div style='display:flex'>
            <div style='margin-left:15px;margin-right:10px'>
                <input id=2 type=Circle />
            </div>
            <div>point at people. <br> scratch your head. <br> lick your lips.</div>
          </div>
        <b>4</b>&ensp;It’s perfectly polite to slurp noisily while drinking your tea in<br>
          <div style='display:flex'>
            <div style='margin-left:15px;margin-right:10px'>
                <input id=3 type=Circle />
            </div>
            <div>Brazil. <br> Canada. <br> Japan.</div>
          </div>
        <b>5</b>&ensp;Giving a thumbs up is considered childish in<br>
          <div style='display:flex'>
            <div style='margin-left:15px;margin-right:10px'>
                <input id=4 type=Circle />
            </div>
            <div>Thailand. <br> Australia. <br> Italy.</div>
          </div>

      `,
    },
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P37-E8",
    exerciseKey: "img/FriendsPlus/Page37/Key/E8answerKey.png",
    audio: "Audios/1.27.mp3",
    component: T6,
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<headphone name='1.27' colorimg=#d1b6d5  color='white'></headphone> Listen to three different situations. Match the
        questions to each situation (1–3).`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          In which situation do they talk about …<br>
          <b>a</b>&ensp;making a good impression?<br>
          <b>b</b>&ensp;dealing with the symptoms of being unwell?<br>
          <b>c</b>&ensp;different ways of expressing yourself?<br>
          <b>Answer</b><br>
          <b>a</b>#<br>
          <b>b</b>#<br>
          <b>c</b>#
        </div>
        `,
        answer: ["situation 3", "situation 1", "situation 2"],
      },
    ],
  },
  9: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P37-E9",
    exerciseKey: "img/FriendsPlus/Page37/Key/E9answerKey.png",
    audio: "Audios/1.27.mp3",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<headphone name='1.27' colorimg=#d1b6d5  color='white'></headphone> Read the <span style='color:#92278f' >Recycle!</span> box. Listen again. Complete
        the sentences using the words in brackets.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page37/E9/1.jpg" style='height:8cm'/>
        <div style='width:27cm'>
            <b>1</b>&ensp;If you can’t control your coughing or sniffing, you #. (might)<br>
            <b>2</b>&ensp;If you use British ways of expressing yourself with Spanish people, they #. (could)<br>
            <b>3</b>&ensp;If you don’t control your body language in an interview, you #. (might not)<br>
        </div>
        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
  10: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P37-E10",
    exerciseKey: "img/FriendsPlus/Page37/Key/E10answerKey.png",
    component: T6,
    textAlign: "center",
    stylesTextInput: { width: 370, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    recorder: true,
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "10",
        title: `Work in pairs. Ask and answer the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#35a367" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:27cm'>
          Which gesture or expression might you use if …<br>
            <b>1</b>&ensp;you wish someone good luck in an exam? <br>
            <b>2</b>&ensp;you are puzzled by a question?<br>
            <b>3</b>&ensp;you don’t know the answer to a question that someone has asked?<br>
        </div>
        <img src="img/FriendsPlus/Page37/E10/1.jpg" style='height:3cm'/>

        `,
        answer: [
          "might annoy people",
          "could think of you as cold and reserved",
          "might not get the job",
        ],
      },
    ],
  },
};

export default json;
