import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  2: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E2",
    exerciseKey: "img/FriendsPlus/Page125/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the verbs below. Use the
        past perfect simple or the past perfect continuous.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 250, paddingTop: 10 },
    hintBox: [
      {
        src: ["not eat", "have", "learn", "put up", "sleep", "think", "wait"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
        width: 900,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
    <div style='width:26cm'>
        <b>1</b>&ensp;1 He # for eight hours and still wasn’t awake when I called.<br>
        <b>2</b>&ensp;2 She was hungry because she #  since breakfast.<br>
        <b>3</b>&ensp;3 I # for half an hour outside the cinema before my friends arrived.<br>
        <b>4</b>&ensp;4 They #  Spanish for five years before they visited Mexico.<br>
        <b>5</b>&ensp;5 I # Lee was at home but he was already in town.<br>
        <b>6</b>&ensp;6 By the age of 30, she #  five different jobs.<br>
        <b>7</b>&ensp;7 We all went to sleep after we #   the tent.<br>
    </div>
        `,
        // InputRong: true,
        answer: [
          "had been sleeping",
          "had not eaten",
          "had been waiting",
          "had been learning",
          "had thought",
          "had had",
          "had put up",
        ],
      },
    ],
  },
  1: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E1",
    exerciseKey: "img/FriendsPlus/Page125/Key/E1answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Complete the sentences with <i>used to</i>  or <i>would</i>.
        Sometimes more than one answer is possible.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: {
      //   marginLeft: 20,
      textAlign: "center",
      width: 350,
      paddingTop: 10,
    },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "almost",
          "a lot more",
          "even",
          "not quite",
          "nowhere near",
          "slightly",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;They #(live) in London but they moved to Paris.<br>
            <b>2</b>&ensp;He was a bad student and #(forget) to do his homework.<br>
            <b>3</b>&ensp;I never #(enjoy) baseball but now I’m a huge fan.<br>
            <b>4</b>&ensp;He #(want) to be a vet but he’s changed his mind.<br>
            <b>5</b>&ensp;They #(go) on holiday twice a year but they can’t afford to nowadays.<br>
            <b>6</b>&ensp;Our old flat was near a park and we #(play) there at weekends.<br>
            <b>7</b>&ensp;My older brother #(have) a moustache but he shaved it off.<br>
            <b>8</b>&ensp;Can you believe that years ago, people #(not / own) smartphones!<br>
            
        </div>
        `,
        // InputRong: true,
        answer: [
          "used to live",
          "used to forget / would forget",
          "used to enjoy",
          "used to want",
          "used to go / would go",
          "used to play / would play",
          "used to have",
          "didn't use to own",
        ],
      },
    ],
  },
  3: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E3",
    exerciseKey: "img/FriendsPlus/Page125/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `Write the adjectives in the correct order.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["about", "at", "by", "from", "of", "on", "to", "with"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
      {
        src: ["The inspector: reserved, loyal"],
        styleElement: { color: "#70abb6", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 250, paddingTop: 10 },
    // character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
            <b>1</b>&ensp;This café serves some #(Spanish, tasty) dishes.<br>
            <b>2</b>&ensp;He used to own a #(big, scary, black) dog.<br>
            <b>3</b>&ensp;Have you seen that #(new, Mexican, funny) film yet?<br>
            <b>4</b>&ensp;We watched as a #(silver, huge) moon rose in the sky.<br>
            <b>5</b>&ensp;The woman was holding a #(tiny, young) baby.<br>
            <b>6</b>&ensp;Who owns that #(red, French, new) car over there?<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "tasty Spanish",
          "scary big black",
          "funny new Mexican",
          "huge silver",
          "tiny young",
          "new red French",
        ],
      },
    ],
  },
  4: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E4",
    exerciseKey: "img/FriendsPlus/Page125/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Choose the correct adjective to complete the sentences.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -30,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "265px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          // color: "white",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a_lonely / an_alone`,
          `asleep / sleeping`,
          `annoyed / angry`,
          `living / alive`,
          `glad / happy`,
          `frightened / afraid`,
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
     <div style='line-height:50px;width:26cm'>
            <div>
                <b>1</b>&ensp;My grandmother lives on her own but she’s not <input id=0 type=Circle /> person.<br>
                <b>2</b>&ensp;Have you heard the expression ‘let <input id=1 type=Circle /> dogs lie’?<br>
                <b>3</b>&ensp;The main character in this book is an <input id=2 type=Circle /> man at the beginning.<br>
                <b>4</b>&ensp;Sadly, her only <input id=3 type=Circle /> relatives live in Australia.<br>
                <b>5</b>&ensp;They were <input id=4 type=Circle /> parents when their son got home safely.<br>
                <b>6</b>&ensp;Someone ought to help that <input id=5 type=Circle /> child.<br>
            </div>
            
     </div>
      `,
    },
  },
  5: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E5",
    exerciseKey: "img/FriendsPlus/Page125/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct form of the words
        and phrases below. Say which sentence is formal or informal.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 300,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "apologise",
          "inform",
          "let you know",
          "postpone",
          "put off",
          "sorry",
        ],
        styleElement: {
          color: "#91268e",
          marginBottom: 10,
          marginRight: "40px",
        },
        width: "90%",
      },
    ],
    character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
            <b>1</b>&ensp; # about breaking your vase!<br>
            <b>2</b>&ensp;We are writing to # for the late delivery of your order.<br>
            <b>3</b>&ensp;We regret to # you that the item you requested is no longer in stock.<br>
            <b>4</b>&ensp;Just thought I’d better # that we can’t make your party.<br>
            <b>5</b>&ensp;We regret to announce that the meeting has been #.<br>
            <b>6</b>&ensp;We’ll have to # that dinner till next week.<br>
        </div>
        
        `,
        answer: [
          "Sorry (informal)|(informal)",
          "apologise (formal)|(formal)",
          "inform (formal)|(formal)",
          "let you know (informal)|(informal)",
          "postponed (formal)|(formal)",
          "put off (informal)|(informal)",
        ],
      },
    ],
  },
  6: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E6",
    exerciseKey: "img/FriendsPlus/Page125/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E7",
    exerciseKey: "img/FriendsPlus/Page125/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page125/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:15px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Grammar Builder and Reference",
    id: "SB12-2024-GBAR-P125-E8",
    exerciseKey: "img/FriendsPlus/Page125/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page125/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
