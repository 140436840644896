import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P31-E6",
    exerciseKey: "img/FriendsPlus/Page31/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 300, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Replace the underlined words with some
        collocations from exercise 5.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px;width:90%'>
          <b>1</b>&ensp;We must stop them from <u>doing something illegal </u>.<br>
          <b>2</b>&ensp;Police officers offered advice on <u>ways of stopping crimes from happening </u>.<br>
          <b>3</b>&ensp;There was a <u>sudden increase in the number of crimes </u> in Birmingham last month.<br>
          <b>4</b>&ensp;There were fewer <u>rapes and murders </u> last year.<br>
          <b>5</b>&ensp;All the data on <u>documented criminal activity </u> is available to the public.<br>
          <b>6</b>&ensp;The threat of long prison sentences doesn’t <u>prevent people from doing things they shouldn’t </u>. <br>
        </div>
        <div style='line-height:47px'>
            <b>Answer</b><br>
            <b>1</b>#<br>
            <b>2</b>#<br>
            <b>3</b>#<br>
            <b>4</b>#<br>
            <b>5</b>#<br>
            <b>6</b>#<br>
        </div>
        `,
        answer: [
          "committing a crime",
          "crime prevention",
          "crime wave",
          "violent crimes",
          "recorded crime",
          "deter crime",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P31-E7",
    exerciseKey: "img/FriendsPlus/Page31/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Use the following words or phrases to write
        a short summary of the text.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px;width:80%'>
          <ul>
              <li>Three reports / change in behaviour /
              small changes in the place</li>
                <li>A psychological experiment / Newscastle
              University // findings / people pay more /
              a picture of the eyes //</li>
                <li>Contrary to expectations / crime rate / in
              the 1990s // The decrease / linked to / ban
              on tetraethyl lead in petrol //</li>
                <li>the use of blue-light street lamps / report /
              prevent crime //</li>
          </ul>
        </div>
        <div style='display:flex'>
          <b>Answer:</b>
          <textarea id=0 rows=8></text>
        </div>
        `,
        answer: [
          `There are three reports of changes in people's behaviour with just small changes in the place. First, a psychological experiment was conducted at Newscastle University. The findings showed that people paid much more for their drinks when there was a picture of the eyes. Second, contrary to expectations, the crime rate decreased dramatically in the 1990s. This decrease was linked to the ban on tetraethyl lead in petrol. Last, the use of blue-light street lamps in Scotland has been reported to prevent crime.`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P31-E8",
    exerciseKey: "img/FriendsPlus/Page31/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Decide if you agree
        or disagree with the statement below. Think
        of three points to support your position.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page31/E8/1.jpg" style='height:7cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P31-E9",
    exerciseKey: "img/FriendsPlus/Page31/Key/E9answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 900, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Use the phrases below to debate
        the statement in exercise 8 with another
        pair. Do you still have the same opinion at
        the end of the debate?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page31/E8/1.jpg" style='height:7cm'/>
        <div style='border-left:5px solid rgb(66,66,156);padding-left:15px'>
          To begin with, …<br>
          The main thing we need to discuss is …<br>
          The problem with your point is …<br>
          Surely you see that …
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
