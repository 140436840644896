import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E1",
    exerciseKey: "img/FriendsPlus/Page71/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> <br> Read the text. Match paragraphs (A–C) with questions
        (1–6). Each paragraph matches two questions.`,

        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    stylesTextInput: { width: 40, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        In which paragraph does the author mention ... <br>
        #<b>1</b>&ensp;an extra received on top of the wages?<br>
        #<b>2</b>&ensp;something that might give away the identity of the worker?<br>
        #<b>3</b>&ensp;a trend which has changed employment prospects?<br>
        #<b>4</b>&ensp;typical consumer behaviour?<br>
        #<b>5</b>&ensp;the difficulty of finding a job in the field?<br>
        #<b>6</b>&ensp;a good reason for doing the job?<br>
        <b style='font-size:30px'>A secret job in the retail trade</b>

      <div style='display:flex;width:25cm'>
        <b>A</b>
        <div style='margin-left:15px'>
          I am in a supermarket, doing my best to look like any
          other shopper browsing the shelves. My mission on this
          trip is to buy something I fancy from the bakery, which
          means I’ll have to interact with the person at the
          counter. I’m hoping to pass off the handwritten notes
          I’m carrying as a shopping list, because no one must
          know why I am here. I have to keep my identity secret
          because I am a mystery shopper.
        </div>
      </div>
      <div style='display:flex;width:25cm'>
       <b>B</b>
        <div style='margin-left:15px'>
            My job involves visiting five to ten different stores a
            day and scoring them on, among other things, their
            appearance and cleanliness. With the €20 I am given
            to spend at each store, I purchase the obligatory item
            that enables me to assess the service I receive at the
            checkout. Adding the value of my purchase to the €225
            I make in a typical day of eight hours of visits and
            two hours filing reports, I earn more than enough to
            live on.
        </div>
      </div>
      <div style='display:flex;width:25cm'>
        <b>C</b>
        <div style='margin-left:15px'>
            However, assignments paying as well as mine are
            becoming few and far between due to the soaring
            demand in my line of work. Retailers increasingly
            need to maintain standards so as to offer consumers
            a quality shopping experience and keep them from
            turning to the internet. However, to date, there are
            more than half a million mystery shoppers registered in
            the UK, making competition for jobs very fierce. Today
            it isn’t only other shoppers I hide my identity from;
            even my friends and family don’t know who I work for
        </div>
      </div>
 
        `,
        answer: ["B", "A", "C", "A", "C", "B"],
      },
    ],
  },
  2: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E2",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    audio: "Audios/2.17.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='2.17' colorimg=#9bc5d6  color='white'></headphone> Listen and underline the correct words to
        complete the sentences.
        `,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `packed / bustling`,
          `watched / spied_on`,
          `stubborn / determined`,
          `generous / extravagant`,
          `chat / gossip`,
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='width:25cm'>
          <b>1</b>&ensp;Speaker 1 describes the film premiere as <input id=0 type=Circle />.<br>
          <b>2</b>&ensp;Speaker 2 says that the singer was <input id=1 type=Circle />.<br>
          <b>3</b>&ensp;Speaker 3 describes the actor as <input id=2 type=Circle />.<br>
          <b>4</b>&ensp;Speaker 4 thinks the footballer is <input id=3 type=Circle />.<br>
          <b>5</b>&ensp;Speaker 5 says that the people in the TV show often <input id=4 type=Circle />.<br>
        
      </div>
      `,
    },
  },
  3: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        Compare the two photos. Then give your opinions about
the different venues of job interviews.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
          <img src="img/FriendsPlus/Page71/E3/1.jpg" style='margin-left:200px;height:14cm'/>
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E4",
    exerciseKey: "img/FriendsPlus/Page71/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 1 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        Every year, your school invites a career counsellor to
give a presentation to the whole school. Write a letter
(180–200 words) to the school magazine in which you
propose who to invite. Include a description of the career
counsellor and suggest topics for the presentation.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E5",
    exerciseKey: "img/FriendsPlus/Page71/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E6",
    exerciseKey: "img/FriendsPlus/Page71/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page71/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E7",
    exerciseKey: "img/FriendsPlus/Page71/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Review Unit 5",
    id: "SB12-2024-U5-P71-E8",
    exerciseKey: "img/FriendsPlus/Page71/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page71/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
