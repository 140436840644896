import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P86-E1",
    exerciseKey: "img/FriendsPlus/Page86/Key/E1answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "although",
          "became",
          "'ve become",
          "despite",
          "in spite",
          "started",
          "'ve started",
          "never",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the email. Look at the phrases in bold. What were 
          the actual words used by the speakers?`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page86/E1/1.jpg" style='margin-left:100px;height:15cm'/>
          <div style="">
            <b style='font-style:italic'>Answer:</b><br>
            <b>1</b>&ensp;#<br>
            <b>2</b>&ensp;#<br>
            <b>3</b>&ensp;#<br>
            <b>4</b>&ensp;#<br>
            <b>5</b>&ensp;#<br>
            <b>6</b>&ensp;#<br>
            <b>7</b>&ensp;#
          </div>`,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P86-E2",
    exerciseKey: "img/FriendsPlus/Page86/Key/E2answerKey.png",
    component: T6,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 450, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <prefix text='Learn this!' icon='' color='#f79126'></prefix> box. Then find examples of the 
          rules in exercise 1.`,
        color: "black",
        left: 50,
      },
    ],
    character: /[,/]/,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page86/E2/1.jpg" style='margin-left:100px;height:14cm'/>
        <div>
          <div style='display:flex'><b>1</b>&ensp;  <textarea id='7' rows='2'></textarea><br></div>
          <div style='display:flex;margin-top:-35px'><b>2</b>&ensp;  <textarea id='8' rows='2'></textarea><br></div>
          <div style='display:flex;margin-top:-35px'><b>3</b>&ensp;  <textarea id='9' rows='2'></textarea><br></div>
          <div style='display:flex;margin-top:-35px'>
            <b>4</b>&ensp;- 
            <div style='margin-left:10px  '>
              <b>1</b>&ensp;#<br>
              <b>2</b>&ensp;#<br>
              <b>3</b>&ensp;#<br>
              <b>4</b>&ensp;#<br>
              <b>5</b>&ensp;#<br>
              <b>6</b>&ensp;#<br>
              <b>7</b>&ensp;#
            </div>
          </div>
        </div>
        
        `,
        answer: [
          "I - he, my - his",
          "my friends - his friends",
          "I - he",
          "you - he",
          "you - he",
          "your brother - my brother, you - me",
          "you - I",
          "had posted, had left, would not forget, had relied.",
          "My mum says that my brother needs support from me.",
          "I said that he shouldn't spend time reading them. / My mum says that I shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P86-E3",
    exerciseKey: "img/FriendsPlus/Page86/Key/E3answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    textareaStyle: {
      width: 1000,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 1000, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Read what Daisy said to Eva. Rewrite the paragraph, changing the direct speech to reported speech.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page86/E3/1.jpg" style='height:4cm'/>
        <div style='margin-left:50px'>
          <textarea id=0 rows=5></textarea>
        </div>
      
        
        `,
        answer: [
          "Daisy told Eva that she had read the comments on her brother's photos. She said that besides three negative comments, most of them had been encouraging enough. She guessed that Eva's brother was too sensitive. She agrees that they should not rely too much on other people's attitude on social media, but Eva's mum was right when she asked her to give him more support.",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P86-E4",
    exerciseKey: "img/FriendsPlus/Page86/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the sentences, changing the direct speech to 
          reported speech. Use the reporting verb in brackets. 
          (Each speaker is / was talking to you.)`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;Lee: "I'm scared of heights." (tells)<br>#<br>
          <b>2</b>&ensp;Simon: "You should always tell the truth." (said)<br>#<br>
          <b>3</b>&ensp;Alison: "I've never believed in ghosts." (says) <br>#<br>
          <b>4</b>&ensp;Pete: "I'll be watching TV this evening." (has said)<br>#<br>
          <b>5</b>&ensp;May: "You might see me at the concert." (told)<br>#<br>
          <b>6</b>&ensp;Jim: "I won't be late tomorrow." (says)<br>#<br>
        </div>
      
      
        
        `,
        answer: [
          "Lee tells me that he's scared of heights.",
          "Simon said that I should always tell the truth.",
          "Alison says that she's never believed in ghosts.",
          "Pete has said that he'll be watching TV this evening.",
          "May told me that I might see her at the concert.",
          "Jim says that he won't be late tomorrow.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P86-E5",
    exerciseKey: "img/FriendsPlus/Page86/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read the <prefix text='Learn this!' icon='' color='#f79126'></prefix> box and rewrite each sentence 
          using the word in brackets.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 700, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page86/E5/1.jpg" style='height:150mm'/>
        <div style='width:20cm'>
          <b>1</b>&ensp;‘What time will the café open tomorrow?’ he asked us. (next day)<br><textarea id='0' rows=2></textarea><br>
          <div style='margin-top:-55px'><b>2</b>&ensp;‘Shall I call you a taxi?’ Pam asked me. (whether)<br><textarea id='1' rows=2></textarea><br></div>
          <div style='margin-top:-55px'><b>3</b>&ensp;‘Don’t believe anything Jim says,’ Fran told us. (not)<br><textarea id='2' rows=2></textarea><br></div>
          <div style='margin-top:-55px'><b>4</b>&ensp;‘We must get to the airport on time,’ I told her. (that) <br><textarea id='3' rows=2></textarea><br></div>
          <div style='margin-top:-55px'><b>5</b>&ensp;‘Who did you see at the club last night?’ Tony asked me. (before)<br><textarea id='4' rows=2></textarea><br></div>
        </div>
      
      
        
        `,
        answer: [
          "He asked us what time the café would open the next day.",
          "Pam asked me whether she should call me a taxi.",
          "Fran told us not to believe anything Jim said.",
          "I told her that we must to get to the airport on time.|I told her that we must to get to the airport on time. / I told her that we had to get to the airport on time.|I told her that we had to get to the airport on time.",
          "Tony asked me who I had seen at the club the night before.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P7-E6",
    exerciseKey: "img/FriendsPlus/Page7/Key/E6answerKey.png",
    recorder: true,
    component: T6,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Tell your partner about an occasion 
          when somebody asked you a lot of information about your 
          post on social media. (Invent one if necessary.) Include 
          reported speech and reported questions in your account.`,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: ``,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
