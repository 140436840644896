import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E1",
    exerciseKey: "img/FriendsPlus/Page36/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:#92278f' >VOCABULARY</span> Look at the people in the photos (A–E). What feelings does their
        body language express?
        `,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page36/E1/2.jpg" style='height:8cm'/>
          <img src="img/FriendsPlus/Page36/E1/3.jpg" style='height:11cm'/>
        </div>
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page36/E1/1.jpg" style='height:8cm'/>
          <div style='line-height:47px;margin-left:20px'>
            <b>Answer</b><br>
            <b>A</b>&ensp;#<br>
            <b>B</b>&ensp;#<br>
            <b>C</b>&ensp;#<br>
            <b>D</b>&ensp;#<br>
            <b>E</b>&ensp;#<br>
          </div>
        </div>
        `,
        answer: ["confused", "happy", "anxious", "annoyed", "surprised"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E2",
    exerciseKey: "img/FriendsPlus/Page36/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. What solution did the British government
        suggest for a problem in the 18th century? Was it successful?
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Gestures and expressions</b>",
          "bite your fingernails",
          "bow your head",
          "fidget",
          "fold your arms",
          "frown",
          "give a thumbs up",
          "grimace",
          "grin",
          "nod your head",
          "point",
          "pout",
          "purse your lips",
          "raise your eyebrows",
          "shake your head",
          "shrug your shoulders",
          "wink",
          "yawn",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page36/E2/1.jpg" style='height:3cm'/>
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page36/E1/2.jpg" style='height:8cm'/>
          <img src="img/FriendsPlus/Page36/E1/3.jpg" style='height:11cm'/>
        </div>
          <img src="img/FriendsPlus/Page36/E1/1.jpg" style='height:8cm'/>
          <div style='line-height:47px;margin-left:20px'>
            <b>Answer</b><br>
            <b>A</b>&ensp;#<br>
            <b>B</b>&ensp;#<br>
            <b>C</b>&ensp;#<br>
            <b>D</b>&ensp;#<br>
            <b>E</b>&ensp;#<br>
          </div>
        `,
        // InputRong: true,
        answer: [
          "The woman is shrugging her shoulders and raising her eyebrows.",
          "The man is giving a thumbs up.",
          "The woman is frowning and biting her fingernails.",
          "The man is frowning.",
          "The woman is raising her eyebrows.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E3",
    exerciseKey: "img/FriendsPlus/Page36/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Answer the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 850, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "annoyance",
          "anxiety",
          "boredom",
          "confusion",
          "disapproval",
          "disgust",
          "fear",
          "friendliness",
          "happiness",
          "ignorance",
          "indifference",
          "interest",
          "pain",
          "shame",
          "shock",
          "surprise",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page36/E2/1.jpg" style='height:3cm'/>`,
        // InputRong: true,
        answer: [
          "The British government decided to try to prevent the kinds of accidents that had happened at sea in 1707.",
          "He was a carpenter rather than a well-known scientist.",
          "Because conditions on board damaged the clocks.",
          "The H probably refers to the first letter of the inventor’s surname (Harrison), and the number 4 to the fact that it was the fourth sea clock that he had made.",
          "A TV audience was asked to choose one challenge from a list of six.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E4",
    audio: "Audios/1.25.mp3",
    exerciseKey: "img/FriendsPlus/Page36/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.25' colorimg=#d1b6d5  color='white'></headphone> <span style='color:#92278f' >VOCABULARY</span> Listen and write in your notebook the sounds below in
        the order you hear them.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 110,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <table style="width: 60%; border-collapse: collapse;">
            <tr>
                <th style="width:20%; padding: 8px; text-align: left; border: 2px solid black;text-align:center;">Order</th>
                <th style="width:40%; padding: 8px; text-align: left; border: 2px solid black;text-align:center;">The sound</th>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">clear your throat</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">cough</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">gasp</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">hiccup</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">sigh</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">slurp</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">sneeze</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">sniff</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">snore</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">tut</td>
            </tr>
        </table>
        `,
        answer: ["8", "5", "9", "2", "3", "7", "1", "4", "10", "6"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E5",
    exerciseKey: "img/FriendsPlus/Page36/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Which sound(s) are you likely to make when you …
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 500,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
          <b>1</b>&ensp;have a bad cold? (more than one possible answer)<br>#<br>
          <b>2</b>&ensp;are disappointed or sad?<br>#<br>
          <b>3</b>&ensp;are shocked?<br>#<br>
          <b>4</b>&ensp;disapprove of something?<br>#<br>
          <b>5</b>&ensp;are drinking a very hot liquid?<br>#<br>
        </div>
        `,
        answer: [
          "clear your throat, cough, sneeze, sniff",
          "sigh",
          "gasp",
          "tut",
          "slurp",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E6",
    exerciseKey: "img/FriendsPlus/Page36/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.23' colorimg=#9ac7d4  color='white'></headphone> Listen again. Choose the correct answers (a–c).`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-0", "1-0", "2-8", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `

      
      <b>1</b>&ensp;On the podcast, they begin by talking about what happened
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=0 type=Circle /></div>
          <div>
                    on a TV programme. <br>
              on a podcast.<br>
              in a government debate.
          </div>
       </div>
      <b>2</b>&ensp;The prize is being offered to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=1 type=Circle /></div>
          <div>
                    any scientist. <br>
              government scientists.<br>
              famous scientists.
          </div>
       </div>
      <b>3</b>&ensp;Dr Kenny uses the example of the warplane to demonstrate that the prize money
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=2 type=Circle /></div>
          <div>
                    is a lot. <br>
              is reasonable.<br>
              isn’t so much.
          </div>
       </div>
      <b>4</b>&ensp;World health organisations are worried that antibiotics
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=3 type=Circle /></div>
          <div>
                    endanger people’s lives. <br>
              are less successful.<br>
              have become unnecessary.
          </div>
       </div>
      <b>5</b>&ensp;If nobody wins the challenge, in the future, doctors may not be able to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=4 type=Circle /></div>
          <div>
                    carry out some types of surgery.<br>
              treat patients for certain illnesses.<br>
              work in hospitals.       
          </div>
       </div>

      `,
    },
  },
  7: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E7",
    exerciseKey: "img/FriendsPlus/Page36/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page36/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 3",
    id: "SB12-2024-U3-P36-E8",
    exerciseKey: "img/FriendsPlus/Page36/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page36/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
