import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  5: {
    unit: "Starter",
    id: "SB12-2024-Starter-P5-E5",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize:500,
    hideBtnFooter:true,
    stylesTextInput:{width:100,paddingTop:10},
    hintBox:[{
      src:["belong to","come across","keep","forget","look forward","recognise","remind","leave"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:150
    }
  ],
    titleQuestion: [
      {
        num: "5",
        title:
          `Choose the correct options.`,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color:"black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "was_building / built / ’d_built ",
          "’d_written / was_writing /  wrote",
          "was_finding / found /  had_found",
          "’d_looked / looked / was_looking",
          "was / was_being / had_been",
          "had_opened / was_opening / opened_it",
          "married / had_married / was_marrying",
        ],
        answers: ['0-6', '1-12', '4-0', '2-6', '3-0'],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px;width:20cm'>
        <b>1</b> Ake Viking was in the middle of some work.  He <input id='0' type='Circle'/> a boat.<br>
        <b>2</b> He was lonely. So he <input id='1' type='Circle'/> a letter and put it in a bottle.<br>
        <b>3</b> One day, a fisherman <input id='2' type='Circle'/> the bottle while he <input id='3' type='Circle'/> through his nets.<br>
        <b>4</b> The message <input id='4' type='Circle'/> in the  bottle for a long time when the fisherman <input id='5' type='Circle'/> .<br>
        <b>5</b> Later Ake <input id='6' type='Circle'/> Paolina, the fisherman’s daughter.<br>
      </div>
      `,
    },
  },
  6: {
    unit: "Starter",
    id: "SB12-2024-Starter-P5-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize:500,
    hideBtnFooter:true,
    stylesTextInput:{width:100,paddingTop:10},
    hintBox:[{
      src:["belong to","come across","keep","forget","look forward","recognise","remind","leave"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:150
    }
  ],
    titleQuestion: [
      {
        num: "6",
        title:
          `Choose the correct words.`,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color:"black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
          "a <br> b <br> c",
        ],
        answers: ['0-6', '1-12', '4-0', '2-6', '3-0'],
        initialValue: [],
      },
      Layout: `

      <div style='width:20cm'>
          Have I <sup><b>1</b></sup>............. you about the time I got into trouble 
          at the shops? It was the first day <sup><b>2</b></sup>............. the 
          summer holidays and I was shopping in town with 
          friends.<br>
          We were walking around a fashionable gift shop 
          and looking at some lava lamps <sup><b>3</b></sup>............. I suddenly 
          knocked one of them over! It fell onto the floor 
          and it was damaged. I remember <sup><b>4</b></sup>............. that I 
          was in big trouble! <sup><b>5</b></sup>............., the shop assistant 
          quickly called the manager. As you can imagine, I 
          felt upset and very afraid. The manager was a bit 
          angry, but <sup><b>6</b></sup>............. he said that I didn’t need to pay 
          for it.<br>
          And that’s <sup><b>7</b></sup>............. . All in all, it <sup><b>8</b></sup>............. a great 
          experience, but I learned to be more careful in 
          shops!
          
      </div>
      <div style='position:relative'>
        
        <div style='height:10cm'>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='0' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='1' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='2' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='3' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='4' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='5' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='6' type='Circle'/></div>
          <div style='display:flex;justify-content:space-around;width:16cm'><input id='7' type='Circle'/></div>
        </div>
        <div style='position:absolute;top:0px;left:0px;font-weight:bold;line-height:41.5px'>
          1<br>2<br>3<br>4<br>5<br>6<br>7<br>8
        </div>
        
        <div style='position:absolute;top:0px;left:65px;line-height:41.5px'>
          said<br>of<br>while<br>to<br>Meanwhile<br>lucky<br>those<br>wasn’t
        </div>
        <div style='position:absolute;top:0px;left:320px;line-height:41.5px'>
          tell<br>on<br>when<br>feel<br>While<br>luckily<br>them<br>was
        </div>
        <div style='position:absolute;top:0px;left:580px;line-height:41.5px'>
          told<br>at<br>during<br>feeling<br>When<br>luck<br>it<br>weren’t 
        </div>
      </div>

      `,
    },
  },
  
  7: {
    unit: "Starter",
    id: "SB12-2024-Starter-P5-E7",
    
    component: T6,
    inputSize:500,
    hideBtnFooter:true,
    textAlign:"center",
    stylesTextInput:{width:200,paddingTop:10},
    hintBox:[{
      src:["Afterwards","Amazingly","at","finally","Meanwhile","The moment","While"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:0
    }
  ],
    titleQuestion: [
      {
        num: "7",
        title:
          `Complete the text with the words.`,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img src='img/FriendsPlus/Page5/E7/1.jpg' alt='' style='margin:0px 20px;height:11cm'/>
        <div style='line-height:50px;width:18cm'>
            It all started <sup>1</sup># 7.30 in the morning 
            when my family got to Phong Nha town. We 
            were all eager to visit some of the fascinating 
            caves there. <sup>2</sup># , we enjoyed the scenery 
            of Việt Nam on our long journey. From the 
            town centre, we took a 30-minute boat ride on 
            the Son River to Phong Nha cave. <br>
            <sup>3</sup># that we arrived, we were impressed 
            by its rock formations and its underground 
            river. The cave is quite well-lit with high ceilings 
            and large dry areas. <sup>4</sup># we climbed 
            more than 500 steps to reach Tiên Sơn cave 
            on the right. The inside landscape looks 
            attractive and mysterious. <br>
            <sup>5</sup># I was walking through the 
            stalagmites and stalactites, my brother started 
            making a deep drumming sound by tapping on 
            one of them. <br>
            <sup>6</sup># , we were able to explore both caves 
            in only one trip. The time spent there was 
            evidence of the incredible power of nature. 
            When we 7 got back, it was 9.00.
        </div>
      
        
        `,
        answer: ["They went to the dinosaur museum.","She bought a dinosaur model.","They saw dinosaur bones.","She thought it was alive.","He knew it was a model."],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB12-2024-Starter-P5-E4",
    
    component: T6,
    inputSize:500,
    textAlign:"center",
    maxLength:5,
    hideBtnFooter:true,
    stylesTextInput:{width:100,paddingTop:10},
    hintBox:[{
      src:["finish","leave","study","not read","close","not use"],
      width:600,
      borderColor:"#5f50a0",
      marginLeft:150
    }
  ],
    titleQuestion: [
      {
        num: "4",
        title:
          `<headphone name=1.16 src='' color='black'  typeImg="sound2" ></headphone> Listen to the dialogue. Write true or 
          false. `,
        color: "black",
        left:50
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b> The genre of the dialogue is a radio news programme.# <br>
          <b>2</b> The collection included a lot of very common Action Man toys.# <br>
          <b>3</b> The collector had worked for a film company.# <br>
          <b>4</b> The toys in the collection were unused.# <br>
          <b>5</b> Some empty boxes in the collection were valuable, too.# <br>
          <b>6</b> Some rare Action Man clothes sold for  £5,480#.<br>
        </div>
      
        
        `,
        answer: ["They went to the dinosaur museum.","She bought a dinosaur model.","They saw dinosaur bones.","She thought it was alive.","He knew it was a model."],
      },
    ],
  },
};

export default json;
