import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E1",
    exerciseKey: "img/FriendsPlus/Page48/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Tell your partner about a memorable holiday you went on. Talk about:
        `,
        color: "black",
        width: "28cm",
        left: 0,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex;justify-content:space-between'>
          <ul>
              <li> where you went.</li>
              <li> how you got there.</li>
              <li> where you stayed.</li>
          </ul>
          <div style='margin-left:100px'>
            <ul>
                <li> what you did.</li>
                <li> who you went with.</li>
                <li> what the weather was like.</li>
            </ul>
          </div>
        </div>
        `,
        answer: ["confused", "happy", "anxious", "annoyed", "surprised"],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E2",
    exerciseKey: "img/FriendsPlus/Page48/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Make two lists of the words below, dividing them into a) holidays
        and b) accommodation. Check the meaning of all the words.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 800, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holidays and holiday accommodation</b>",
          "adventure holiday",
          "B&B",
          "backpacking",
          "beach holiday",
          "beach house",
          "cabin",
          "camper van",
          "camping",
          "campsite",
          "caravan",
          "city break",
          "cottage",
          "couch-surfing",
          "cruise",
          "cycling holiday",
          "ecotourism",
          "guest house",
          "holiday camp",
          "holiday home",
          "hotel",
          "houseboat",
          "house swap",
          "package holiday",
          "self-catering apartment",
          "tent",
          "time-share apartment",
          "villa",
          "volunteering",
          "winter sports holiday",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
            <b>Answer</b><br>
            <b>a)</b>&ensp;holidays:<textarea id=0 rows=3></textarea><br>
            <b>b)</b>&ensp;holidays accommodation:<textarea id=1 rows=3></textarea><br>
            
        </div>
        `,
        // InputRong: true,
        answer: [
          `adventure holiday, backpacking, beach holiday, camping, city break, couch-surfing, cruise, cycling holiday, ecotourism, house swap, package holiday, volunteering, winter sports holiday`,
          `B&B, beach house, cabin, camper van, campsite, caravan, cottage, guest house, holiday camp, holiday home, hotel, houseboat, self-catering apartment, tent, time-share apartment, villa, youth hostel`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E3",
    exerciseKey: "img/FriendsPlus/Page48/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the text again. Answer the questions.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Holidays and holiday accommodation</b>",
          "adventure holiday",
          "B&B",
          "backpacking",
          "beach holiday",
          "beach house",
          "cabin",
          "camper van",
          "camping",
          "campsite",
          "caravan",
          "city break",
          "cottage",
          "couch-surfing",
          "cruise",
          "cycling holiday",
          "ecotourism",
          "guest house",
          "holiday camp",
          "holiday home",
          "hotel",
          "houseboat",
          "house swap",
          "package holiday",
          "self-catering apartment",
          "tent",
          "time-share apartment",
          "villa",
          "volunteering",
          "winter sports holiday",
          "youth hostel",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    stylesTextInput: { width: 850, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex'><b>In photo A:</b><textarea id=0 rows=2></textarea></div>
        <div style='display:flex'><b>In photo B:</b><textarea id=1 rows=2></textarea></div>
        <div style='display:flex'><b>In photo C:</b><textarea id=2 rows=2></textarea></div>
        <img src="img/FriendsPlus/Page48/E3/1.jpg" style='height:18cm'/>
        
        `,
        // InputRong: true,
        answer: [
          "there is a beautiful beach house with a roof made of grass or leaves. The house is on the beach, right in front of the sea.",
          "there are some young people on a cycling holiday. They have stopped at a river bank.",
          "there are some large tents and camper vans at a camping site.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E4",
    exerciseKey: "img/FriendsPlus/Page48/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='1.25' colorimg=#d1b6d5  color='white'></headphone> <span style='color:#92278f' >VOCABULARY</span> Listen and write in your notebook the sounds below in
        the order you hear them.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 110,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <table style="width: 60%; border-collapse: collapse;">
            <tr>
                <th style="width:20%; padding: 8px; text-align: left; border: 2px solid black;text-align:center;">Order</th>
                <th style="width:40%; padding: 8px; text-align: left; border: 2px solid black;text-align:center;">The sound</th>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">clear your throat</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">cough</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">gasp</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">hiccup</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">sigh</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">slurp</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">sneeze</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">sniff</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">snore</td>
            </tr>
            <tr>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">#</td>
                <td style="padding: 8px; border: 2px solid black;text-align:center;">tut</td>
            </tr>
        </table>
        `,
        answer: ["8", "5", "9", "2", "3", "7", "1", "4", "10", "6"],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E5",
    exerciseKey: "img/FriendsPlus/Page48/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Which sound(s) are you likely to make when you …
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      // textAlign: "center",
      // fontSize: 20,
      width: 500,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Human sounds</b>",
          "clear your throat",
          "cough",
          "gasp",
          "hiccup",
          "sigh",
          "slurp",
          "sneeze",
          "sniff",
          "snore",
          "tut",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
          <b>1</b>&ensp;have a bad cold? (more than one possible answer)<br>#<br>
          <b>2</b>&ensp;are disappointed or sad?<br>#<br>
          <b>3</b>&ensp;are shocked?<br>#<br>
          <b>4</b>&ensp;disapprove of something?<br>#<br>
          <b>5</b>&ensp;are drinking a very hot liquid?<br>#<br>
        </div>
        `,
        answer: [
          "clear your throat, cough, sneeze, sniff",
          "sigh",
          "gasp",
          "tut",
          "slurp",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E6",
    exerciseKey: "img/FriendsPlus/Page48/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='1.23' colorimg=#9ac7d4  color='white'></headphone> Listen again. Choose the correct answers (a–c).`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-0", "1-0", "2-8", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `

      
      <b>1</b>&ensp;On the podcast, they begin by talking about what happened
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=0 type=Circle /></div>
          <div>
                    on a TV programme. <br>
              on a podcast.<br>
              in a government debate.
          </div>
       </div>
      <b>2</b>&ensp;The prize is being offered to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=1 type=Circle /></div>
          <div>
                    any scientist. <br>
              government scientists.<br>
              famous scientists.
          </div>
       </div>
      <b>3</b>&ensp;Dr Kenny uses the example of the warplane to demonstrate that the prize money
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=2 type=Circle /></div>
          <div>
                    is a lot. <br>
              is reasonable.<br>
              isn’t so much.
          </div>
       </div>
      <b>4</b>&ensp;World health organisations are worried that antibiotics
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=3 type=Circle /></div>
          <div>
                    endanger people’s lives. <br>
              are less successful.<br>
              have become unnecessary.
          </div>
       </div>
      <b>5</b>&ensp;If nobody wins the challenge, in the future, doctors may not be able to
       <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'><input id=4 type=Circle /></div>
          <div>
                    carry out some types of surgery.<br>
              treat patients for certain illnesses.<br>
              work in hospitals.       
          </div>
       </div>

      `,
    },
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E7",
    exerciseKey: "img/FriendsPlus/Page48/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. What important problem in
        the world would you add to the list in exercise 4?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page48/E4/1.jpg" style='margin-left:100px;height:11cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P48-E8",
    exerciseKey: "img/FriendsPlus/Page48/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page48/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
