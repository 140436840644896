import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E1",
    exerciseKey: "img/FriendsPlus/Page33/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. If you needed advice for a
        problem, what would you do? Discuss the options below
         `,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E2",
    exerciseKey: "img/FriendsPlus/Page33/Key/E2answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:rgb(8,144,73)' >Speaking Strategy</span> Work in pairs. Read the task. Decide on an
        argument for and an argument against the topic.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: ["age", "common", "course", "fact", "first", "purpose", "trouble"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page33/E2/1.jpg" style='height:4cm'/>
        
          
        `,
        // InputRong: true,
        answer: ["isn't it", "do you", "don't you", "can't it", "aren't I"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E3",
    exerciseKey: "img/FriendsPlus/Page33/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the essay. Do you agree with the conclusion?
        Why? / Why not?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 850, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='background:rgb(243,240,221);padding:10px 20px; border-radius:5px; box-shadow:2px 3px 5px 5px gray; width:25cm'>
          There are many internet forums and chat rooms for young
          people where they can discuss their personal problems with
          their peers. There are both advantages and disadvantages to
          looking for solutions in this way.
          <div style='margin-top:15px'>The first advantage of the internet is the huge amount of
          information on it. You can find an answer to any question you
          have, provided that you search correctly. And no one can
          deny that chat rooms can be very supportive and can help
          you to feel better. Even if you feel you are the only person
          with your particular problem, you will soon realise that there
          is always someone else who feels the same way. </div>
          <div style='margin-top:15px'>So are there any disadvantages to using the internet in this
          way? Although it is true that there is a lot of information
          online, we should also remember that it is not always
          accurate. Moreover, teenagers in chat rooms are not
          necessarily the best judges of a situation. Even if you get
          advice, it may not be the most helpful advice you could
          receive. Finally, the internet is impersonal. People who you
          chat to online don’t know you well, so they won’t understand
          your particular situation.</div>
          <div style='margin-top:15px'>In conclusion, even though the internet can be helpful
          when you have a problem, I don’t think it is a substitute for
          speaking to the people in your life who know and care about
          you. Young people shouldn’t forget to talk to the people that
          they have a real relationship with.</div>
        </div>
        <div style='margin-top:50px'>
          <textarea id=0 rows=5></textarea>
        
        </div>
        `,
        // InputRong: true,
        answer: [
          "The kitchen was dirty last Friday and there were dirty plates in the sink.",
          "Yes.",
          "They agree to clean the flat together every Tuesday evening.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E4",
    exerciseKey: "img/FriendsPlus/Page33/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Read the <span style='color:#2483c5' >Writing Strategy</span>. Then read and compare the
        structure in the essay. Are either of your views from
        exercise 2 expressed by the writer?`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 110,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page33/E4/1.jpg" style='margin-left:100px;height:8cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "word",
          "just",
          "mind",
          "perhaps",
          "feel",
          "bring",
          "should",
          "on",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E5",
    exerciseKey: "img/FriendsPlus/Page33/Key/E5answerKey.png",
    textareaStyle: {
      width: 100,
      paddingTop: 2,
      resize: "none",
    },
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Look at the phrases below. Which ones
        are included in the essay?
        `,
        color: "black",
        left: -50,
        width: "28cm",
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      fontSize: 20,
      width: 182,
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["<b>Introducing one side of the argument</b>"],
        styleElement: { color: "#2483c5", marginRight: "30px" },
      },
      {
        src: ["<b>Introducing the other side of the argument</b>"],
        styleElement: { color: "#2483c5", marginRight: "30px" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `There_are_many_internet_forums_and_chat_rooms_for_young_people_where_they_can_discuss_their_personal_problems_with_their_peers. 
          There_are_both_advantages_and_disadvantages_to_looking_for_solutions_in_this_way.`,
          `The_first_advantage_of_the_internet_is_the_huge_amount_of_information_on_it. 
          You_can_find_an_answer_to_any_question_you_have,_provided_that_you_search_correctly. 
          And_no_one_can_deny_that_chat_rooms_can_be_very_supportive_and_can_help_you_to_feel_better. 
          Even_if_you_feel_you_are_the_only_person_with_your_particular_problem, you_will_soon_realise_that_there_is_always_someone_else_who_feels_the_same_way.`,
          `So_are_there_any_disadvantages_to_using_the_internet_in_this_way? Although_it_is_true_that_there_is_a_lot_of_information_online, we_should_also_remember_that_it_is_not_always_accurate. 
          Moreover,_teenagers_in_chat_rooms_are_not_necessarily_the_best_judges_of_a_situation. 
          Even_if_you_get_advice,_it_may_not_be_the_most_helpful_advice_you_could_receive. 
          Finally,_the_internet_is_impersonal. 
          People_who_you_chat_to_online_don’t_know_you_well, so_they_won’t_understand_your_particular_situation.`,
          `In_conclusion,_even_though_the_internet_can_be_helpful_when_you_have_a_problem, I_don’t_think_it_is_a_substitute_for_speaking_to_the_people_in_your_life_who_know_and_care_about_you. 
          Young_people_shouldn’t_forget_to_talk_to_the_people_that_they_have_a_real_relationship_with.`,
        ],
        answers: ["1-0", "1-48", "2-2", "2-4", "2-28"],
        initialValue: [],
      },
      Layout: `

      <div style='border-left:5px solid rgb(36,131,197);padding-left:15px; width:90%'>
            <hintbox id=0></hintbox>
            The first / second advantage …<br>
            It can be argued that …<br>
            No one can deny that …<br>
            On the one hand, …<br>
            It is also true that …<br>
            Furthermore, … / Moreover, …
            <hintbox id=1></hintbox>
            However, …<br>
            On the other hand, …<br>
            Although it is true that … , we should also remember<br>
            that …<br>
            Having said that, … / That said, …<br>
            And yet, …
        </div>
      <div style='margin-top:10px;background:rgb(243,240,221);padding:10px 20px; border-radius:5px; box-shadow:2px 3px 5px 5px gray; width:25cm'>
        <input id=0 type=Circle />
        <div style='margin-top:15px'><input id=1 type=Circle /> </div>
        <div style='margin-top:15px'><input id=2 type=Circle /></div>
        <div style='margin-top:15px'><input id=3 type=Circle /></div>
      </div>
      `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E6",
    exerciseKey: "img/FriendsPlus/Page33/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#2483c5' > Learn this!</span>. Then find examples of the
        rules in the essay.`,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `There_are_many_internet_forums_and_chat_rooms_for_young_people_where_they_can_discuss_their_personal_problems_with_their_peers. 
          There_are_both_advantages_and_disadvantages_to_looking_for_solutions_in_this_way.`,
          `The_first_advantage_of_the_internet_is_the_huge_amount_of_information_on_it. 
          You_can_find_an_answer_to_any_question_you_have,_provided_that_you_search_correctly. 
          And_no_one_can_deny_that_chat_rooms_can_be_very_supportive_and_can_help_you_to_feel_better. 
          Even_if_you_feel_you_are_the_only_person_with_your_particular_problem,_you_will_soon_realise_that_there_is_always_someone_else_who_feels_the_same_way.`,
          `So_are_there_any_disadvantages_to_using_the_internet_in_this_way? Although_it_is_true_that_there_is_a_lot_of_information_online, we_should_also_remember_that_it_is_not_always_accurate. 
          Moreover,_teenagers_in_chat_rooms_are_not_necessarily_the_best_judges_of_a_situation. 
          Even_if_you_get_advice,_it_may_not_be_the_most_helpful_advice_you_could_receive. 
          Finally,_the_internet_is_impersonal. 
          People_who_you_chat_to_online_don’t_know_you_well, so_they_won’t_understand_your_particular_situation.`,
          `In_conclusion,_even_though_the_internet_can_be_helpful_when_you_have_a_problem,_I_don’t_think_it_is_a_substitute_for_speaking_to_the_people_in_your_life_who_know_and_care_about_you. 
          Young_people_shouldn’t_forget_to_talk_to_the_people_that_they_have_a_real_relationship_with.`,
        ],
        answers: ["1-72", "2-52", "3-0"],
        initialValue: [],
      },
      Layout: `

      <img src="img/FriendsPlus/Page33/E6/1.jpg" style='height:9.5cm'/><br>
      
      <div style='margin-top:10px;background:rgb(243,240,221);padding:10px 20px; border-radius:5px; box-shadow:2px 3px 5px 5px gray; width:25cm'>
        <input id=0 type=Circle />
        <div style='margin-top:15px'><input id=1 type=Circle /> </div>
        <div style='margin-top:15px'><input id=2 type=Circle /></div>
        <div style='margin-top:15px'><input id=3 type=Circle /></div>
      </div>
      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E7",
    exerciseKey: "img/FriendsPlus/Page33/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Read the task below. Make notes of some advantages
        and disadvantages.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P33-E8",
    exerciseKey: "img/FriendsPlus/Page33/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page33/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
