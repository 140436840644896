import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import { CgMagnet } from "react-icons/cg";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E1",
    exerciseKey: "img/FriendsPlus/Page14/Key/E1answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Read the text. Which is the best piece of advice, in your
        opinion? Do you disagree with any of the advice?
        
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page14/E1/1.jpg" style='margin-left:100px;height:21cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E2",
    exerciseKey: "img/FriendsPlus/Page14/Key/E2answerKey.png",
    // checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 300, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b17'>Learn this!</span>  box. Which negative adverbials can
        you find in exercise 1?`,
        color: "black",
        left: -20,
      },
    ],
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          `Wouldn’t it be great if parents and their teenagers
          got on all the time? Post your best advice for parents
          below.`,
          `Be calm and reasonable and treat me like an adult. Under_no_circumstances should you speak to me as if I’m still a
          small child.`,
          `Not_only should you avoid speaking down to me, you should
          also notice when I do things that are good! `,
          `Surely, part of being a teenager is being allowed to make
          mistakes. So it’s not a good idea to tell me off every time I
          get something wrong!`,
          `If you’re worried about me, just talk to me – calmly! On_no_account should you get angry. I’m not likely to want to
          confide in you if you’re shouting!`,
          `Rarely do teens and their parents agree all the time,
          but you can still get on well even if you don’t always
          see eye to eye. The best advice to parents? Try not to
          argue! You may regret not being patient!`,
        ],
        answers: ["1-20", "2-0", "2-40", "5-0", "4-22"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page14/E2/1.jpg" style='margin-left:-30px;height:10cm'/>
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page14/E2/2.jpg" style='height:25cm'/>
          <div style='font-weight:bold;font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:84px;left:76px'>
              <input id=0 type=Circle />
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:180px;left:76px'>
              <input id=1 type=Circle /> <b style='color:rgb(248,144,32)'>THAI AN, HA NOI</b>
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:284px;left:76px'>
              <input id=2 type=Circle /> <b style='color:rgb(42,106,180)'>PETER, DEVON</b>
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:359px;left:76px'>
              <input id=3 type=Circle /> <b style='color:rgb(154,44,69)'>ARI, SEOUL</b>
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:465px;left:76px'>
              <input id=4 type=Circle /> <b style='color:rgb(243,101,35)'>TOM, LONDON</b>
          </div>
          <div style='font-weight:bold;font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:568px;left:76px'>
              <input id=5 type=Circle /> 
          </div>
      </div>
      `,
    },
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E3",
    exerciseKey: "img/FriendsPlus/Page14/Key/E3answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 250, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Write the sentences with the correct form of the words
        in brackets.`,
        color: "black",
        left: 50,
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;No sooner #(we / eat) dinner than he got up and left.<br>
            <b>2</b>&ensp;Only once #(I / break) a promise in my whole life.<br>
            <b>3</b>&ensp;At no time #(anyone / explain) what had happened.<br>
            <b>4</b>&ensp;Never before #(she / meet) a more disagreeable man.<br>
            <b>5</b>&ensp;In no way #(he / trust) you in the future after all your lies.<br>
        </div> 
        `,
        answer: [
          "had we eaten",
          "have I broken",
          "did anyone explain",
          "had she met / has she met",
          "will he trust",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E4",
    exerciseKey: "img/FriendsPlus/Page14/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the sentences using inversion.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Never have we faced such a serious problem"],
        styleElement: { color: "#3c8fa8", marginRight: 40 },
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: {
      width: 600,
      background: "none",
      paddingTop: 10,
    },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
              <b>1</b>&ensp;We’ve never faced such a serious problem.
              <hintbox id=0></hintbox>
              <b>2</b>&ensp;We couldn’t find a coffee shop open anywhere.<br>#<br>
              <b>3</b>&ensp;I had hardly woken up when my mobile rang.<br>#<br>
              <b>4</b>&ensp;They weren’t aware of the danger at any time.<br>#<br>
              <b>5</b>&ensp;There won’t be any more festivals until next year.<br>#<br>
        </div>
      
      
        
        `,
        answer: [
          "Nowhere could we find a coffee shop open.",
          "Hardly had I woken up when my mobile rang.",
          "At no time were they aware of the danger.",
          "Not until next year will there be any more festivals.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E5",
    exerciseKey: "img/FriendsPlus/Page14/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      // resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#f88b17'>Learn this!</span> box. Find examples of negative
        questions and negative infinitives and -ing forms in
        exercise 1. `,
        color: "black",
        left: -50,
        width: "30cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    stylesTextInput: {
      // borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 300,
      paddingTop: 10,
    },
    // hideBtnFooter: true,
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 10,
        listWords: [
          `Wouldn’t_it_be_great_if_parents_and_their_teenagers_got_on_all_the_time? Post your best advice for parents
          below.`,
          `Be_calm_and_reasonable_and_treat_me_like_an_adult. Under_no_circumstances_should_you_speak_to_me_as_if_I’m_still_a_small_child.`,
          `Not_only_should_you_avoid_speaking_down_to_me, you_should_also_notice_when_I_do_things_that_are_good|! `,
          `Surely, part_of_being_a_teenager_is_being_allowed_to_make_mistakes. So_it’s_not_a_good_idea_to_tell_me_off_every_time_I_get_something_wrong!`,
          `If_you’re_worried_about_me,_just_talk_to_me_–_calmly! On_no_account_should_you_get_angry. I’m_not_likely_to_want_to_confide_in_you_if_you’re_shouting!`,
          `Rarely_do_teens_and_their_parents_agree_all_the_time,
          but_you_can_still_get_on_well_even_if_you_don’t_always_see_eye_to_eye. The_best_advice_to_parents? Try_not_to_argue! You_may_regret_not_being_patient!`,
        ],
        answers: ["5-26", "5-30", "0-0", "5-28"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page14/E2/1.jpg" style='margin-left:-30px;height:10cm'/>
      <div style='position:relative'>
        <img src="img/FriendsPlus/Page14/E2/2.jpg" style='height:25cm'/>
          <div style='font-weight:bold;font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:84px;left:76px'>
              <input id=0 type=Circle />
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:180px;left:76px'>
              <input id=1 type=Circle /> <b style='color:rgb(248,144,32)'>THAI AN, HA NOI</b>
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:284px;left:76px'>
              <input id=2 type=Circle /> <b style='color:rgb(42,106,180)'>PETER, DEVON</b>
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:359px;left:76px'>
              <input id=3 type=Circle /> <b style='color:rgb(154,44,69)'>ARI, SEOUL</b>
          </div>
          <div style='font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:465px;left:76px'>
              <input id=4 type=Circle /> <b style='color:rgb(243,101,35)'>TOM, LONDON</b>
          </div>
          <div style='font-weight:bold;font-size: 22px;line-height: 30px;width: 164mm;position:absolute;top:568px;left:76px'>
              <input id=5 type=Circle /> 
          </div>
      </div>
      `,
    },
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E6",
    exerciseKey: "img/FriendsPlus/Page14/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    stylesTextInput: { width: 600, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "continue",
          "forget",
          "go on",
          "like",
          "prefer",
          "remember",
          "start",
          "stop",
          "try",
        ],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Write the words in the correct order to make sentences.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=''>
          <b>1</b>&ensp;to / not / I’ll / late / tomorrow / be / try<br>#<br>
          <b>2</b>&ensp;be / the / shouldn’t / test / for / Aidan / studying / ?<br>#<br>
          <b>3</b>&ensp;honest / not / with / I / being / him / resent / me<br>#<br>
          <b>4</b>&ensp;not / upset / was / Kat / invited / be / to<br>#<br>
          <b>5</b>&ensp;about / should / you / argue / try / to / not / it<br>#<br>
        </div>
        
        
        `,
        answer: [
          "I'll try not to be late tomorrow.",
          "Shouldn't Aidan be studying for the test?",
          "I resent him not being honest with me.",
          "Kat was upset not to be invited.",
          "You should try not to argue about it. ",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E7",
    exerciseKey: "img/FriendsPlus/Page14/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:#91268e'>USE OF ENGLISH</span> Rewrite the sentences beginning with
          the words in brackets. Do not change the meaning. `,
        color: "black",
        width: "27cm",
        left: 50,
      },
    ],
    stylesTextInput: { width: 700 },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:48px'>
          <b>1</b>&ensp;He doesn’t often go out during the week. (Rarely)<br>#<br>
          <b>2</b>&ensp;He wasn’t to blame. (In no way)<br>#<br>
          <b>3</b>&ensp;Don’t worry about the future. (Try)<br>#<br>
          <b>4</b>&ensp;Luckily she didn’t hurt herself badly. (She was)<br>#<br>
          <b>5</b>&ensp;They won’t decide until they know the facts. (Only when)<br>#<br>
          <b>6</b>&ensp;I’m sure we’ve been here before. (Haven’t)<br>#<br>
        </div>
        `,
        answer: [
          "Rarely does he go out during the week.",
          "In no way was he to blame.",
          "Try not to worry about the future.",
          "She was lucky not to hurt herself badly.",
          "Only when they know the facts will they decide.",
          "Haven't we been here before?",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P14-E8",
    exerciseKey: "img/FriendsPlus/Page14/Key/E8answerKey.png",
    component: T6,
    recorder: true,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Think of different ways to
        complete the sentences below. Compare your sentences
        with another pair’s. `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Under no circumstances should …<br>
          <b>2</b>&ensp;At no time have I ever …<br>
          <b>3</b>&ensp;Only once have I …<br>
          <b>4</b>&ensp;It’s so important not to …<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
