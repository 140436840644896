import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E1",
    exerciseKey: "img/FriendsPlus/Page77/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;What was your last dream or nightmare about?<br>
          <b>2</b>&ensp;Can our dreams help us in our waking lives?<br>
        </div>

        
        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E2",
    exerciseKey: "img/FriendsPlus/Page77/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the text. How could ‘lucid dreaming’ help students?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { width: 650, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        #<br>
        <img src="img/FriendsPlus/Page77/E2/1.jpg" style='margin-top:10px;height:20cm'/><br>
        
        
        `,
        // InputRong: true,
        answer: ["It could help students by improving a variety of skills."],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Put the highlighted compound adjectives in the text into
        the correct columns.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["young – age", "self-centred – opinion"],
        styleElement: { color: "#4dc4c4", marginRight: "100px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 230, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    character: ",",
    component: T6,
    questions: [
      {
        title: `
          <div>
            <table style="width: 50%; border-collapse: collapse;">
              <tr>
                <th style="width:30%;background-color: rgb(146,39,143); border-left: 2px solid rgb(146,39,143); color:white; padding: 8px; text-align: center;">adjective + noun + -ed </th>
                <th style="width:30%;background-color: rgb(146,39,143); border: 2px solid white; color:white; padding: 8px; text-align: center;">noun + -ing form</th>
                <th style="width:30%;background-color: rgb(146,39,143); border-right: 2px solid rgb(146,39,143); color:white; padding: 8px; text-align: center;">adverb + past participle</th>
              </tr>
              <tr>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>1</b>#</td>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>4</b>#</td>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>8</b>#</td>
              </tr>
              <tr>
                <td style="border: 2px solid rgb(146,39,143); padding: 8px;text-align:center"><b>2</b>#</td>
                <td style="border: 2px solid rgb(146,39,143); padding: 8px;text-align:center"><b>5</b>#</td>
                <td style="border: 2px solid rgb(146,39,143); padding: 8px;text-align:center"><b>9</b>#</td>
              </tr>
              <tr>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>3</b>#</td>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>6</b>#</td>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>10</b>#</td>
              </tr>
              <tr>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"> </td>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><b>7</b>#</td>
                <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"> </td>
              </tr>
              
            </table>
          </div>  
          <img src="img/FriendsPlus/Page77/E2/1.jpg" style='margin-top:10px;height:20cm'/><br>
        
        `,
        // InputRong: true,
        answer: [
          "broad-based",
          "breathtaking",
          "highly respected",
          "half-hearted",
          "thought-provoking",
          "far-fetched",
          "bleary-eyed",
          "mind-blowing",
          "well-known",
          "record-breaking",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E4",
    exerciseKey: "img/FriendsPlus/Page77/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the adjectives in brackets
        in the correct order.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 300,
      textAlign: "center",
    },
    component: Circle_Write,

    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { textAlign: "center", width: 240, paddingTop: 2 },
        answers: [
          "good-looking",
          "straighforward",
          "second-hand",
          "single-minded",
          "high-spirited",
          "light-hearted",
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 2,
        listWords: [
          `Would you describe yourself as single-minded|?`,
          `Do you ever buy second-hand goods|?`,
          `When did you last watch a light-hearted film|?`,
          `Which school subjects do you find most straightforward|?`,
          `Do you think it’s better to be good-looking or talented|?`,
          `Were you a high-spirited child|?`,
        ],
        answers: ["0-10", "1-8", "2-12", "3-14", "4-14", "5-6"],
        initialValue: [],
      },
      Layout: `
      <div style='width:27cm;line-height:47px'>
        <b>1</b>&ensp;<input id=0 type=Circle /><br>
        <b>2</b>&ensp;<input id=1 type=Circle /><br>
        <b>3</b>&ensp;<input id=2 type=Circle /><br>
        <b>4</b>&ensp;<input id=3 type=Circle /><br>
        <b>5</b>&ensp;<input id=4 type=Circle /><br>
        <b>6</b>&ensp;<input id=5 type=Circle /><br>
      </div>
      <div style='display:flex;line-height:50px'>
        <div style='margin-right:150px'>
          <b>a</b>&ensp;attractive <input id=0 /><br>
          <b>b</b>&ensp;easy <input id=1 /><br>
          <b>c</b>&ensp;not new <input id=2 /><br>
        </div>
        <div>
          <b>d</b>&ensp;determined <input id=3 /><br>
          <b>e</b>&ensp;energetic <input id=4 /><br>
          <b>f</b>&ensp;not serious <input id=5 /><br>
        </div>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E5",
    exerciseKey: "img/FriendsPlus/Page77/Key/E5answerKey.png",
    character: ",",
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Read the <span style='color:#91268e'>Dictionary Strategy</span>. Which
        of the compound adjectives below are likely to have
        their own entry? Check your answers in a dictionary.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 750,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "densely populated",
          "English-speaking",
          "light-hearted",
          "much-needed",
          "old-fashioned",
          "record-breaking",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        
        <div style='display:flex'>
          <b>Answer:</b>#
        </div>
        `,
        answer: [
          "English-speaking, light-hearted, old-fashioned, record-breaking",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E6",
    exerciseKey: "img/FriendsPlus/Page77/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 150 },
    titleQuestion: [
      {
        num: "6",
        title: `Complete the compound adjectives with the words
        below. Use a dictionary to help you.
        `,
        color: "black",
        width: "28cm",
        left: 40,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["minded", "populated", "reaching", "saving", "watering"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    // character: ",",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:47px'>
          <b>1</b>&ensp;Sarah often forgets things because she’s absent-# .<br>
          <b>2</b>&ensp;The microwave is one of the best time-# devices ever invented.<br>
          <b>3</b>&ensp;The cakes at the baker’s were so mouth-# that I wanted to eat them all!<br>
          <b>4</b>&ensp;That decision could have far-# consequences for us.<br>
          <b>5</b>&ensp;Singapore is one of the most densely # places in the world.<br>
        </div>
        `,
        answer: ["minded", "saving", "watering", "reaching", "populated"],
      },
    ],
  },
  7: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E7",
    exerciseKey: "img/FriendsPlus/Page77/Key/E7answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title: `Work in pairs. Discuss the questions in
        exercise 4. Give reasons and examples.
        `,
        color: "black",
        left: 40,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
      <div style='width:27cm;line-height:47px'>
        <b>1</b>&ensp;Would you describe yourself as single-minded?<br>
        <b>2</b>&ensp;Do you ever buy second-hand goods?<br>
        <b>3</b>&ensp;When did you last watch a light-hearted film?<br>
        <b>4</b>&ensp;Which school subjects do you find most straightforward?<br>
        <b>5</b>&ensp;Do you think it’s better to be good-looking or talented?<br>
        <b>6</b>&ensp;Were you a high-spirited child?<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 6",
    id: "SB12-2024-U6-P77-E8",
    exerciseKey: "img/FriendsPlus/Page77/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Using adjectives and linking
        verbs, tell your partner about a famous person you would
        like to meet.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <ul>
          <li>Give some information about them and what they do.</li>
          <li>Say why you’d like to meet them and how it would make you feel. </li>
        </ul>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
