import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import { CgMagnet } from "react-icons/cg";
const json = {
  1: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E1",
    exerciseKey: "img/FriendsPlus/Page11/Key/E1answerKey.png",
    recorder: "true",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Describe the photo. Have you ever been to a
        wedding? What was it like?
        
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page11/E1/1.jpg" style='margin-left:100px;height:7cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E2",
    exerciseKey: "img/FriendsPlus/Page11/Key/E2answerKey.png",
    component: T6,
    checkDuplicated: true,
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 180, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["He’s always talking about his life"],
        // styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with the correct past simple,
        past continuous, or past perfect form of the verbs in
        brackets.`,
        color: "black",
        left: 50,
      },
    ],
    widthModalKey: 1300,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:15px'>My brother #(learn) to drive while he
            #(study) at university.</div>        
        </div>
        <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:15px'>It #(snow) all the time that we
            #(drive) up to Scotland.</div>        
        </div>
        <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:15px'>I #(grow) up in London, but when I was
            sixteen, we #(move) to Edinburgh.</div>        
        </div>
        <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:15px'>My sister finally #(get) her first job a year
            after she #(leave) university!</div>        
        </div>
        <div style='display:flex'>
            <b>5</b>
            <div style='margin-left:15px'>My mum #(emigrate) to New York,
            and she #(start) a business while she #(live) there.</div>        
        </div>
        <div style='display:flex'>
            <b>6</b>
            <div style='margin-left:15px'>I #(meet) my best friend last night.<br>I #(not see) her for over a year!</div>        
        </div>
         

        
        `,
        answer: [
          "learned",
          "was studying",
          "was snowing",
          "were driving",
          "grew",
          "moved",
          "got",
          "had left",
          "emigrated",
          "started",
          "was living",
          "met",
          "hadn't seen|had not seen",
        ],
      },
    ],
  },
  3: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E3",
    exerciseKey: "img/FriendsPlus/Page11/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#f88b17'>Learn this!</span>  box. Then choose the correct
        answers to complete the sentences.`,
        color: "black",
        left: -50,
      },
    ],

    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "been_jogging / jogged",
          "marrying / married",
          "been_raining / rained",
          "been_buying / bought",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page11/E3/1.jpg" style='margin-left:100px;height:10cm'/>
      <div style='line-height:50px'>
          <div style='display:flex'>
                <b>1</b>
                <div style='margin-left:15px'>Mary’s face is red because she has <input id=0 type='Circle' /> for an hour.
                </div>        
            </div>
            <div style='display:flex'>
                <b>2</b>
                <div style='margin-left:15px'>My parents have been <input id=1 type='Circle' /> for 25 years.</div>        
            </div>
            <div style='display:flex'>
                <b>3</b>
                <div style='margin-left:15px'>It’s <input id=2 type='Circle' /> all morning and I don’t think
                it’ll ever stop.
                </div>        
            </div>
            <div style='display:flex'>
                <b>4</b>
                <div style='margin-left:15px'>We’ve <input id=3 type='Circle' /> a new house, and we
                move in next week!</div>        
            </div>
        </div>
      `,
    },
  },

  4: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E4",
    exerciseKey: "img/FriendsPlus/Page11/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the text with the verbs in brackets. Use the
        correct tense from the two <span style='color:#f88b17'>Learn this!</span> boxes.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    component: T6,

    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: {
      width: 250,
      background: "none",
      textAlign: "center",
      paddingTop: 10,
    },
    widthModalKey: 1200,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page11/E4/1.jpg" style='margin-left:100px;height:14cm'/>
        <div style='width:90%'>
          I <sup>1</sup>#just#(receive) an email from my cousin Carl.
          I <sup>2</sup>#(not see) him since he <sup>3</sup>#(get) married last summer. We all 4#(go) to
          the wedding. It <sup>5</sup>#(be) great fun.
          I <sup>6</sup>#(not go) to a wedding before. Anyway,
          he and Sally <br><sup>7</sup>#(be) married for about nine
          months now. They <br><sup>8</sup>#(live) in London since
          the marriage. According to Carl, Sally <br><sup>9</sup>#(work)
          really hard and her boss <sup>10</sup>#(offer) her a
          job in the Paris office. That’s great news. She’s going to
          accept it. She <sup>11</sup>#(learn) French while she <sup>12</sup>#(live) in France in the 1990s, so she’ll have
          no problem settling in there. 
        </div>
      
      
        
        `,
        answer: [
          "'ve|have",
          "received",
          "haven't seen|have not seen",
          "got",
          "went",
          "was",
          "hadn't gone|had not gone",
          "have been",
          "have been living",
          "has been working",
          "has offered",
          "learned",
          "was living",
        ],
      },
    ],
  },
  5: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E5",
    exerciseKey: "img/FriendsPlus/Page11/Key/E5answerKey.png",
    recorder: "true",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss the questions.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["accuse", "apologise", "ask", "insist", "remind", "think"],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
      textAlign: "center",
      width: 186,
      paddingTop: 10,
    },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:15px'>How many family events have you been to in the past
            few years? </div>        
        </div>
        <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:15px'>What kinds of events were they? What happened?</div>        
        </div>
        `,
        answer: ["be going to", "will", "be going to", "will", "will"],
      },
    ],
  },
  6: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E6",
    exerciseKey: "img/FriendsPlus/Page11/Key/E6answerKey.png",
    component: T6,
    checkDuplicated: true,
    textAlign: "center",
    stylesTextInput: { borderBottom: "none", width: 170, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "continue",
          "forget",
          "go on",
          "like",
          "prefer",
          "remember",
          "start",
          "stop",
          "try",
        ],
        styleElement: { color: "#92278f", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        width: "28cm",
        left: 0,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;justify-content:center'>
          <img src="img/FriendsPlus/Page11/E6/1.jpg" style='height:6cm'/>
        </div>
        
        
        `,
        InputRong: true,
        answer: [
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
          "continue|like|prefer|start|",
          "forget|go on|remember|stop|try",
        ],
      },
    ],
  },
  7: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E7",
    exerciseKey: "img/FriendsPlus/Page11/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title:
          "<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Ask and answer the following questions, using the proper intonation patterns.",
        color: "black",
        width: "27cm",
        left: -50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:48px'>
          Do your friends have any annoying habits?<br>
          What do you do if someone has habits that are annoying?<br>
          Do you often enjoy your school holiday with your friends?<br>
          What plans do you have for the next school holiday?<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You should not spend time reading them.|You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You should not complain about his dependence on social media.|You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Introduction",
    id: "SB12-2024-I-P11-E8",
    exerciseKey: "img/FriendsPlus/Page11/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Tell your partner about
        something you did recently. Use verbs from this lesson.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
