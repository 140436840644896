import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E1",
    exerciseKey: "img/FriendsPlus/Page28/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Do the quiz below in your notebook. Do you agree with
        what your score says?`,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page28/E1/1.jpg" style='margin-left:0px;height:26.5cm'/>
        </div>
        
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E2",
    exerciseKey: "img/FriendsPlus/Page28/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#f88b1f'>Learn this!</span> box. Then find examples for each
        rule (1–3) in the quiz in exercise 1.
        `,
        color: "black",
        left: 50,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>", "alarmed"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    textareaStyle: {
      resize: "none",
      width: 450,
      paddingTop: 1,
      marginBottom: -23,
    },
    character: ",",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page28/E2/1.jpg" style='margin-left:-50px;height:12.5cm'/>
           <div>
            <b>Answer</b>
            <div style='display:flex'>
              <b>1</b> <div><textarea id=0 rows=3></textarea></div>
            </div>
            <div style='display:flex'>
              <b>2</b> <div><textarea id=1 rows=3></textarea></div>
            </div>
            <div style='display:flex'>
              <b>3</b> <div><textarea id=2 rows=3></textarea></div>
            </div>
           </div>
        </div>
        <img src="img/FriendsPlus/Page28/E1/1.jpg" style='margin-left:0px;height:26.5cm'/>
        `,
        // InputRong: true,
        answer: [
          "stop to pick it up, try to find another seat, stop answering the phone, try being more assertive",
          "see someone drop, hear a shop assistant being rude",
          "taking a risk, ignoring bad behaviour, seeing bad behaviour",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E3",
    exerciseKey: "img/FriendsPlus/Page28/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct words to complete the sentences.
        If both are correct, explain the difference in meaning. 
        `,
        color: "black",
        left: 50,
        width: "",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    // textAlign: "center",
    stylesTextInput: { width: 750, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>", "alarmed"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    textareaStyle: {
      resize: "none",
      width: 450,
      paddingTop: 1,
      marginBottom: -23,
    },
    character: "/",
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;<b>Queue / Queuing</b> in shops really frustrates me.<br>
          <b>2</b>&ensp;Everyone saw me <b>argue / arguing</b> with the driver.<br>
          <b>3</b>&ensp;Please stop <b>talking / to talk</b> while I’m explaining the homework.<br>
          <b>4</b>&ensp;I’m sure she didn’t really mean <b>to be / being</b> rude.<br>
          <b>5</b>&ensp;Have you tried <b>taking / take</b> painkillers for your headache?<br>
          <b>6</b>&ensp;You must remember to <b>choose / choosing</b> a password!<br>
        </div>
        <b>Answer</b>
        <div style='line-height:50px'>
          <b>1</b>#<br>
          <b>2</b>#<br>
          <b>3</b>#<br>
          <b>4</b>#<br>
          <b>5</b>#<br>
          <b>6</b>#<br>
        </div>

        `,
        // InputRong: true,
        answer: [
          "Queuing",
          "argue (for a completed action) / arguing (for an ongoing action)",
          "talking",
          "to be",
          "taking",
          "to choose (refers to future time) / choosing (refers to past time)",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E4",
    exerciseKey: "img/FriendsPlus/Page28/Key/E4answerKey.png",
    textareaStyle: {
      resize: "none",
      width: 450,
      paddingTop: 1,
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<span style='color:#91268e' >USE OF ENGLISH </span> Read the <span style='color:#f88b1f'>Learn this!</span> box. Then complete
        the second sentence so that it has the same meaning as
        the first. Use (1–5) words, including the word in brackets. 
        
        `,
        color: "black",
        width: "28.5cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Describing feelings</b>"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: { width: 250, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
            <div>
                <div style='display:flex'>
                      <b>1</b>
                    <div style='margin-left:15px'>
                      I’d prefer not to go out this evening. (rather)<br>
                      I # go out this evening.
                    </div>
                </div>
                <div style='display:flex'>
                      <b>2</b>
                    <div style='margin-left:15px'>
                      I suggest you ask him to keep his voice down. (why)<br>
                      # him to keep his voice down?
                    </div>
                </div>
                <div style='display:flex'>
                      <b>3</b>
                    <div style='margin-left:15px'>
                      It would be wise for you to check your change. (better)<br>
                      # check your change.
                    </div>
                </div>
                <div style='display:flex'>
                      <b>4</b>
                    <div style='margin-left:15px'>
                      # sit here or somewhere quieter?<br>
                    </div>
                </div>
            </div>
        </div>
        
        `,
        // InputRong: true,
        answer: [
          "would rather not",
          "Why not ask",
          "You'd better|You had better",
          "Would you sooner",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E5",
    exerciseKey: "img/FriendsPlus/Page28/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the correct form of the
        verbs below.
        `,
        color: "black",
        left: 50,
        width: "25cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    textAlign: "center",
    stylesTextInput: { width: 140, paddingTop: 10 },
    hintBox: [
      {
        src: ["enter", "hang up", "keep", "talk", "wait"],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='line-height:50px'>
            <b>1</b>&ensp;Using the website means # all my personal details again!<br>
            <b>2</b>&ensp;# in the library is strictly forbidden at all times.<br>
            <b>3</b>&ensp;I’d better # a note of all my passwords in a safe place.<br>
            <b>4</b>&ensp;Why not just # if they keep calling your number?<br>
            <b>5</b>&ensp;If you’d sooner not # for me, I’ll meet you in town later. 
        </div>
        `,
        // InputRong: true,
        answer: ["entering", "Talking", "keep", "hang up", "wait"],
      },
    ],
  },

  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E6",
    exerciseKey: "img/FriendsPlus/Page28/Key/E6answerKey.png",
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Tell each other about:`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 360, paddingTop: 10 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
              <b>1</b>&ensp;something that makes you angry (use -ing as the subject).<br>
              <b>2</b>&ensp;something you mustn’t forget to do this week.<br>
              <b>3</b>&ensp;a new activity you’d like to try doing.<br>
        </div>
        `,
        answer: [
          "is nowhere near as difficult as",
          "are much safer than",
          "nothing like as important as",
          "more popular than it",
          "older you get, the more",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P28-E8",
    exerciseKey: "img/FriendsPlus/Page28/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Discuss whether you agree or
        disagree with the statements in exercise 7. Give reasons.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
