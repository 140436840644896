import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E1",
    exerciseKey: "img/FriendsPlus/Page110/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `Check the meaning of the idioms below. Then complete
        the sentences in the correct form.
        `,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 400, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "everything but the kitchen sink",
          "have a whale of a time",
          "home from home",
          "in the middle of nowhere",
          "just what the doctor ordered",
          "make a nice change",
          "recharge one’s batteries",
          "a short break",
        ],
        styleElement: { color: "#91268e", marginRight: "40px" },
        width: "90%",
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },

    // hideBtnFooter: true,
    // character: /[/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width:25cm'>
            <b>1</b>&ensp;‘Did you have a good holiday with your friends?’ ‘It was brilliant, thanks. We #.’<br>
            <b>2</b>&ensp;Wow! That’s a lot of luggage! It looks like you’ve packed #!<br>
            <b>3</b>&ensp;I feel great. Our holiday was fantastic – good food, good weather. It was .#<br>
            <b>4</b>&ensp;We stayed in a great apartment that was really comfortable and had everything we needed. It was a real #.<br>
            <b>5</b>&ensp;I’ve booked a relaxing holiday in a spa hotel so that I # can before work starts again.<br>
            <b>6</b>&ensp;They’re renting a cottage #. The nearest village is 30 km away!<br>
            <b>7</b>&ensp;I’m not around at the end of the week. We’re taking # and going to Paris for a few days.<br>
            <b>8</b>&ensp;We usually go to France on holiday, but this year we’re going to Cyprus – it’ll #.<br>
        </div>
        `,
        answer: [
          "had a whale of a time",
          "everything but the kitchen sink",
          "just what the doctor ordered",
          "home from home",
          "recharge my batteries",
          "in the middle of nowhere",
          "a short break",
          "make a nice change",
        ],
      },
    ],
  },
  2: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E2",
    exerciseKey: "img/FriendsPlus/Page110/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Complete the letter with the
        correct form of the words below. There are two extra words.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "appreciate",
          "communicate",
          "express",
          "imagine",
          "impress",
          "organise",
          "produce",
          "promote",
          "suggest",
        ],
        width: "90%",
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page110/E2/1.jpg" style='height:6.5cm'/>
        <div style='width:25cm'>
            Dear Sir or Madam,<br>
            I am writing to <sup>1</sup># my dissatisfaction with my recent stay
            in your hotel.<br>
            When I arrived, my first <sup>2</sup># of your establishment was not
            a good one. The queues in reception highlighted the general lack of
            <sup>3</sup># in your establishment.<br>
            The events <sup>4</sup># was late for most of the excursions and
            the sightseeing programme wasn’t particularly <sup>5</sup># – we
            didn’t even leave the town!<br>
            I complained to the receptionist, but couldn’t understand his explanations
            as he seemed to lack basic <sup>6</sup># skills.<br>
            My <sup>7</sup># would be that in future, you, the manager, are
            available to deal with problems as they arise.
            Yours faithfully,
        </div>
        `,
        answer: [
          "express",
          "impression",
          "organisation",
          "promoter",
          "imaginative",
          "communication",
          "suggestion",
        ],
      },
    ],
  },
  3: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E3",
    exerciseKey: "img/FriendsPlus/Page110/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `The pairs of adjectives below have similar meanings, but one
        has a positive and the other a negative association. Copy the
        table and write the adjectives in the correct column.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["clean", "cold", "scary", "small", "starving", "terrible"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    character: "/",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: "25cm",
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `

        <hintbox id=0></hintbox>
        <div>
            <b>1</b>&ensp;My room is always very #. I tidy it every day.<br>
            <b>2</b>&ensp;I want to go home. This party is really #!<br>
            <b>3</b>&ensp;I need some lunch now because I’m absolutely #!<br>
            <b>4</b>&ensp;The food was good, but the portions were rather #.<br>
            <b>5</b>&ensp;I don’t want a lift with Anna – her driving’s pretty #.<br>
            <b>6</b>&ensp;It was slightly too # to be sitting outside in March.<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "clean",
          "terrible",
          "starving",
          "small",
          "scary / terrible",
          "cold",
        ],
      },
    ],
  },
  4: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E4",
    exerciseKey: "img/FriendsPlus/Page110/Key/E4answerKey.png",

    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "1",
        title: `The pairs of adjectives below have similar meanings, but one
        has a positive and the other a negative association. Copy the
        table and write the adjectives in the correct column.`,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrogant / self-assured",
          "calculating / shrewd",
          "courageous / foolhardy",
          "pushy / enthusiastic",
          "reserved / antisocial",
          "stingy / thrifty",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "90%",
      },
    ],
    stylesTextInput: { textAlign: "center", width: 240, paddingTop: 10 },
    character: ",",
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 400,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
  
          <hintbox id=0></hintbox>
          <table style="width: 50%; border-collapse: collapse;border: 2px solid rgb(146,39,143);">
          <tr>
            <th style="width:20%;color:white; background-color: rgb(146,39,143); border-right: 2px solid white; padding: 8px; text-align: center;">Starting a relationship</th>
            <th style="width:20%;color:white; background-color: rgb(146,39,143);  padding: 8px; text-align: center;">Finishing a relationship</th>
          </tr>
          <tr>
            <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><textarea id=0 rows=4></textarea></td>
            <td style="border: 2px solid rgb(146,39,143);text-align:center; padding: 8px;"><textarea id=1 rows=4></textarea></td>
          </tr>
        </table>
          `,
        // InputRong: true,
        answer: [
          "self-assured, shrewd, courageous, enthusiastic, reserved, thrifty",
          "arrogant, calculating, foolhardy, pushy, antisocial, stingy",
        ],
      },
    ],
  },
  5: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E5",
    exerciseKey: "img/FriendsPlus/Page110/Key/E5answerKey.png",
    textareaStyle: {
      width: 450,
      paddingTop: 2,
      resize: "none",
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Complete the sentences with one adjective from each pair
        in exercise 1.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 170,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrogant / self-assured",
          "calculating / shrewd",
          "courageous / foolhardy",
          "pushy / enthusiastic",
          "reserved / antisocial",
          "stingy / thrifty",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
        width: "90%",
      },
    ],
    // character: /[,/|]/,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>

        <div style='width:27cm'>
            <b>1</b>&ensp;Don’t expect a birthday present from her – she’s well-off, but so # with her money.<br>
            <b>2</b>&ensp;The new sales assistant is wonderful. She’s so genuinely # about the products that everybody buys them.<br>
            <b>3</b>&ensp;I know you think you’re being brave, but actually I think climbing that wall is just #.<br>
            <b>4</b>&ensp;Leo’s a very # judge of character. He knew immediately that the journalist wasn’t being honest.<br>
            <b>5</b>&ensp;Come over and chat to the rest of the group. Don’t be so #!<br>
            
        </div>
        
        `,
        answer: ["Stingy", "Enthusiastic", "Foolhardy", "Shrewd", "Reserved"],
      },
    ],
  },
  6: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E6",
    exerciseKey: "img/FriendsPlus/Page110/Key/E6answerKey.png",
    // checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 140 },
    titleQuestion: [
      {
        num: "3",
        title: `Match the American words in A with the British words in B.`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>A</b>",
          "bathing suit",
          "pants",
          "sneakers",
          "sweater",
          "undershirt",
          "vest",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "jumper",
          "swimming costume",
          "trainers",
          "trousers",
          "vest",
          "waistcoat",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>A</b>",
          "eraser",
          "hot lunch",
          "math",
          "recess",
          "teachers’ lounge",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>B</b>",
          "break time",
          "maths",
          "rubber",
          "school dinner",
          "staff room",
        ],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>A</b>", "cupcake", "dessert", "eggplant", "jelly", "takeout"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: ["<b>B</b>", "aubergine", "fairy cake", "jam pudding", "takeaway"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    character: /[,-]/,
    // characterCoupleWork: "",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Clothes
        <hintbox id=0></hintbox>
        <hintbox id=1></hintbox>
        <textarea id=0 rows=3></textarea>
        <b>2</b>&ensp;School
        <hintbox id=2></hintbox>
        <hintbox id=3></hintbox>
        <textarea id=1 rows=3></textarea>
        <b>3</b>&ensp;Food
        <hintbox id=4></hintbox>
        <hintbox id=5></hintbox>
        <textarea id=2 rows=3></textarea>
        
        
          `,
        answer: [
          "bathing suit - swimming costume, pants - trousers, sneakers - trainers, sweater - jumper, undershirt - vest, vest - waistcoat",
          "eraser - rubber, hot lunch - school dinner, math - maths, recess - break time, teachers' lounge - staff room",
          "cupcake - fairy cake, dessert - pudding, eggplant - aubergine, jelly - jam, takeout - takeaway",
        ],
      },
    ],
  },
  7: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E7",
    exerciseKey: "img/FriendsPlus/Page110/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Add an example of your own for
        each type of adjective formation.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 330, paddingTop: 10 },
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
            <img src="img/FriendsPlus/Page110/E7/1.jpg" style='height:11cm'/><br>
            <div style='margin-left:10px'>
                <b>-ful:</b> #<br>
                <b>-ive:</b> #<br>
                <b>-al:</b> #<br>
                <b>-able:</b> #<br>
                <b>-ous:</b> #<br>
                <b>-y:</b> #<br>
                <b>-less:</b> #<br>
            </div>
        </div>
        `,
        answer: [
          "truthful, useful",
          "active, creative",
          "magical, fatal",
          "breakable, uncontrollable",
          "dangerous, envious",
          "salty, muddy",
          "helpless, lifeless",
        ],
      },
    ],
  },
  8: {
    unit: "Vocabulary Builder",
    id: "SB12-2024-VB-P110-E8",
    exerciseKey: "img/FriendsPlus/Page110/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your essay (180–200 words) using your notes from
        exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page110/E7/1.jpg" style='height:5cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
