import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E1",
    exerciseKey: "img/FriendsPlus/Page23/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> `,

        color: "black",
        width: "28cm",
        left: 70,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <b>Read the text. Match sentences (A–F) with gaps (1–5).
      There is one extra sentence.</b><br>
      <b style='font-size:30px'>Identical twins</b>
      <div style='width:90%'>
        Identical twins are born when an egg divides into two,
        resulting in the birth of two children with identical genes.
        <sup>1</sup># Some believe, however, that there is also a close
        connection between twins in the way they think, not just in
        the way they look. Twins finish each other’s sentences, and
        share the same tastes in clothes or music. Some have argued
        that identical twins have mysterious, even psychic, powers.
        Although many identical twins claim to know what the
        other is thinking, there is no evidence to suggest that this
        is because of special powers. <sup>2</sup># Most of us have met
        up with a brother or sister, or close friend, and immediately
        asked, ‘What’s wrong?’ because we have unconsciously
        recognised, in their facial expression, that they are upset
        in some way. Signs we would miss in a stranger’s behaviour
        are instantly obvious in the way those close to us act. It is
        hardly surprising that twins notice the way their brother
        or sister’s facial expressions or body language gives away
        how they feel. Indeed, the closeness of their bond is likely
        to improve this ability over the years.
        The media is full of stories in which a twin senses her
        brother or sister to be in trouble. <sup>3</sup># Damien Croft
        developed a bad headache one evening and, concerned,
        felt the need to phone his brother to check if everything
        was OK. When there was no reply, he drove to his brother’s
        home, only to find him unconscious, having had an
        epileptic fit and fallen from a ladder while decorating. On
        the face of it, his sixth sense had saved the day. <sup>4</sup>#
        Damien knew his brother was decorating, and knew that
        he was prone to epileptic fits. Subconsciously concerned,
        he had just imagined the worst-case scenario. <sup>5</sup>#
      </div>
        <div style='width:90%;margin-top:15px'>
            <b>A</b>&ensp;In a recent, widely-reported case, for example, a young
            man survived a horrific fall thanks to a feeling his twin
            is supposed to have had.<br>
            <b>B</b>&ensp;We all know such twins are often very similar in
            appearance.<br>
            <b>C</b>&ensp;The fact that something awful had happened was
            only a coincidence.<br>
            <b>D</b>&ensp;In contrast, there are many other stories which can be
            more easily explained.<br>
            <b>E</b>&ensp;In truth, of course, the events might also be explained
            in more ordinary terms.<br>
            <b>F</b>&ensp;A more believable explanation is that, like any pair
            of people who have grown up together, they are just
            good at understanding the thought processes behind
            what their twin does.<br>
        </div>
        
        `,
        answer: ["B", "F", "A", "E", "C"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E2",
    exerciseKey: "img/FriendsPlus/Page23/Key/E2answerKey.png",
    audio: "Audios/1.14.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='1.14' colorimg=#9bc5d6  color='white'></headphone> You will hear four speakers talking about how
        they met their best friends. Are the sentences true or false?
        Write T or F. You will hear the recording twice. 
        `,
        color: "black",
        left: 50,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:46px'>
          <b>1</b>&ensp;Speaker 1 didn’t speak to his friend the first time they met.#<br>
          <b>2</b>&ensp;The two became friends after the head teacher had spoken to them.#<br>
          <b>3</b>&ensp;Speaker 2 stopped going to drama group because she didn’t get the leading role in a play.#<br>
          <b>4</b>&ensp;She didn’t get to know her friend until she was invited to her party.#<br>
          <b>5</b>&ensp;Speaker 3 was the only child who wasn’t wearing a costume when he first saw his friend.#<br>
          <b>6</b>&ensp;The two have been friends since their parents got to know each other.#<br>
          <b>7</b>&ensp;Speaker 4 did her friend a favour the first time they sat together.#<br>
          <b>8</b>&ensp;The girl wasn’t angry that she didn’t get her pencil back.#<br>
        </div>
        
        `,
        answer: ["T", "F", "F", "T", "F", "T", "F", "T"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E3",
    exerciseKey: "img/FriendsPlus/Page23/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        Work in pairs. Compare the photos. Then give your opinion
about the importance of sharing hobbies with others. Say
whether you think there are differences between sharing
hobbies with family and with friends.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page23/E3/1.jpg" style='height:10cm'/>
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E4",
    exerciseKey: "img/FriendsPlus/Page23/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 5 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        It is often said that teens and their parents rarely get
on well. Write an essay (180–200 words) to give your
opinion on this view and suggest advice to help make the
relationship better.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E5",
    exerciseKey: "img/FriendsPlus/Page23/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E6",
    exerciseKey: "img/FriendsPlus/Page23/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page23/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E7",
    exerciseKey: "img/FriendsPlus/Page23/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P23-E8",
    exerciseKey: "img/FriendsPlus/Page23/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page23/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
