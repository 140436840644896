import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E1",
    exerciseKey: "img/FriendsPlus/Page56/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Ask two questions each. Make a
        note of your partner’s replies and report back to the class.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <b style='font-size:28px'>Student A</b><br>
        <b>1</b>&ensp;When did you last go on a long journey? What was it like?<br>
        <b>2</b>&ensp;Do you prefer to travel alone or in a group? Why?<br>
        <b style='font-size:28px'>Student B</b><br>
        <b>1</b>&ensp;Which country would you like to visit and why?<br>
        <b>2</b>&ensp;Do you prefer adventure holidays or holidays where you just relax? Why?<br>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E2",
    exerciseKey: "img/FriendsPlus/Page56/Key/E2answerKey.png",
    recorder: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Which of the methods of transport in the
        photos have you tried? Which do you prefer and why?
        Tell your partner
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 50, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page56/E2/1.jpg" style='height:12cm'/>
          <img src="img/FriendsPlus/Page56/E2/4.jpg" style='margin-left:10px;height:12cm'/>
        </div>
        <img src="img/FriendsPlus/Page56/E2/3.jpg" style='height:12cm'/>
        `,
        InputRong: true,
        answer: ["C", "A", "", "B"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E3",
    exerciseKey: "img/FriendsPlus/Page56/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:rgb(8,144,73)' >Speaking Strategy</span> below. Make notes
        under the headings below for each method of transport.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page56/E2/2.jpg" style='height:3.5cm'/>
        <img src="img/FriendsPlus/Page56/E3/1.jpg" style='height:6.5cm'/>
        <textarea id=0 rows=4></textarea>
        `,
        answer: [
          "To travel the world on his motorbike.",
          "To travel from Europe, across Africa to the South Pole.",
          "To travel around eleven countries in 42 days with just the things he could carry in his pockets",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E4",
    exerciseKey: "img/FriendsPlus/Page56/Key/E4answerKey.png",
    audio: "Audios/2.06.mp3",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone name='2.06' colorimg=#b6d5b9  color='white'></headphone> Listen to a student doing the task. Answer the
        questions.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
        <b>1</b>&ensp;Which option does he select and what reasons does he give?<textarea id=0 rows=6 ></textarea>
        <b>2</b>&ensp;What are his reasons for rejecting the other options?<textarea id=1 rows=6 ></textarea>
        `,
        answer: [
          "He chooses A, the luxury coach tour. The reasons he gives are: it's cheap and all costs are included, he'll be able to see a lot from the coach windows, it's more convenient and less stressful to go on an organised coach tour.",
          "He rejects B, travelling by train, because he doesn't think it's as safe. He doesn't want to organise his own transport in strange places where he doesn't know the language. He doesn't like big train stations very much and finds them stressful. He rejects C, the cruise, because he doesn't think there'll be many young people on the ship. He gets sea-sick and it's the most expensive.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E5",
    exerciseKey: "img/FriendsPlus/Page56/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `How well does the student follow the the <span style='color:rgb(8,144,73)' >Speaking Strategy</span>
        Does he give enough reasons for each of his opinions?
        Are any of them similar to your ideas in exercise 3?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "boredom",
          "comfort",
          "convenience",
          "cost",
          "luggage",
          "safety",
          "time",
          "travel sickness",
        ],
        styleElement: { color: "#039246", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page56/E3/1.jpg" style='height:6.5cm'/>
        <textarea id=0 rows=5 ></texta>
        `,
        answer: [
          `The student follows the Speaking Strategy well. He gives three reasons for choosing the coach. He gives more than one reason for rejecting the train and more than one reason for rejecting the cruise. One of his reasons for rejecting the cruise is the opposite of one of his reasons for choosing the coach.`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E6",
    exerciseKey: "img/FriendsPlus/Page56/Key/E6answerKey.png",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Complete the phrases with the words below.`,
        color: "black",
        width: "28cm",
        left: 40,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: ",",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <hintbox id=0></hintbox>
        <div style='border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;I # I’ll choose … , mainly because …<br>
          <b>2</b>&ensp;I’m # for … and that’s because …<br>
          <b>3</b>&ensp;The # option would be the … because …<br>
          <b>4</b>&ensp;The reason # I’m (not) choosing the … is that …<br>
        </div>
        `,
        answer: ["think", "opting", "best", "why"],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E7",
    exerciseKey: "img/FriendsPlus/Page56/Key/E7answerKey.png",
    audio: "Audios/2.06.mp3",
    titleQuestion: [
      {
        num: "7",
        title: `<headphone name='2.06' colorimg=#b6d5b9  color='white'></headphone> Listen to the student again. Which key phrases
        from exercise 6 did you hear?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 660,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    component: T6,

    questions: [
      {
        title: `
       
        
        <div style='border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;I think I’ll choose … , mainly because …<br>
          <b>2</b>&ensp;I’m opting for … and that’s because …<br>
          <b>3</b>&ensp;The best option would be the … because …<br>
          <b>4</b>&ensp;The reason why I’m (not) choosing the … is that …<br>
        </div>
        <textarea id=0 rows=3></textarea>
        `,
        answer: [
          `I think I'll choose to travel by coach , mainly because …  
The best option would (again) be the coach.  
That's because you travel …`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E8",
    exerciseKey: "img/FriendsPlus/Page56/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Do the task in exercise 2 using
        the phrases in exercise 6.`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page56/E2/2.jpg" style='height:3.5cm'/>
        <div style='border-left:5px solid rgb(15,143,73);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;I think I’ll choose … , mainly because …<br>
          <b>2</b>&ensp;I’m opting for … and that’s because …<br>
          <b>3</b>&ensp;The best option would be the … because …<br>
          <b>4</b>&ensp;The reason why I’m (not) choosing the … is that …<br>
        </div>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P56-E9",
    exerciseKey: "img/FriendsPlus/Page56/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
