import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> <br> Read the extract from a story. Circle the correct answers
        (A–D).`,

        color: "black",
        width: "28cm",
        left: -20,
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
          `A <br> B <br> C <br> D`,
        ],
        answers: ["0-12", "1-0", "2-8", "3-8"],
        initialValue: [],
      },
      Layout: `

      
      <div style='margin-bottom:15px; width:25cm'>
        The Pennine Way is a 469-kilometre walking path which
        runs from the Peak District in Derbyshire to just inside the
        Scottish border. Other trails go further, but it is known
        as the most challenging. Some people see this trail as
        an opportunity to take a pleasant day’s hike on a small
        section of the route, but eighteen-year-old David Lemming
        saw it as a challenge. ‘I’d come across a great account of
        hiking the whole trail, written a few years ago, and I was
        determined to do it myself. I didn’t feel anxious – I was
        just excited to get going.
          <div style='margin-top:15px'>Things went perfectly for the first week. David was really
          enjoying the experience. Since many people use the trail,
          he was constantly meeting fellow hikers, so he never felt
          isolated. ‘But then it got really stormy,’ he says, ‘so a lot
          of people gave up. I could go for hours without seeing
          anyone at all. But as long as I had my mobile, I wasn’t
          really worried about anything bad happening.’ 
          </div>
          <div style='margin-top:15px'>Unfortunately, something bad did happen. During a
          particularly heavy rain storm, David slipped in the mud and
          fell about ten metres down the hill. ‘I realised I’d broken
          my arm immediately. Then I found that my mobile had
          fallen out of my pocket. And when I tried to get up to look
          for it, I couldn’t stand up at all. My leg was injured too.</div>
          <div style='margin-top:15px'>All he could do was to shout for help. ‘I yelled until my
          throat was sore,’ he says. ‘And nothing happened. But then
          I saw a head at the top of the hill – someone had heard
          me. A man came down, saw the state I was in and called
          for help. And really, that was the most amazing part of
          the whole experience. At the hospital, even the doctors
          were impressed at how much of the trail I’d covered. I felt
          really proud.’</div>
        </div>
        <b>1</b>&ensp;The Pennine Way is
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=0 type='Circle' />
          </div>
          <div>
            the longest trail in Britain.<br>
            located mainly in Scotland.<br>
            not popular within experienced walkers.<br>
            considered a difficult trail to walk.
          </div>
         </div>
        <b>2</b>&ensp;Where did David get the idea of hiking the trail?
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=1 type='Circle' />
          </div>
          <div>
            From reading about it<br>
            From his parents<br>
            From hearing an account of it<br>
            From being challenged by a friend
          </div>
         </div>
        <b>3</b>&ensp;How did David get help after his accident?
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=2 type='Circle' />
          </div>
          <div>
            He called for help on his mobile.<br>
            He spoke to a passer-by.<br>
            He called out until someone heard him.<br>
            He returned to the trail and found someone.
          </div>
         </div>
        <b>4</b>&ensp;What is the best title for the article?
         <div style='display:flex'>
          <div style='margin-left:15px;margin-right:10px'>
            <input id=3 type='Circle' />
          </div>
          <div>
            A Foolish Adventure<br>
            How a Mobile Saved a Boy’s Life<br>
            Near Tragedy, But No Regrets<br>
            Fear and Tragedy on the Pennine Way
          </div>
         </div>


      `,
    },
  },
  2: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E2",
    exerciseKey: "img/FriendsPlus/Page35/Key/E2answerKey.png",
    audio: "Audios/1.24.mp3",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='1.24' colorimg=#9bc5d6  color='white'></headphone> Listen to four dialogues and choose the correct
        answers (a–c).  
        `,
        color: "black",
        left: -20,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-0", "1-8", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      <b>1</b>&ensp;If Jack doesn’t go to Sam’s party, Jack will
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=0 type='Circle' />
        </div>
        <div>
          blow his top.<br>s
          tear his hair out.<br>s
          lose face
        </div>
       </div>
      <b>2</b>&ensp;When Kate hears about Ann’s results, she will
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=1 type='Circle' />
        </div>
        <div>
          be on edge.<br>
          be over the moon.<br>
          be green with envy.<br>
        </div>
       </div>
      <b>3</b>&ensp;The girl thinks that Meg _____________ over
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=2 type='Circle' />
        </div>
        <div>
          tears her hair out<br>
          is over the moon<br>
          blows her top<br>
        </div>
       </div>
      <b>4</b>&ensp;The boy says that Ewan was _____________ about the interview he was going to.        
       <div style='display:flex'>
        <div style='margin-left:15px;margin-right:10px'>
          <input id=3 type='Circle' />
        </div>
        <div>
         losing face<br>
         on edge<br>
         tearing his hair out<br>
        </div>
       </div>
      
      `,
    },
  },
  3: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E3",
    exerciseKey: "img/FriendsPlus/Page35/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        While studying in Britain, you are sharing a flat with
another student. You are having problems with your
flatmate because he / she leaves the lights on after using
the toilet. Talk with your flatmate and agree on some
rules in the flat.`,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E4",
    exerciseKey: "img/FriendsPlus/Page35/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 1 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        Young people tend to show all aspects of their life on their
social media network page. Write an essay (180–200 words)
in which you present the advantages and disadvantages of
displaying private life online.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E5",
    exerciseKey: "img/FriendsPlus/Page35/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E6",
    exerciseKey: "img/FriendsPlus/Page35/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page35/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E7",
    exerciseKey: "img/FriendsPlus/Page35/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Review Unit 2",
    id: "SB12-2024-U2-P35-E8",
    exerciseKey: "img/FriendsPlus/Page35/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page35/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
