import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E1",
    exerciseKey: "img/FriendsPlus/Page94/Key/E1answerKey.png",
    recorder: true,
    component: Circle_Write,
    // maxLength:4,
    // hideBtnFooter:true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Is the number of people using social 
          networks increasing? Which social network do you think 
          attracts the most users?`,
        color: "black",
        left: -30,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "339px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "498px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "339px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "498px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "339px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "498px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
        ],
        answers: ["0-5", "2-3", "1-4"],
        initialValue: [],
      },
      Write: {
        inputStyle: { paddingTop: 0, width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
          "a  <br> b  <br> c  <br> d",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <input id='0' type='boxMatch'/>
      <input id='1' type='boxMatch'/>
      <input id='2' type='boxMatch'/>
      <input id='3' type='boxMatch'/>
      <input id='4' type='boxMatch'/>
      <input id='5' type='boxMatch'/>
      <div style='display:flex'>
            <div>
              <b>1</b>&ensp;Rene Descartes<br>
              <b>2</b>&ensp;Johann Heinrich Lambert<br>
              <b>3</b>&ensp;Pierre De Fermat<br>
            </div>
            <div style='margin-left:200px'>
              <b>a</b>&ensp;x<sup>n</sup> + y<sup>n</sup> = z<sup>n</sup><br>
              <b>b</b>&ensp;hyperbolic functions into trigonometry<br>
              <b>c</b>&ensp;The saying “I think, therefore I am”<br>
            </div>
  
          </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page94/Key/E2answerKey.png",
    component: T6,
    inputSize: 500,
    stylesTextInput: { width: 50 },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the task and the report. Answer the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    questions: [
      {
        title: `
      <div style='display:flex'>
        <div style=''>
          <b>1.</b>&ensp; A challenge # <br>
          <b>2.</b>&ensp; Well-known mathematician # <br>
          <b>3.</b>&ensp; Different interests #<br>
        </div>
        <img src="img/FriendsPlus/Page94/E2/1.jpg" style='height:30cm'/>
      </div>
      `,
        answer: ["C", "A", "B"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E3",
    exerciseKey: "img/FriendsPlus/Page94/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      fontSize: 23,
      width: 56,
      fontWeight: "bold",
      borderBottom: "none",
      background: "none",
      paddingTop: 10,
    },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `Four sentences (A– D) have been removed from the text. Put them back in the blanks (1–4).`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex; position:relative'>
          <div >
            <b>A</b>&ensp;He then presented the proof in the journal Annals of Mathematics in 1995.<br><br>
            <b>B</b>&ensp;Fermat discovered the fundamental principle of analytic geometry.<br><br>
            <b>C</b>&ensp;In 1631 Fermat received the degree in law from the University of Orléans.<br><br>
            <b>D</b>&ensp;For centuries, so many mathematicians all over the world were puzzled by this statement<br><br>
          </div>
          <img src="img/FriendsPlus/Page94/E2/1.jpg" style='height:30cm'/>
          <div style='position:absolute;top:158px ;left:910px'>#</div>
          <div style='position:absolute;top:448px ;left:503px'>#</div>
          <div style='position:absolute;top:912px ;left:576px'>#</div>
          <div style='position:absolute;top:968px ;left:985px'>#</div>
        </div>
      
        
        `,
        answer: ["B", "C", "D", "A"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E4",
    exerciseKey: "img/FriendsPlus/Page94/Key/E4answerKey.png",
    video: "",

    component: T6,
    // recorder: true,
    textAlign: "center",
    stylesTextInput: { width: 100 },
    totalInput: 1,
    titleQuestion: [
      {
        num: "4",
        title: `Check if the following information from the text is True 
          or False. Write T or F.`,
        color: "black",
        left: 50,
        width: "30cm",
        // prefix:[{text:"KEY PHRASES",icon:"",color:"#9a2894"}]
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; position:relative'>
          <div >
              <b>1</b>&ensp;Fermat was the only founder of theory of probability #<br><br>
              <b>2</b>&ensp;Besides law, Fermat was interested in different fields. #<br><br>
              <b>3</b>&ensp;Introduction to Loci published in 1679 was related to Fermat’s work at the Criminal Court.  #<br><br>
              <b>4</b>&ensp;Fermat had never stated that he could provide a proof of his last theorem.  #<br><br>
              <b>5</b>&ensp;It took Andrew Wiles nearly ten years to search for the proof of Fermat’s last theorem.  #<br><br>
          </div>
          <img src="img/FriendsPlus/Page94/E2/1.jpg" style='height:30cm'/>
          
        </div>
      
        
        `,
        answer: ["F|false", "T|true", "F|false", "F|false", "F|false"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E5",
    exerciseKey: "img/FriendsPlus/Page94/Key/E5answerKey.png",
    component: T6,
    // maxLength:4,
    textAlign: "center",
    stylesTextInput: { width: 320, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "almost",
          "considerably",
          "suddenly",
          "slowly",
          "slightly",
          "rise",
          "change",
          "fall",
          "double",
          "plunge",
        ],
        width: 800,
        borderColor: "white",
        colorHint: "#942690",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone name='3.14' colorimg='#94c7d4' color='white' marginleft='-50px' ></headphone> Listen to a small talk on lifelong learning and 
          complete the text with appropriate information. `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix:[{text:"VOCABULARY", color:"#942690",icon:''}]
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width:25cm'>
            Lifelong learning is following <sup>1</sup># and developing 
            particular skills which are necessary for one person’s 
            job or life. Lifelong learning can only be effective if 
            the learner is self-motivated, <sup>2</sup># and focused. The 
            learner should set his own goal on his own interests. 
            He is also willing to gain more skills though it may take 
            time. He is persistent enough <sup>3</sup># his plans of leaning 
            step by step. He is the one who decides his long journey. 
            No one else can decide what he needs to improve, 
            which course he has to take and <sup>4</sup># for it. 
            Lifelong learning is regarded as one of important 
            characteristics that the employers nowadays look for. 
            It is essential for the employees to seek ways to grow 
            personally and <sup>5</sup>#  this helps them to stand out in 
            the job market. Employers are happy to have lifelong 
            learners at work because they are likely to pick up skills 
            at time, anywhere, train themselves in order to meet 
            the work demands
          </div>
          
      

        `,
        answer: [
          "additional education",
          "voluntary",
          "to carry on",
          "how much time he needs",
          "professionally",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E6",
    exerciseKey: "img/FriendsPlus/Page94/Key/E6answerKey.png",
    recorder: true,
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 655, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Discuss the following question.`,
        color: "black",
        width: "28cm",
        left: 150,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <i>Can you guess why Fermat never considered doing 
          research his job?</i>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P94-E7",
    exerciseKey: "img/FriendsPlus/Page94/Key/E7answerKey.png",
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 655, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <img src="img/FriendsPlus/Page94/E6/3.jpg" style='height:60mm'/>

          <div></div>
          <textarea id='0' rows=5></textarea>
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
