import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E1",
    exerciseKey: "img/FriendsPlus/Page18/Key/E1answerKey.png",
    audio: "Audios/1.10.mp3",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "black",
        width: "28cm",
        left: -50,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page19/E1/1.jpg" style='height:19cm'/>
        `,
        InputRong: true,
        answer: [
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E2",
    exerciseKey: "img/FriendsPlus/Page18/Key/E2answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    checkDuplicated: true,
    stylesTextInput: { textAlign: "center", width: 200, paddingTop: 10 },
    hintBox: [
      {
        src: ["about", "at", "by", "of", "to", "with"],
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the <span style='color:#91268e'>Learn this!</span> box. Which verbs in the dialogue are trasitive and which are instransitive?`,
        color: "black",
        left: 50,
        width: "90%",
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <img src="img/FriendsPlus/Page18/E2/1.jpg" style='height:19cm'/>
            <div>
                <table style="width: 50%; border-collapse: collapse;">
                  <tr>
                    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Transitive</th>
                    <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Intransitive</th>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
                    <td style="border: 2px solid black; padding: 8px;text-align:center">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                  <tr>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                    <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  </tr>
                </table>
              </div>  
          </div>  
        <div style='display:flex'>
        <div>
          <b>Mina</b><br>
          <b>Bill</b><br><br>
          <b>Mina</b><br>
          <b>Bill</b><br><br><br>
          <b>Mina</b><br><br>
          <b>Bill</b>
        </div>
        <div style='margin-left:30px; width:80%'>
        How was your date with Donna last night?<br>
        I wish you hadn’t brought that up. She didn’t
        turn_up – again – so I’m not going out with her
        any more.<br>
        But I thought you two got on really well!<br>
        So did I. But she started putting me down in
        front of her friends, and when she let me down
        again last night … Well, I couldn’t put up with
        it any more. I decided she’d put me through
        enough, so I split up with her.<br>
        That’s a shame. But you’ll soon get over her.
        Don’t let it put you off dating other girls!<br>
        "Oh, it won’t. I’ve already asked her friend out!<br>
        </div>
      </div>
        
        `,
        InputRong: true,
        answer: [
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
          "bring up|go out with|put down|let down|put up with|put through|split up with|get over|put off|ask out",
          "turn up|get on||||||||",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E3",
    exerciseKey: "img/FriendsPlus/Page18/Key/E3answerKey.png",
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 750, paddingTop: 10 },
    textareaStyle: { resize: "none", width: 750, paddingTop: 2 },
    hintBox: [
      {
        src: ["about", "at", "by", "of", "to", "with"],
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<span style='color:#91268e'>DICTIONARY WORK</span> Read the dictionary entries (A–F) and
        answer the questions.`,
        color: "black",
        left: 50,
        width: "90%",
      },
    ],
    character: ".",
    component: T6,
    questions: [
      {
        title: `
        
  <img src="img/FriendsPlus/Page18/E3/1.jpg" style='height:13cm'/>
        <div>
          <b>1</b>&ensp;Which phrasal verbs are separable?<br>#<br> Which are
            inseparable?<br>#<br> Which one must be separated by the
            object?<br>#<br>
          <b>2</b>&ensp;How is this information shown in the entries?<textarea id=3 rows=3 ></textarea>
        </div>
        `,
        answer: [
          "Turn sb/sth down and make sth up are separable.",
          "Look after sb/sth, run into sb and take after sb are inseparable.",
          "Talk sb into sth must be separated.",
          "Separable verbs are indicated with. Non-separable verbs have sb/sth after the particle, but no. Verbs that must be separated have sb/sth between the verb and the particle, and no.",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E4",
    exerciseKey: "img/FriendsPlus/Page18/Key/E4answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Complete the sentences with the words in brackets in the
        correct order.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "0px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Verbal interaction</b>",
          "compliment (sb on sth)",
          "flatter (sb)",
          "insult (sb)",
          "lecture  (sb about sth)",
          "nag (sb about sth)",
          "offend (sb)",
          "praise (sb for sth)",
          "tease (sb about sth)",
          "tell (sb) off (for sth)",
          "warn (sb about sth)",
        ],
        styleElement: { color: "#92278f", marginRight: 30 },
      },
    ],
    character: ",",
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { textAlign: "center", width: 440, paddingTop: 10 },

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;Did she manage to talk #
          date Bill? (letting / her / her / into /
          parents)<br>
          <b>2</b>&ensp;After # boyfriend,
          Laura went travelling  .
          (splitting / her / with / up) (get / him / to / over)<br>
          <b>3</b>&ensp;Neither of my cousins #
           . (aunt / takes / my / after)<br>
          <b>4</b>&ensp;That experience #
          another relationship. (me / off / put / having)<br>
          <b>5</b>&ensp;My dad went shopping this morning. I’m surprised #
           town. (into / you /
          him / in / run / didn’t)<br>
          <b>6</b>&ensp;If # too much, she may
          leave you. (her / you / through / put)<br>
        </div>
      
        
        `,
        answer: [
          "her parents into letting her",
          "splitting up with her, to get over him",
          "take after my aunt",
          "put me off having",
          "you didn't run into him in",
          "you put her through",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E5",
    exerciseKey: "img/FriendsPlus/Page18/Key/E5answerKey.png",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Rewrite the questions using phrasal verbs from this lesson.`,
        color: "black",
        left: 50,
        width: "25cm",
      },
    ],
    component: T6,
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "0px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Has a friend ever let you down?"],
        styleElement: { color: "#68a7b8", marginRight: 30 },
      },
    ],
    // hideBtnFooter:true,
    // textAlign:"center",
    stylesTextInput: { width: 650, paddingTop: 10 },
    questions: [
      {
        title: `
        
        <div>
          <b>1</b>&ensp;Has a friend ever failed to do what you expect?
          <hintbox id=0></hintbox>
          <b>2</b>&ensp;Have you ever invented a reason not to meet someone?<br>#<br>
          <b>3</b>&ensp;Who do you most resemble in your family?<br>#<br>
          <b>4</b>&ensp;Have you ever regretted rejecting an invitation?<br>#<br>
          <b>5</b>&ensp;Which person do you have the best relationship with?<br>#<br>
        </div>
      
        
        `,
        answer: [
          "Have you ever made up a reason not to meet someone?",
          "Who do you take after most in your family?",
          "Have you ever regretted turning down an invitation?",
          "Which person do you get on with the best?|Which person do you get on with best?|Which person do you get on with (the) best?",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E6",
    exerciseKey: "img/FriendsPlus/Page18/Key/E6answerKey.png",
    component: T6,
    recorder: true,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      margihLeft: 20,
      width: 700,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Work in pairs. Discuss the questions from
        exercise 5, using phrasal verbs.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div>
          <b>1</b>&ensp;Has a friend ever failed to do what you expect?<br>
          <b>2</b>&ensp;Have you ever invented a reason not to meet someone?<br>
          <b>3</b>&ensp;Who do you most resemble in your family?<br>
          <b>4</b>&ensp;Have you ever regretted rejecting an invitation?<br>
          <b>5</b>&ensp;Which person do you have the best relationship with?<br>
        </div>
        
        `,
        answer: [
          "a man is being teased (about his haircut).",
          "a student is being praised (for his good work).",
          "a girl is being flattered (about her personality).",
          "a teenager is being told off (for going out at the weekend when they should have been revising).",
          "a driver is being warned (that he will be arrested unless he shows his licence).",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E7",
    exerciseKey: "img/FriendsPlus/Page18/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Use verbs from exercise 5 to ask
        and answer questions about things that have happened
        to you. `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page18/E7/1.jpg" style='margin-left:50px;height:7cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 1",
    id: "SB12-2024-U1-P18-E8",
    exerciseKey: "img/FriendsPlus/Page18/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page18/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
