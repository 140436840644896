import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P92-E1",
    exerciseKey: "img/FriendsPlus/Page92/Key/E1answerKey.png",
    recorder: true,
    component: T6,
    // maxLength:4,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: { width: 150, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions`,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width:23cm'>
          <b>1</b>&ensp;Have social media, and modern technology in general, created any new kinds of crime? If so, what are they?<br>
          <b>2</b>&ensp;Have they created any new ways to fight crime? If so, what are they?
        </div>
        `,
        answer: [
          "locate",
          "renovate",
          "excavate",
          "build",
          "unearth",
          "remove",
          "restore",
          "display",
          "bury",
          "destroy",
          "uncover",
          "examine",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P92-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page92/Key/E2answerKey.png",
    component: T6,
    inputSize: 500,
    textareaStyle: { width: 800, paddingTop: 2, resize: "none" },
    hintBox: [
      {
        src: [
          "belong to",
          "come across",
          "keep",
          "forget",
          "look forward",
          "recognise",
          "remind",
          "leave",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `Read the news reports. In what two different ways did 
      Facebook help to catch criminals? Which story does the 
      photo belong with?`,
        color: "black",
        width: "28cm",
        left: 0,
      },
    ],
    questions: [
      {
        title: `
      <img src="img/FriendsPlus/Page92/E2/1.jpg" style='height:18cm'/>
      <div style='width:23cm'>
        <b>1</b>&ensp;In what two different ways did Facebook help to catch criminals?<br><textarea id='0' rows=0></textarea>
      </div>
        <b>2</b>&ensp; Which story does the photo belong with?<br><textarea id='1' rows=2></textarea>
      `,
        answer: [
          "In news report 1, the thief used his victim's computer to check his Facebook account and forgot to log out, so it was easy to catch him.",
          "In news report 2, the fugitive added a Facebook friend who used to be a Justice Department official. Story 2 matches the photo.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P92-E3",
    exerciseKey: "img/FriendsPlus/Page92/Key/E3answerKey.png",
    audio: "Audios/3.11.mp3",
    component: T6,
    textAlign: "center",
    maxLength: 1,
    hideBtnFooter: true,
    stylesTextInput: { width: 50, paddingTop: 10 },
    hintBox: [
      {
        src: ["finish", "leave", "study", "not read", "close", "not use"],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 150,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone colorimg='#b5d0b7' name='3.12' color='white' marginleft='-50px'  ></headphone> Listen to a student doing the task below. 
          Do you agree or disagree with his opinions? Does he 
          mention any of your ideas from exercise 1?`,
        color: "black",
        left: 70,
        width: "28cm",
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page92/E3/1.jpg" style='height:6cm'/>
        
      
        
        `,
        answer: ["B", "A", "D"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P92-E4",
    exerciseKey: "img/FriendsPlus/Page92/Key/E4answerKey.png",
    video: "",
    audio: "Audios/3.11.mp3",
    component: T6,
    // recorder: true,
    stylesTextInput: { width: 200, paddingTop: 2, resize: "none" },
    textareaStyle: {
      width: 500,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone colorimg='#b5d0b7' name='3.12' color='white' marginleft='-50px'  ></headphone> Read the <span style='color:#009345'>Reading Strategy</span>. Then listen 
          again. What example does the student give for each 
          of the points (1–3) below? What phrase does he use to 
          introduce it?`,
        color: "black",
        left: 50,
        width: "30cm",
      },
    ],
    // isHello: true,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page92/E4/1.jpg" style='height:7cm'/>
  <table style="width: 80%; border-collapse: collapse;border:2px solid rgb(0,147,69)">
  <tr>
  <th style="width:20%;background-color: rgb(0,147,69);color:white; padding: 8px; text-align: center;"></th>
    <th style="width:30%;background-color: rgb(0,147,69);color:white; border-left: 2px solid white; padding: 8px; text-align: center;">Example</th>
    <th style="width:30%;background-color: rgb(0,147,69);color:white; border-left: 2px solid white; padding: 8px; text-align: center;">Phrase</th>
  </tr>
  <tr>
    <td style="border: 2px solid rgb(0,147,69);text-align:center; padding: 8px;"><b>1</b>&ensp;Problems caused by social media</td>
    <td style="border: 2px solid rgb(0,147,69);text-align:center; padding: 8px;"><textarea id=0 rows=4 ></textarea></td>
    <td style="border: 2px solid rgb(0,147,69);text-align:center; padding: 8px;"><textarea id=1 rows=4 ></textarea></td>
  </tr>
  <tr>
    <td style="border: 2px solid rgb(0,147,69); padding: 8px;text-align:center"><b>2</b>&ensp;Sharing information to fight crimes</td>
    <td style="border: 2px solid rgb(0,147,69); padding: 8px;text-align:center"><textarea id=2 rows=4 ></textarea></td>
    <td style="border: 2px solid rgb(0,147,69); padding: 8px;text-align:center"><textarea id=3 rows=4 ></textarea></td>
  </tr>
  <tr>
    <td style="border: 2px solid rgb(0,147,69);text-align:center; padding: 8px;"><b>3</b>&ensp;Criminals using social media unwisely</td>
    <td style="border: 2px solid rgb(0,147,69);text-align:center; padding: 8px;"><textarea id=4 rows=4 ></textarea></td>
    <td style="border: 2px solid rgb(0,147,69);text-align:center; padding: 8px;"><textarea id=5 rows=4 ></textarea></td>
  </tr>
</table>
      

        `,
        answer: [
          "Somebody might feel very unhappy because a photograph of them is posted online without their permission. So, for instance, …",
          "So, for instance",
          "somebody has a bike stolen and puts a message on Facebook or Twitter asking for help, The kind of thing I'm talking about is …",
          "The kind of thing I'm talking about is",
          "a criminal posts a photo of himself with something that he has stolen, One example of that would be …",
          "One example of that would be",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P92-E5",
    component: T6,
    // maxLength:4,
    textAlign: "center",
    textareaStyle: { width: 830, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "Afterwards",
          "Amazingly",
          "at",
          "finally",
          "Meanwhile",
          "The moment",
          "While",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `Work in pairs. Look at the task, news report and 
          headline below. Choose two of the prompts each and 
          make notes. Then work together to add details and 
          examples to your notes.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page92/E5/1.jpg" style='height:6cm'/>
          <div></div>
          <img src="img/FriendsPlus/Page92/E5/2.jpg" style='height:10cm'/>
          
      

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P92-E6",
    exerciseKey: "img/FriendsPlus/Page92/Key/E6answerKey.png",
    recorder: true,
    component: T6,
    inputSize: 500,
    textAlign: "center",
    textareaStyle: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `Take turns to do the task in exercise 5. Use 
          your notes to talk about your two prompts.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page92/E5/1.jpg" style='height:6cm'/>
          
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
