import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E1",
    exerciseKey: "img/FriendsPlus/Page54/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Look at the photos. Which do you
        think is the best method of travelling?`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page54/E1/1.jpg" style='height:12cm'/><br>

        `,
        answer: [
          "It is a holiday company for young people",
          "It has been running for nine years.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E2",
    exerciseKey: "img/FriendsPlus/Page54/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the texts quickly. Match titles (1–4) with
        texts (A–C). There is one extra title.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 50, paddingTop: 10 },
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // character:",",
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='margin:10px 0px'>
          <b>1</b>&ensp;The art of travelling light #<br>
          <b>2</b>&ensp;Non-stop traveller #<br>
          <b>3</b>&ensp;On the road to nowhere #<br>
          <b>4</b>&ensp;Follow your dreams  #<br>
        </div>
        
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        
        
        `,
        InputRong: true,
        answer: ["C", "A", "", "B"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E3",
    exerciseKey: "img/FriendsPlus/Page54/Key/E3answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Look at the texts again. What was the
        purpose of each trip?`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["closely", "daily", "early", "friendly", "late", "quick"],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 600,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='margin:10px 0px'>
          <div style='display:flex'><b>1</b>&ensp;<textarea id=0 rows=2></textarea></div>
          <div style='display:flex'><b>2</b>&ensp;<textarea id=1 rows=2></textarea></div>
          <div style='display:flex'><b>3</b>&ensp;<textarea id=2 rows=2></textarea></div>
        </div>
        
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        `,
        answer: [
          "To travel the world on his motorbike.",
          "To travel from Europe, across Africa to the South Pole.",
          "To travel around eleven countries in 42 days with just the things he could carry in his pockets",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E4",
    exerciseKey: "img/FriendsPlus/Page54/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 900,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: ` Read the <span style='color: #42429c'>Reading Strategy</span> and look at the first
        question in exercise 5. Then follow the steps below.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: -20,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: ["Alex in text A."],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 5,
        listWords: [
          `At_the_age_of_seventeen,_Alex_Chacón_set_off_from_his_home_in_El_Paso,_Texas_and_went_for_a_motorbike_ride.
          Alex’s_thirty-day_tour_of_the_USA_took_in_California,_Washington_and_Florida_and_gave_Alex_an_aim_in_life:_to_travel_the_world_on_his_motorbike.
          <br>
          Although_Alex_usually_travels_alone,_he_has_shared_his_experiences_on_his_blog.
          While_travelling,_Alex_videos_himself_doing_all_sorts_of_activities_against_breathtaking_backgrounds_of_remote_jungles,_deserts_and_mountains.
           On_one_trip,_he_filmed_himself_by_moving_in_a_360<sup>o</sup>_circle_so_that_all_the_famous_landmarks_would_be_included.<br>
          During_Alex’s_motorcycle_expeditions,_he_regularly_stops_to_take_part_in_volunteering_projects_at_orphanages_that_he_comes_across_on_his_route.
          Furthermore,_he_uses_his_blog_to_raise_donations_for_orphans.<br>
          Alex_is_now_planning_to_cross_Africa,_Europe_and_Asia. 
          It_seems_that_by_the_time_he’s_thirty,_Alex_will_have_ridden_his_bike_across_most_of_the_planet!
          `,
          `Dutch_actress_Manon_Ossevoort_has_dreamed_of_travelling_from_Europe_across_Africa_to_the_South_Pole_for_years,_and_she_chose_a_slow_form_of_transport:_a_tractor.<br> 
          Manon_only_averaged_5_km/h_as_she_drove,_but_that_allowed_her_to_get_to_know_the_hospitable_people_in_villages_where_she_stopped_over_for_the_night. 
          She_would_explain_why_she_was_going_to_the_South_Pole_and_ask_people_to_follow_her_blog_and_write_down_their_own_dreams. 
          She_promised_to_build_a_snowman_when_she_arrived_at_her_destination_and_leave_all_their_dreams_inside_it. 
          The_idea_was_popular_and_Manon_received_thousands_of_dreams_on_pieces_of_paper_and_in_emails.<br>
          Finally,_on_9_December_2014,_Manon’s_dream_came_true,_and_she_pulled_up__in_a_big_red_tractor_at_the_South_Pole. 
          She_then_built_a_snowman_and_left_a_time_capsule_inside_it_with_all_the_dreams_she_had_collected. 
          Manon_explained_on_her_blog_that_when_the_time_capsule_is_opened_in_eighty_years’_time,_future_generations_will_be_able_to_read_something_about_our_lives_and_our_hopes_for_the_future.`,
          `Have_you_ever_travelled_with_no_luggage? 
          That_was_the_question_Rolf_Potts_aimed_to_answer_on_the_‘no-baggage_challenge’. 
          However,_the_task_wasn’t_simply_a_question_of_going_off_for_a_week_to_soak_up_the_sun_on_a_beach. 
          Rolf_had_to_travel_50,000_km_by_plane_and_get_around_eleven_countries_in_42_days_with_just_the_things_he_could_carry_in_his_pockets. 
          Would_he_be_able_to_do_it?<br>
          Rolf_was_certainly_well_qualified_enough_to_try. 
          He_was_an_experienced_traveller_who_wrote_a_blog_about_budget_travel. 
          Now_he_planned_to_update_it_with_regular_reports_about_travelling_extremely_light.<br>
          His_first_‘no-baggage_challenge’_entry_listed_the_items_he_took_with_him:_a_toothbrush
          and_toothpaste,_a_small_bottle_of_soap,_a_mobile_phone_and_charger,_deodorant,_sunglasses,_a_passport,_cash_and_a_credit_card. 
          His_second_entry_explained_that_he_had_got_into_the_habit_of_washing_his_clothes_before_going_to_bed_during_his_trips. 
          By_the_third_entry,_he_was_really_enjoying_luggage-free_travel.<br>
          Potts_said_that_he_would_never_pack_any_‘just_in_case’_items_again,_because_the_imagined_situations_that_they_were_packed_for_would_never_happen. <br>`,
        ],
        answers: ["0-156", "1-166", "2-142", "0-66", "1-48"],
        initialValue: [],
      },
      Layout: `
      <div style='border-radius:30px;border: 6px dotted rgb(66,66,156);background: rgb(228,225,242); width:17cm; padding:10px 20px'>
          <b style='font-size:30px;color:rgb(66,66,156)'>Reading Strategy</b>
          <div>
            When you have to match statements or questions with
            different texts, remember that some of the topics or
            key words from the task may appear in several of the
            texts. Study each text carefully to decide which of the
            statements or questions matches it most closely.
          </div>
        </div>
        
        <b>the first question in exercise 5</b><br>
        <b>1</b>&ensp;the traveller using his / her blog to ask people to do
        things?<br>
        <b>Answer</b><br>
        <div style='width:25cm'>
          <b>1</b>&ensp;Does the word ‘blog’ appear in texts A, B and C? Find
          examples in each text.<br>
          
          <b style='font-size:34px;color:red'>A</b><br>
          <input id=0 type='Circle' /><br>
          <b style='font-size:34px;color:red'>B</b><br>
          <input id=1 type='Circle' /><br>
          <b style='font-size:34px;color:red'>C</b><br>
          <input id=2 type='Circle' /><br>
        <b>2</b>&ensp;Read the texts carefully and decide which person
          used their blog for the purpose described in question 1
          in exercise 5.
          <input id=0 />
        </div>
        
      
      
      `,
    },
  },
  5: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E5",
    exerciseKey: "img/FriendsPlus/Page54/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Complete the sentences with the adverbs and adverbial
        phrases below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 50,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["daily", "hard", "lately", "pretty", "rough"],
        styleElement: { color: "#91268e", marginRight: "30px" },
        width: "70%",
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        Which text mentions ...
        <div style='line-height:47px'>
          <b>1</b>&ensp;the traveller using his / her blog to ask people to do things? #<br>
          <b>2</b>&ensp;the things that the traveller took with him / her on the journey? #<br>
          <b>3</b>&ensp;the traveller asking people both online and that he /  she met to take part in something? #<br>
          <b>4</b>&ensp;the traveller helping people to collect money for a good cause? #<br>
          <b>5</b>&ensp;the speed at which the traveller went?  #<br>
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        `,
        answer: ["A", "C", "B", "A", "B"],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E6",
    exerciseKey: "img/FriendsPlus/Page54/Key/E6answerKey.png",
    // checkDuplicated: true,
    // textAlign: "center",

    stylesTextInput: { width: 630 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the texts again. Answer the questions in your
        own words if possible.`,
        color: "black",
        width: "28cm",
        left: 40,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "DICTIONARY WORK", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Being diplomatic</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:47px'>
          <b>1</b>&ensp;In what three ways does Alex Chacón use his blog?<textarea id=0 rows=3></textarea>
          <b>2</b>&ensp;If Alex’s plans succeed, how many years will he have been travelling?<textarea id=1 rows=3></textarea>
          <b>3</b>&ensp;What were the advantages of travelling by tractor for Manon Ossevoort?<textarea id=2 rows=3></textarea>
          <b>4</b>&ensp;How will future generations learn about our hopes for the future thanks to Manon?<textarea id=3 rows=3></textarea>
          <b>5</b>&ensp;Why did Rolf Potts seem to be the right person to try the no-baggage challenge?<textarea id=4 rows=3></textarea>
          <b>6</b>&ensp;What never, ever happens according to Rolf?<textarea id=5 rows=3></textarea>
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        `,
        answer: [
          "He uses his blog to tell other people about his experiences and show them the places he visits. He also asks people to donate money to a charity that works with orphans.",
          "He will have been travelling for thirteen years.",
          "She travelled slowly and had the chance to get to know people, who were attracted to the tractor.",
          "She asked people to send her messages about their dreams and put these messages into a time capsule which she left in a snowman that she made at the South Pole.",
          "Because he is an experienced traveller and he writes a blog in which he gives advice about how to travel on a limited budget.",
          "Occasions which require special items of clothing or equipment never happen.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E7",
    exerciseKey: "img/FriendsPlus/Page54/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `Match the highlighted phrasal verbs in
        the texts with the definitions. Write the base forms.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "bring",
          "feel",
          "just",
          "mind",
          "on",
          "perhaps",
          "should",
          "word",
        ],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Phrasal verbs</b>"],
        styleElement: { color: "#414199", marginRight: "30px" },
      },
    ],
    questions: [
      {
        title: `
        <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          <hintbox id=1></hintbox>
          <b>1</b>&ensp;to stop a vehicle #<br>
          <b>2</b>&ensp;to leave a place to do something #<br>
          <b>3</b>&ensp;to move from place to place #<br>
          <b>4</b>&ensp;to begin a journey #<br>
          <b>5</b>&ensp;to find something by chance #<br>
          <b>6</b>&ensp;to include #<br>
          <b>7</b>&ensp;to stay somewhere for a short time during a journey #<br>
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>A</b><br>
          At the age of seventeen, Alex Chacón
          <span style='background: rgb(252,193,91)'>set off</span> from his home in El Paso, Texas
          and went for a motorbike ride. Alex’s
          thirty-day tour of the USA <span style='background: rgb(252,193,91)'>took in</span>
          California, Washington and Florida and
          gave Alex an aim in life: to travel the
          world on his motorbike.<br>
          Although Alex usually travels alone, he
          has shared his experiences on his blog.
          While travelling, Alex videos himself
          doing all sorts of activities against
          breathtaking backgrounds of remote
          jungles, deserts and mountains. On
          one trip, he filmed himself by moving
          in a 360<sup>o</sup> circle so that all the famous
          landmarks would be included.<br>
          During Alex’s motorcycle expeditions,
          he regularly stops to take part in
          volunteering projects at orphanages
          that he <span style='background: rgb(252,193,91)'>comes across</span> on his route.
          Furthermore, he uses his blog to raise
          donations for orphans.<br>
          Alex is now planning to cross Africa,
          Europe and Asia. It seems that by the
          time he’s thirty, Alex will have ridden his
          bike across most of the planet!
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>B</b><br>
          Dutch actress Manon Ossevoort has dreamed of travelling from Europe across
          Africa to the South Pole for years, and she chose a slow form of transport: a tractor.<br>
          Manon only averaged 5 km/h as she drove, but that allowed her to get to know
          the hospitable people in villages where she <span style='background: rgb(252,193,91)'>stopped over</span> for the night. She would
          explain why she was going to the South Pole and ask people to follow her blog and
          write down their own dreams. She promised to build a snowman when she arrived
          at her destination and leave all their dreams inside it. The idea was popular and
          Manon received thousands of dreams on pieces of paper and in emails.<br>
          Finally, on 9 December 2014, Manon’s dream came true, and she <span style='background: rgb(252,193,91)'>pulled up</span>  in a
          big red tractor at the South Pole. She then built a snowman and left a time capsule
          inside it with all the dreams she had collected. Manon explained on her blog that
          when the time capsule is opened in eighty years’ time, future generations will be
          able to read something about our lives and our hopes for the future.
        </div>
        <div style='width:25cm'>
          <b style='font-size:34px;color:red'>C</b><br>
          Have you ever travelled with no luggage? That was the question Rolf Potts aimed to
          answer on the ‘no-baggage challenge’. However, the task wasn’t simply a question of
          <span style='background: rgb(252,193,91)'>going off</span> for a week to soak up the sun on a beach. Rolf had to travel 50,000 km by
          plane and <span style='background: rgb(252,193,91)'>get around</span> eleven countries in 42 days with just the things he could carry
          in his pockets. Would he be able to do it?<br>
          Rolf was certainly well qualified enough to try. He was an experienced traveller who
          wrote a blog about budget travel. Now he planned to update it with regular reports
          about travelling extremely light.<br>
          His first ‘no-baggage challenge’ entry listed the items he took with him: a toothbrush
          and toothpaste, a small bottle of soap, a mobile phone and charger, deodorant,
          sunglasses, a passport, cash and a credit card. His second entry explained that he had
          got into the habit of washing his clothes before going to bed during his trips. By the
          third entry, he was really enjoying luggage-free travel.<br>
          Potts said that he would never pack any ‘just in case’ items again, because the
          imagined situations that they were packed for would never happen. <br>
        </div>
        `,
        answer: [
          "pull up",
          "go off",
          "get around",
          "set off",
          "come across",
          "take in",
          "stop over",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E8",
    exerciseKey: "img/FriendsPlus/Page54/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Decide who is for and who is against
        this statement: ‘More tourism would be bad for the
        planet.’ Prepare arguments in favour of and against
        the statement. Use the ideas below to help you.
        `,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <textarea id=0 rows=5></textarea>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 4",
    id: "SB12-2024-U4-P54-E9",
    exerciseKey: "img/FriendsPlus/Page54/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    recorder: true,
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
