import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E1",
    exerciseKey: "img/FriendsPlus/Page24/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Match each event with its date. Do you and
        your family do anything special on these days?`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        
          <img src="img/FriendsPlus/Page24/E1/1.jpg" style='height:12cm'/>
          <img src="img/FriendsPlus/Page24/E1/3.jpg" style='height:12cm'/>
          
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E2",
    exerciseKey: "img/FriendsPlus/Page24/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Check the meaning of the adjectives below. Match at least two adjectives with
        each person in the photos.
        `,
        color: "black",
        left: 50,
        width: "100%",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='position:relative'>
          <img src="img/FriendsPlus/Page24/E1/1.jpg" style='height:11cm'/>
          <img src="img/FriendsPlus/Page24/E1/3.jpg" style='height:11cm'/>
          <div style='position:absolute;top:426px;left:400px'>
                <table style="width:80%; border-collapse: collapse;">
                <tr>
                  <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Photo A</th>
                  <th colspan="2" style="width:40%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Photo B</th>
                  <th style="width:20%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Photo C</th>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><input group=3 id=0 /></td>
                  <td style='border: 2px solid black;text-align:center; padding: 8px;' colspan="2">
                        <table style=" width:100%">
                            <tr>
                                <td style='border-right:2px solid black'><b>Boy</b></td>
                                <td ><b>Girl</b></td>
                            </tr>
                        </table>
                    </td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><input group=3 id=1 /></td>
                </tr>
                <tr>
                  <td style="border: 2px solid black; padding: 8px;text-align:center"><input group=3 id=2 /></td>
                  <td style='border: 2px solid black;text-align:center; padding: 8px;' colspan="2">
                        <table style=" width:100%">
                            <tr>
                                <td style='border-right:2px solid black'><input group=3 id=3 /></td>
                                <td><input group=3 id=4 /></td>
                            </tr>
                        </table>
                    </td>
                  <td style="border: 2px solid black; padding: 8px;text-align:center"><input group=3 id=5 /></td>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><input group=3 id=6 /></td>
                  <td style='border: 2px solid black;text-align:center; padding: 8px;' colspan="2">
                        <table style=" width:100%">
                            <tr>
                                <td style='border-right:2px solid black'><input group=3 id=7 /></td>
                                <td><input group=3 id=8 /></td>
                            </tr>
                        </table>
                    </td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><input group=3 id=9 /></td>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><input group=3 id=10 /></td>
                  <td style='border: 2px solid black;text-align:center; padding: 8px;' colspan="2">
                        <table style=" width:100%">
                            <tr>
                                <td style='border-right:2px solid black'><input group=3 id=11 /></td>
                                <td><input group=3 id=12 /></td>
                            </tr>
                        </table>
                    </td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><input group=3 id=13 /></td>
                </tr>
                <table>
        </div>
      </div>
        `,
        InputRong: true,
        answer: [
          "anxious|frustrated|miserable|stressed",
          "frustrated|irritated||",
          "anxious|frustrated|miserable|stressed",
          "content|thrilled|upbeat|",
          "bitter|disappointed|envious|",
          "frustrated|irritated||",
          "anxious|frustrated|miserable|stressed",
          "content|thrilled|upbeat|",
          "bitter|disappointed|envious|",
          "frustrated|irritated||",
          "anxious|frustrated|miserable|stressed",
          "content|thrilled|upbeat|",
          "bitter|disappointed|envious|",
          "frustrated|irritated||",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E3",
    exerciseKey: "img/FriendsPlus/Page24/Key/E3answerKey.png",
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title: `Which of the adjectives in exercise 2 have a positive meaning and which have a
        negative meaning? Are there any that you think can have both? `,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    textareaStyle: { resize: "none", width: 300, paddingTop: 2 },
    stylesTextInput: { width: 300, paddingTop: 2 },
    hintBox: [
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    component: T6,
    character: ",",
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <table style="width:90%; border-collapse: collapse;">
                <tr>
                  <th style="width:30%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Positive</th>
                  <th style="width:30%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Negative</th>
                  <th style="width:30%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">Both</th>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><textarea id=0 rows=8 ></textarea></td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><textarea id=1 rows=8 ></textarea></td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;"><textarea id=2 rows=8 ></textarea></td>
                </tr>
                
              <table>
          
          
        `,
        InputRong: true,
        answer: [
          "amused, content, thrilled, upbeat",
          "anxious, ashamed, bitter, disappointed, disillusioned, envious, exasperated, frustrated, furious, humiliated, indecisive, irritated, miserable, stressed, alarmed",
          "hysterical, stunned",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E4",
    exerciseKey: "img/FriendsPlus/Page24/Key/E4answerKey.png",
    textareaStyle: { width: 800, paddingTop: 5 },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `How might you feel in these situations? Choose two or three adjectives from
        exercise 2 for each situation.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div>
            <b>1</b>&ensp;You hear a strange noise in the middle of the night.<br>
            <b>2</b>&ensp;You receive an email with some good news.<br>
            <b>3</b>&ensp;You’re waiting for a friend and she’s late (for the third time this week!).<br>
            <b>4</b>&ensp;A friend makes fun of you in front of other people.<br>
            <b>5</b>&ensp;You’re waiting for some important exam results.<br>
            <b>6</b>&ensp;Your application for a summer job has been rejected.&ensp;Did they get their points in the third question?<br>
        </div>
          
        <table style="width:90%; border-collapse: collapse;">
                <tr>
                  <th style="width:15%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">1</th>
                  <th style="width:15%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">2</th>
                  <th style="width:15%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">3</th>
                  <th style="width:15%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">4</th>
            <th style="width:15%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">5</th>
            <th style="width:15%;background-color: #f2f2f2; border: 2px solid black; padding: 8px; text-align: center;">6</th>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
            <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
            <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
            <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
            <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                </tr>
                <tr>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                  <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
            <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
            <td style="border: 2px solid black;text-align:center; padding: 8px;">#</td>
                </tr>
            <table>
        <div>
        
        `,
        InputRong: true,
        answer: [
          "alarmed|anxious|indecisive",
          "content|thrilled|upbeat",
          "exasperated|furious|irritated",
          "bitter|furious|humiliated",
          "anxious|stressed|",
          "disappointed|disillusioned|frustrated",
          "alarmed|anxious|indecisive",
          "content|thrilled|upbeat",
          "exasperated|furious|irritated",
          "bitter|furious|humiliated",
          "anxious|stressed|",
          "disappointed|disillusioned|frustrated",
          "alarmed|anxious|indecisive",
          "content|thrilled|upbeat",
          "exasperated|furious|irritated",
          "bitter|furious|humiliated",
          "anxious|stressed|",
          "disappointed|disillusioned|frustrated",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E5",
    exerciseKey: "img/FriendsPlus/Page24/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `<span style='color:#91268e'>VOCABULARY</span> Match the idioms below with a feeling from exercise 2. `,
        color: "black",
        left: 50,
        width: "25cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 404, paddingTop: 10 },
    hintBox: [
      {
        src: ["<b>Idioms to describe feelings</b>"],
        styleElement: { color: "#91268e", marginRight: "30px" },
      },
      {
        src: [
          "<b>Describing feelings</b>",
          "alarmed",
          "amused",
          "anxious",
          "ashamed",
          "bitter",
          "content",
          "disappointed",
          "disillusioned",
          "envious",
          "exasperated",
          "frustrated",
          "furious",
          "humiliated",
          "hysterical",
          "indecisive",
          "irritated",
          "miserable",
          "stressed",
          "stunned",
          "thrilled",
          "upbeat",
        ],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: "/",
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=1></hintbox>
        <div style='line-height:50px;padding-left:10px;border-left:5px solid rgb(140,40,144)'>
          <hintbox id=0></hintbox>
          <b>1</b>&ensp;be over the moon <span style='color:rgb(78,140,151)'>thrilled</span><br>
          <b>2</b>&ensp;be down in the dumps#<br>
          <b>3</b>&ensp;blow your top#<br>
          <b>4</b>&ensp;be on edge#<br>
          <b>5</b>&ensp;something gets on your nerves#<br>
          <b>6</b>&ensp;be green with envy#<br>
          <b>7</b>&ensp;lose face#<br>
          <b>8</b>&ensp;be tearing your hair out#<br>
          <b>9</b>&ensp;be in two minds (about something)#<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "miserable",
          "furious",
          "anxious / stressed",
          "irritated",
          "envious",
          "humiliated",
          "anxious / exasperated / frustrated",
          "indecisive",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E6",
    exerciseKey: "img/FriendsPlus/Page24/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page24/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E7",
    exerciseKey: "img/FriendsPlus/Page24/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "SB12-2024-U2-P24-E8",
    exerciseKey: "img/FriendsPlus/Page24/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page24/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
