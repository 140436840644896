import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E1",
    exerciseKey: "img/FriendsPlus/Page83/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Reading</span> <br> Read the text and questions (1–6) below. Match the correct
        question with each part of the text (A–C). You can match
        each part with more than one question.`,

        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    stylesTextInput: { width: 40, textAlign: "center" },
    selectStyle: {
      marginTop: 2,
      border: "2px solid black",
      color: "black",
      width: 40,
      height: 40,
      textAlign: "center",
      fontSize: 25,
      marginRight: 15,
    },
    // select: true,
    selectOptionRandom: true,
    selectOptionValues: ["A", "B", "C"],
    component: T6,
    questions: [
      {
        title: `
        In which paragraph does the author mention an activity which …<br>
        <div style='display:flex'><select id=0></select><b>1</b>&ensp;has become extremely popular recently?<br></div>
        <div style='display:flex'><select id=1></select><b>2</b>&ensp;requires you to do two things at once?<br></div>
        <div style='display:flex'><select id=2></select><b>3</b>&ensp;has made an existing form of exercise more extreme?<br></div>
        <div style='display:flex'><select id=3></select><b>4</b>&ensp;gives the wrong impression to the observer?<br></div>
        <div style='display:flex'><select id=4></select><b>5</b>&ensp;tires you out after a very short time?<br></div>
        <div style='display:flex'><select id=5></select><b>6</b>&ensp;can be particularly painful?<br></div>
        <b style='font-size:30px'>The fitness trends</b>

      <div style='display:flex;width:25cm'>
        <b>A</b>
        <div style='margin-left:15px'>
          Reformer Pilates is a much more intense form of
          Pilates. It involves doing the same sort of pushing and
          stretching exercises, but on equipment resembling a
          rowing machine. It isn’t the kind of thing you’d have at
          home, so you have to go to a special Pilates centre to
          try it. If you do, bear in mind that you won’t be able
          to walk up or down stairs for a few days afterwards. At
          first, it looks quite easy, but towards the end of the
          session, your thighs will be crying out for mercy.
        </div>
      </div>
      <div style='display:flex;width:25cm'>
       <b>B</b>
        <div style='margin-left:15px'>
            Jumping up and down on a trampoline is a lot of
            fun, but it can make a great workout too. You’d be
            surprised by how exhausted you are after only a few
            minutes bouncing. Not only is it better for the heart
            than running, but it also increases co-ordination and
            helps reduce stress levels. Trampolining is taking off in
            a big way, and some new centres have as many as 150
            trampolines. You can either have a go at ‘freejumping’ –
            without an instructor – or pay someone to teach you
            how to do it properly.
        </div>
      </div>
      <div style='display:flex;width:25cm'>
        <b>C</b>
        <div style='margin-left:15px'>
          If you’re usually quite active, but you’re feeling down
          in the dumps, then you might like to try Dynamic
          Running Therapy – going out for a run with a therapist.
          The sessions are quite pricey as the therapists are
          professionals and they charge a bit more than the
          going rate. It’s easier to do both activities when you’re
          running and talking at the same time: you run further
          because you don’t notice your sore feet, and you talk
          more freely than you would in the therapist’s office.
        </div>
      </div>
 
        `,
        answer: ["B", "C", "B", "A", "B", "A"],
      },
    ],
  },
  2: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E2",
    exerciseKey: "img/FriendsPlus/Page83/Key/E2answerKey.png",
    audio: "Audios/2.28.mp3",

    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Listening</span><br>
        <headphone name='2.28' colorimg=#9bc5d6  color='white'></headphone> You will hear three texts. Choose the correct
        answers (a–c).
        `,
        color: "black",
        left: -20,
        width: "28cm",
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 7px",
          border: "2px solid white",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "0px 7px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
          `a <br> b <br> c`,
        ],
        answers: ["0-8", "1-4", "2-8", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `
<b style='font-size:28px'>Text 1</b><br>
<b>1</b>&ensp;The speaker compares the kiwi to other fruits in terms of
 <div style='display:flex'>
      <div style='margin-left:15px'><input id=0 type=Circle /></div>
      <div>
          its appearance. <br>
          its size. <br>
          its taste. <br>
      </div>
 </div>
<b>2</b>&ensp;The article specifically states that the kiwi fruit is good for people with diabetes because
 <div style='display:flex'>
      <div style='margin-left:15px'><input id=1 type=Circle /></div>
      <div>
          it is rich in Vitamin C. <br>
          it is a great source of fibre. <br>
          it contains phytonutrients. <br>
      </div>
 </div>
<b style='font-size:28px'>Text 2</b><br>
<b>3</b>&ensp;In the interview, which of the following does Dr Smith recommend people to do?
 <div style='display:flex'>
      <div style='margin-left:15px'><input id=2 type=Circle /></div>
      <div>
          Avoid eating most sorts of nuts <br>
          Choose a particular type of nut to eat <br>
          Eat nuts with no additives <br>
      </div>
 </div>
<b>4</b>&ensp;What does Dr Smith say about chestnuts?
 <div style='display:flex'>
      <div style='margin-left:15px'><input id=3 type=Circle /></div>
      <div>
          They are usually processed and packaged. <br>
          They aren’t as fattening as pecans. <br>
          They are much healthier than most nuts. <br>
      </div>
 </div>
<b style='font-size:28px'>Text 3</b><br>
<b>5</b>&ensp;How should the piece of news be headlined?
 <div style='display:flex'>
      <div style='margin-left:15px'><input id=4 type=Circle /></div>
      <div>
          Poor labelling adds to obesity crisis <br>
          Solution to obesity crisis found <br>
          Obese people eat fewer healthy foods <br>
      </div>
 </div>
      
      
      
      `,
    },
  },
  3: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E3",
    exerciseKey: "img/FriendsPlus/Page83/Key/E3answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Speaking</span><br>
        Work in pairs. Discuss the questions below. You may include
the following points in your discussion.<br> <i>What makes people unhealthy? How?</i>
  `,
        color: "black",
        left: 50,
        width: "90%",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <ul>
          <li>Diet</li>
          <li>Exercise</li>
          <li>Lifestyle</li>
          <li>Education</li>
        </ul>
         
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },

  4: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E4",
    exerciseKey: "img/FriendsPlus/Page83/Key/E4answerKey.png",
    textareaStyle: { width: 1000, paddingTop: 1 },
    titleQuestion: [
      {
        num: "",
        title: `<span style='font-size:30px;color:#016e92' >Writing</span><br>
        You’ve read an article about old-fashion remedies. Write an
article (180–200 words) for your school website about an
old-fashioned remedy that you or a member of your family
has ever used for treating an illness or an injury.
       `,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    stylesTextInput: { width: 200, textAlign: "center" },
    component: T6,
    questions: [
      {
        title: `
        <textarea id=0 rows=5></textarea>
          
        `,
        answer: ["Round 2", "Family life", "Flowers", "Pink", "Yes, they did."],
      },
    ],
  },
  5: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E5",
    exerciseKey: "img/FriendsPlus/Page83/Key/E5answerKey.png",
    textareaStyle: {
      width: 600,
      paddingTop: 2,
      resize: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `You are having a family reunion at the end of
        the month. Plan a short trip for your family on that day,
        including:`,
        color: "black",
        left: 50,
        width: "25cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { textAlign: "center", width: 600, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "appears",
          "as",
          "be",
          "can’t",
          "certain",
          "like",
          "must",
          "say",
          "seems to",
          "wrong",
        ],
        styleElement: { color: "#1a9955", marginRight: "30px" },
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp;Where you and your family are going ...<br>
          <b>2</b>&ensp;What activities you are participating in ...<br>
        </div>
        `,
        // InputRong: true,
        answer: [
          "",
          "4, 8|8, 4",
          "His general opinion is that arguments sometimes happen when you spend a lot of time with people and they aren't always serious. He thinks that arguing doesn't always cause problems, provided you get over it. But being unkind to your friends always damages a relationship",
        ],
      },
    ],
  },
  6: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E6",
    exerciseKey: "img/FriendsPlus/Page83/Key/E6answerKey.png",
    component: T6,
    // checkDuplicated: true,
    // textAlign: "center",
    textareaStyle: {
      width: 740,
      resize: "none",
      paddingTop: 1,
    },
    styleHint: {
      border: "none",
      paddingInline: 0,
      paddingBlock: "5px",
      styleElementHintBox: {
        // background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["In dialogue 1, a man is being teased."],
        styleElement: { color: "rgb(64,147,166)", marginRight: 40 },
      },
    ],
    stylesTextInput: { width: 130, textAlign: "center" },
    titleQuestion: [
      {
        num: "6",
        title: `Write your essay (180–200 words) using your notes in
        exercise 5.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page83/E6/1.jpg" style='margin-top:20px;height:6cm'/>
        <textarea id=0 rows=5></textarea>
        `,
        answer: ["devices", "attached", "enhances", "counts", "perfect"],
      },
    ],
  },
  7: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E7",
    exerciseKey: "img/FriendsPlus/Page83/Key/E7answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Look at the ideas below. Which
        three ideas do you think are the most important in
        helping people maintain relationships? Then compare
        your answers with your partner’s.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b>&ensp;Have regular get-togethers<br>
            <b>2</b>&ensp;Eat out with family members<br>
            <b>3</b>&ensp;Join sports activities<br>
            <b>4</b>&ensp;Keep contact on social networks<br>
            <b>5</b>&ensp;Take part in social organisations<br>
        </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  8: {
    unit: "Review Unit 6",
    id: "SB12-2024-U6-P83-E8",
    exerciseKey: "img/FriendsPlus/Page83/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["Xuan often plays soccer. She sometimes watches TV. She #"],
        styleElement: { color: "#10adcf", marginRight: 10 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `<span style='color:rgb(8,144,73)' >SPEAKING</span> Work in pairs. Describe events in your family
        life. Use phrases from exercises 2 and 5 to help you.`,
        color: "black",
        left: 50,
        width: "28cm",
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page83/E8/1.jpg" style='margin-left:100px;height:4cm'/>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
