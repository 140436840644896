import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P9-E1",
    exerciseKey: "img/FriendsPlus/Page87/Key/E1answerKey.png",
    recorder: true,
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    hintBox: [
      {
        src: ["dogs", "heights", "large crowds", "snakes", "spiders", "water"],
        width: 800,
        borderColor: "none",
        marginLeft: 0,
        colorHint: "#c92959",
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Discuss the questions.`,
        color: "black",
        left: 150,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:100px'>
          <b>1</b>&ensp;Do you enjoy scary films, stories or fairground rides?<br>
          <b>2</b>&ensp;Do you find any of these things scary? 
        </div>
        <hintbox id=0 font-weight='bold'></hintbox>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P9-E2",
    exerciseKey: "img/FriendsPlus/Page87/Key/E2answerKey.png",
    component: T6,
    audio: "Audios/3.03.mp3",
    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 450, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "But it’s up to you",
          "So I’m leaning towards the film club",
          "What are your options",
          "The only bad thing about that club",
          "What are you up to",
          "Have you considered another basketball club",
          "It’s a tricky decision",
        ],
        width: 600,
        borderColor: "#5f50a0",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `<headphone colorimg='#e9b6b3' name=3.03 marginleft='-50px'   color=white></headphone> Read the definition of an urban legend, then 
          listen to the story. What happened? Did you like it?`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page87/E2/1.jpg" style='margin-left:0px;height:5cm'/>
        <div>
          <textarea id='0' rows=6></textarea>
        </div>
        
        `,
        answer: [
          "A woman was driving home at night in a remote part of America when she had to stop for petrol. She had to get out of her car because there seemed to be a problem with her banknote. It turned out that there wasn't a problem, but the attendant wanted her to get out of the car because he had seen a man with an axe in the back of her car.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P9-E3",
    audio: "Audios/3.03.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E3answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "angry",
          "enthusiastic",
          "nervous",
          "patient",
          "sarcastic",
          "surprised",
        ],
        width: 800,
        borderColor: "none",
        marginLeft: 0,
        colorHint: "#c92959",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `<headphone colorimg='#e9b6b3' name=3.03 marginleft='-50px'   color=white></headphone> Read the <span style='color:#c92959'>Listening Strategy</span>. Listen again to 
          the first part of the story. Pay attention to the extracts 
          (1–4) and match each extract with one of the adjectives 
          below. There are two extra adjectives.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <img src="img/FriendsPlus/Page87/E3/1.jpg" style='margin-left:50px;height:8cm'/>
        <div>
          <b>1</b>&ensp;Max A horror story. Great, Ian. They’re so interesting.<br>
          <b>2</b>&ensp;Jill Oh shush, Max. Fantastic! How scary is it?<br>
          <b>3</b>&ensp;Ian Well, whatever. I’m not saying it isn’t true. It could easily be true.<br>
          <b>4</b>&ensp;Caro Oh, dear. I don’t like this story already. <br>
        </div>
        <div>
          <b style='font-style:italic'>Answer:</b><br>
          angry - #<br>
          enthusiastic - #<br>
          nervous - #<br>
          patient - #<br>
          sarcastic - #<br>
          surprised - #<br>
        </div>
        
        `,
        answer: ["", "2", "4", "3", "1", ""],
        InputRong: true,
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P9-E4",
    audio: "Audios/3.04.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E4answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 40, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "angry",
          "enthusiastic",
          "nervous",
          "patient",
          "sarcastic",
          "surprised",
        ],
        width: 800,
        borderColor: "none",
        marginLeft: 0,
        colorHint: "#c92959",
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: `<headphone colorimg='#e9b6b3' name=3.04 marginleft='-50px'   color=white></headphone> Read the <span style='color:#c92959'>Pronunciation</span> box. Then listen 
          carefully to the examples. Repeat them, copying the 
          intonation.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page87/E4/1.jpg" style='margin-left:50px;height:12cm'/>
        
        
        `,
        answer: ["", "2", "4", "3", "1", ""],
        InputRong: true,
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P9-E5",
    audio: "Audios/3.05.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E5answerKey.png",
    component: T6,

    // hideBtnFooter:true,
    textAlign: "center",
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
    },
    stylesTextInput: { width: 200, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "angry",
          "enthusiastic",
          "nervous",
          "patient",
          "sarcastic",
          "surprised",
        ],
        width: 800,
        borderColor: "none",
        marginLeft: 0,
        colorHint: "#c92959",
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: `<headphone colorimg='#e9b6b3' name=3.05 marginleft='-50px'   color=white></headphone>  Listen to the examples from the story. Match 
          them with the rules in the <span style='color:#c92959'>Pronunciation</span> box.
          intonation.`,
        color: "black",
        width: "28cm",
        left: 50,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page87/E4/1.jpg" style='margin-left:50px;height:12cm'/>
        <div>
          <b>1</b>&ensp;Caro Well, I think I like horror stories. <br>
          <b>2</b>&ensp;Max Oh, yeah. It’s so scary. <br>
          <b>3</b>&ensp;Jill I quite like this kind of story. <br>
          <b>4</b>&ensp;Caro She shouldn’t get out of the car. <br>
        </div>
        <b style='font-style:italic'>Answer:</b>
        <div style='display:flex;justify-content:space-between'>
          <div><b>1</b>&ensp;#</div>
          <div><b>2</b>&ensp;#</div>
        </div>
        <div style='display:flex;margin-top:10px;justify-content:space-between'>
          <div><b>3</b>&ensp;#</div>
          <div><b>4</b>&ensp;#</div>
        </div>
        
        `,
        answer: [
          "rule 1 (unsure)",
          "rule 2 (sarcastic)",
          "rule 1 (unsure)",
          "rule 1 (nervous)",
        ],
        InputRong: true,
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P87-E6",
    audio: "Audios/3.06.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E6answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "6",
        title: `<headphone colorimg='#e9b6b3' name=3.06 marginleft='-50px'   color=white></headphone> Listen to the sentences (1–6). Choose the 
          correct meaning (a or b).`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix:[{text:'SPEAKING',icon:'',color:'#01a951'}]
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
          "a <br> b",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
         <div>
            <b>1</b>&ensp;I enjoyed the story.
            <div style='display:flex;margin-bottom:10px'>
              <div style='margin-left:20px'><input id='0' type='Circle'/></div>
              <div>
                qualifying<br>
                a statement<br>
              </div>
            </div>
            <b>2</b>&ensp;Brilliant. It’s snowing.
            <div style='display:flex;margin-bottom:10px'>
              <div style='margin-left:20px'><input id='1' type='Circle'/></div>
              <div>
                enthusiastic<br>
                sarcastic<br>
              </div>
            </div>
            <b>3</b>&ensp;You’ll love it.
            <div style='display:flex;margin-bottom:10px'>
              <div style='margin-left:20px'><input id='2' type='Circle'/></div>
              <div>
                sarcastic<br>
                expecting agreement<br>
              </div>
            </div>
         </div>
         <div style='margin-left:200px'>
            <b>4</b>&ensp;This is the road home.
            <div style='display:flex;margin-bottom:10px'>
              <div style='margin-left:20px'><input id='3' type='Circle'/></div>
              <div>
                expecting disagreement<br>
                expecting agreement<br>
              </div>
            </div>
            <b>5</b>&ensp;This is nice.
            <div style='display:flex;margin-bottom:10px'>
              <div style='margin-left:20px'><input id='4' type='Circle'/></div>
              <div>
                qualifying<br>
                statement<br>
              </div>
            </div>
            <b>6</b>&ensp;Oh, great. Just what we need.
            <div style='display:flex;margin-bottom:10px'>
              <div style='margin-left:20px'><input id='5' type='Circle'/></div>
              <div>
                enthusiastic<br>
                sarcastic<br>
              </div>
            </div>
         </div>
      </div>
      
      `,
    },
  },
  7: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P87-E7",
    audio: "Audios/3.07.mp3",
    exerciseKey: "img/FriendsPlus/Page87/Key/E7answerKey.png",
    component: Circle_Write,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "7",
        title: `<headphone colorimg='#e9b6b3' name=3.07 marginleft='-50px'   color=white></headphone> Listen to the interview. Choose the correct 
          answers (a–d).`,
        color: "black",
        width: "28cm",
        left: 50,
        // prefix:[{text:'SPEAKING',icon:'',color:'#01a951'}]
      },
    ],
    // hideBtnFooter: true,
    question: {
      leftContent: 100,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "57px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "132px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "251px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
          "a <br> b <br> c <br> d",
        ],
        answers: ["0-8", "1-8", "2-12", "3-8"],
        initialValue: [],
      },
      Layout: `
      <b>1</b>&ensp;Dr Miriam Webster
      <div style='display:flex;margin:0px 20px'>
          <div><input id=0 type='Circle' /></div>
          <div>
            is a professor of urban myths and legends.<br>
            studies urban legends with her students.<br>
            writes down urban legends in her spare time.<br>
            is the author of many different urban legends.
          </div>
      </div>
      <b>2</b>&ensp;The presenter thinks that urban legends 
      <div style='display:flex;margin:0px 20px'>
          <div><input id=1 type='Circle' /></div>
          <div>
            generally seem unpleasant.<br>
            are very charming.<br>
            are always a fantasy.<br>
            put you in a good mood.
          </div>
      </div>
      <b>3</b>&ensp;Dr Webster says that an urban legend 
      <div style='display:flex;margin:0px 20px'>
          <div><input id=2 type='Circle' /></div>
          <div>
            is based on a traditional fairy story.<br>
            is nearly always written down.<br>
            generally has a single source.<br>
            has many different versions.
          </div>
      </div>
      <b>4</b>&ensp;Urban legends are popular because
      <div style='display:flex;margin:0px 20px'>
          <div><input id=3 type='Circle' /></div>
          <div>
            most ordinary people like to be shocked or scared.<br>
            they are better than Hollywood blockbuster films.<br>
            humans have a need for turning life events into stories.<br>
            generally people don’t read novels much any more.
          </div>
      </div>
      
      `,
    },
  },
  8: {
    unit: "Unit 7",
    id: "SB12-2024-U7-P8-E8",
    exerciseKey: "img/FriendsPlus/Page8/Key/E8answerKey.png",
    recorder: true,
    component: T6,
    inputSize: 500,
    textAlign: "center",
    stylesTextInput: { width: 170, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "biased",
          "critical",
          "daunting",
          "encouraging",
          "fake",
          "flattering",
          "genuine",
          "honest",
          "informative",
          "misleading",
          "neutral",
          "provocative",
        ],
        width: 1000,
        borderColor: "none",
        colorHint: "#92278f",
        marginLeft: 0,
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Work in pairs. Do you have urban legends in 
          Viet Nam? Find a story and make notes for it. Share your 
          story with another pair’s.
          `,
        color: "black",
        width: "28cm",
        left: 150,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='margin-left:150px'>
          Traditional media or social media, which one do you <br>
          think is …<br>
          faster?<br>
          more accurate?
        </div>
        `,
        answer: [
          "misleading",
          "dissemble",
          "provocative",
          "flattering",
          "disseminate",
          "biased",
          "fake",
          "daunting",
        ],
      },
    ],
  },
};

export default json;
