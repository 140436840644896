import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E1",
    exerciseKey: "img/FriendsPlus/Page69/Key/E1answerKey.png",
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Look at the want ad and
        discuss the questions.`,
        color: "black",
        width: "28cm",
        left: 50,
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    // recorder: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: { width: 540, paddingTop: 10 },
    hintBox: [
      {
        src: [
          "go to an online forum",
          "speak to a family member",
          "speak to a friend",
          "speak to a teacher / other adult",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: 800,
      },
      {
        src: ["<b>Speculating and deducing</b>"],
        styleElement: { color: "#1a9955" },
      },
    ],
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='width:26cm'>
          <b>1</b>&ensp;According to the job advert, what is expected from an applicant? What can an applicant enjoy at the work place?<textarea id=0 rows=3></textarea>
          <b>2</b>&ensp;What should you do if you want to apply for this job?<textarea id=1 rows=3></textarea>
        </div>
       

        `,
        answer: [
          "Things expected from an applicant: experience in taking an IELTS test, patience and availability in evenings and at weekends. Things to enjoy: good salary, free uniform, training, working environment",
          "Write an application letter.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E2",
    exerciseKey: "img/FriendsPlus/Page69/Key/E2answerKey.png",
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: `Read the task. What things do you think
        might have gone wrong? Discuss and make a list of
        possible problems.Read the task above and the letter below. In which
        paragraph does the letter include a) key qualities of the
        writer b) reasons for wanting to do this job?
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textAlign: "center",
    stylesTextInput: { width: 500, paddingTop: 10 },
    textareaStyle: {
      width: 800,
      paddingTop: 2,
      resize: "none",
      marginBottom: -43,
    },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [""],
        styleElement: { color: "#92278f", marginRight: "30px" },
      },
    ],
    character: ",",
    // hideBtnFooter: true,
    // character: "/",
    component: T6,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <img src="img/FriendsPlus/Page69/E2/1.jpg" style='height:11cm'/>
            <b>a)</b>&ensp;key qualities of the writer <br>#<br>
            <b>b)</b>&ensp;reasons for wanting to do this job?<br>#<br>
          </div>
          <img src="img/FriendsPlus/Page69/E2/2.jpg" style='height:22cm'/><br>
        </div>
        `,
        // InputRong: true,
        answer: ["Paragraphs 3", "Paragraphs 2, 4"],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E3",
    exerciseKey: "img/FriendsPlus/Page69/Key/E3answerKey.png",
    // checkDuplicated: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `Read the <span style='color:#2683c6' >Writting Strategy</span>. Find formal expressions in
        the letter for the informal words and phrases below.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["<b>Language focus: formal language</b>"],
        styleElement: { color: "#2683c6", marginRight: "30px" },
      },
    ],
    // textAlign: "center",
    stylesTextInput: { width: 450, paddingTop: 10 },

    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    // hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        
        <img src="img/FriendsPlus/Page69/E3/1.jpg" style='height:6cm'/>
        <div>
              <div style='display:flex'>
                <div  style='height:11.4cm;margin-bottom:10px;border-left: 5px solid rgb(0,147,69); padding-left: 10px'>
                    <hintbox id=0></hintbox>
                    <b>a</b>&ensp;I want to have<br>#<br>
                    <b>b</b>&ensp;I used to work<br>#<br>
                    <b>c</b>&ensp;I am really interested in<br>#<br>
                    <b>d</b>&ensp;means I am suitable for<br>#<br>
                    <b>e</b>&ensp;had to give<br>#<br>
                </div>
                <img src="img/FriendsPlus/Page69/E2/2.jpg" style='height:23cm'/><br>
            </div>
        </div>
        `,
        answer: [
          "I would be grateful for",
          "have previous experience of working",
          "I am writing to express my interest in",
          "make me a perfect fit",
          "was in charge of giving",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E4",
    exerciseKey: "img/FriendsPlus/Page69/Key/E4answerKey.png",
    textareaStyle: {
      marginBottom: -23,
      resize: "none",
      width: 700,
      paddingTop: 1,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title: `Rewrite the informal sentences in an appropriate formal
        style using the words in brackets.
        `,
        color: "black",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        width: "28cm",
        left: 50,
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "arrive",
          "have",
          "receive",
          "get",
          "wait",
          "set",
          "catch",
          "let",
          "build",
        ],
        styleElement: { color: "#f78d1f", marginRight: "30px" },
      },
    ],
    // hideBtnFooter: true,
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    component: T6,
    questions: [
      {
        title: `
         <div style='width:27cm'> 
          <b>1</b>&ensp;I was happy to read your advertisement in Thanh Nien Newspaper yesterday. (it / with interest / read / yesterday’s Thanh Nien Newspaper)<textarea id=0 rows=2></textarea>
          <b>2</b>&ensp;My English is good and I know some French. (speak / fluent / have / a basic knowledge)<textarea id=1 rows=2></textarea>
          <b>3</b>&ensp;You can find my CV in this letter. (enclose / CV / letter)<textarea id=2 rows=2></textarea>
          <b>4</b>&ensp;You can interview me whenever you like. (available / an interview / any time)<textarea id=3 rows=2></textarea>
        </div>
        `,
        answer: [
          "It was with interest that I read your advertisement in yesterday's Thanh Nien Newspaper.",
          "I speak fluent English and have a basic knowledge of French.",
          "I enclose my CV with this letter.",
          "I am available for an interview at any time.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E5",
    exerciseKey: "img/FriendsPlus/Page69/Key/E5answerKey.png",
    // character:",",
    textareaStyle: {
      width: 900,
      paddingTop: 2,
      resize: "none",
      marginBottom: -23,
    },
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "5",
        title: `Read the task below. Make notes. Then write your letter
        (180–200 words).`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],

    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // fontSize: 20,
      width: 450,
      paddingTop: 10,
    },

    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "do not have problems",
          "got",
          "happen",
          "looked at",
          "really think",
          "said",
          "she didn’t say sorry",
          "showed her",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
       
        <img src="img/FriendsPlus/Page69/E5/1.jpg" style='height:15cm'/>
        <textarea id=0 rows=4></textarea>
        `,
        answer: [
          `are not inconvenienced`,
          `received`,
          `arise`,
          `reviewed`,
          `feel strongly`,
          `maintained`,
          `There was no apology at any point`,
          `produced`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E6",
    exerciseKey: "img/FriendsPlus/Page69/Key/E6answerKey.png",
    checkDuplicated: true,
    textAlign: "center",

    stylesTextInput: { width: 200 },
    titleQuestion: [
      {
        num: "6",
        title: `Read the <span style='color:#2683c6' >Learn this!</span> box. Then find two examples of
        the subjunctive form in the letter`,
        color: "black",
        width: "28cm",
        left: -20,
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "KEY PHRASES", icon: "", color: "#91268e" }],
      },
    ],
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: ["best", "opting", "think", "why"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
      {
        src: ["<b>Making a selection</b>"],
        styleElement: { color: "#01a951", marginRight: "30px" },
      },
    ],
    textareaStyle: {
      resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 800,
    },
    character: ",",
    // hideBtnFooter: true,

    component: Circle_Write,
    question: {
      // ThSmile: true,
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { textAlign: "center", width: 200, paddingTop: 2 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "0px 7px",
          // border: "2px solid white",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          // fontWeight: "bold",
          // padding: "0px 7px",
          // borderRadius: "50%",
          color: "black",
          borderBottom: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 7,
        listWords: [
          `Dear_Sir_or_Madam,<br>
I_am_writing_to_complain_about_our_stay_at_your_youth_hostel_from_14–16_May.<br>
I_reserved_a_room_for_four_with_a_shower_room,_and_I_received_confirmation_of_the_booking. 
However,_when_we_arrived,_the_receptionist_maintained_there_was_no_record_of_my_reservation.<br>
I_produced_my_confirmation_slip,_but_she_just_said_that_‘something_must_have_been_wrong_with_the_website_that_day’.<br>
We_were_given_beds_in_a_fourteen-person_room_with_ten_other_people. 
There_was_one_bathroom_for_all_of_us,_which_was_dirty_with_no_toilet_paper. 
The_receptionist_took_no_notice_of_our_complaints.<br>
Then_we_were_supplied_with_no_sheets_on_the_beds! Eventually,_we_found_some_sheets_and_cleaned_the_bathrooms_ourselves.<br>
There_was_no_apology_at_any_point.<br>
I_feel_strongly_that_we_were_treated_very_poorly. 
Firstly,_I_suggest_that_your_online_booking_system_be_reviewed. 
Secondly,_your_staff_should_be_trained_to_be_more_welcoming,_and_more_helpful_when_problems_arise. 
And_finally,_I_request_that_there_be_sufficient_bed_linen,_so_that_other_guests_are_not_inconvenienced_as_we_were.<br>
I_feel_we_deserve_some_compensation_and_I_look_forward_to_hearing_from_you.<br>
Yours_faithfully,<br>
Gemma_Winters`,
        ],
        answers: ["0-32", "0-40"],
        initialValue: [],
      },
      Layout: `
      <img src="img/FriendsPlus/Page69/E6/1.jpg" style='height:9cm'/><br>
      <div style='width:25cm'>
        <input id=0 type=Circle />
      </div>
      `,
    },
  },
  7: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E7",
    exerciseKey: "img/FriendsPlus/Page69/Key/E7answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: `Read the task above. Make notes about what you will
        complain about. Use the ideas below or your own ideas.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
        // prefix: [{ text: "VOCABULARY", icon: "", color: "#91268e" }],
      },
    ],
    textareaStyle: {
      // resize: "none",
      marginBottom: -23,
      paddingTop: 1,
      width: 860,
    },
    // hideBtnFooter: true,
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        color: "#91268e",
        // background: "#fee7d2",
        marginRight: 20,
        // padding: "2px 5px",
        // borderRadius: "10px",
      },
    },
    stylesTextInput: {
      // fontWeight: "bold",
      // borderBottom: "none",
      // background: "none",
      width: 200,
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "no food available",
          "overcrowded",
          "queues for everything",
          "shop / swimming pool, etc. was closed",
          "showers / restaurants, etc. were dirty",
          "staff were impolite / unhelpful",
        ],
        styleElement: { color: "#2683c6", marginRight: "30px" },
        width: "80%",
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
       
        <hintbox id=0></hintbox>
        <img src="img/FriendsPlus/Page69/E7/1.jpg" style='height:6cm'/>
       
        <textarea id=0 rows=3></textarea>
        `,
        answer: [
          `I think I'll choose to travel by coach , mainly because …  
The best option would (again) be the coach.  
That's because you travel …`,
        ],
      },
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E8",
    exerciseKey: "img/FriendsPlus/Page69/Key/E8answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "8",
        title: `Write your letter of complaint (180–200 words) using
        your notes in exercise 7.`,
        color: "black",
        left: 50,
        width: "28cm",
        // prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id=0 rows=3></textarea>
        
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
  9: {
    unit: "Unit 5",
    id: "SB12-2024-U5-P69-E9",
    exerciseKey: "img/FriendsPlus/Page69/Key/E9answerKey.png",
    component: T6,
    stylesTextInput: { width: 800, paddingTop: 10 },
    styleHint: {
      border: "none",
      paddingInline: 3,
      paddingBlock: "0px",
      styleElementHintBox: {
        background: "#fee7d2",
        marginRight: 20,
        padding: "2px 5px",
        borderRadius: "10px",
      },
    },
    hintBox: [
      {
        src: [
          "build hotels",
          "create pollution / more traffic",
          "damage the environment",
          "keep traditions alive",
          "learn about other cultures",
          "more air travel",
          "prices go up",
          "promote international understanding",
          "protect wildlife",
          "provide money for poorer countriesprovide jobs",
          "provide new facilities for local people",
          "threaten our culture",
        ],
        styleElement: { color: "#414199", marginRight: 30 },
      },
    ],
    titleQuestion: [
      {
        num: "9",
        title: `Work in pairs. Discuss the statement in
        exercise 8. Use the phrases below to show that you
        agree or disagree.
        `,
        color: "black",
        left: 50,
        width: "28cm",
        prefix: [{ text: "SPEAKING", icon: "", color: "#01a951" }],
      },
    ],
    textareaStyle: { paddingTop: 1, width: "25cm" },
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style='border-left:5px solid rgb(65,65,153);padding-left:20px'>
          That’s exactly how I feel.<br>
          I couldn’t agree more. In fact, …<br>
          I see your point, but …<br>
          That may be true to some extent. However, …<br>
          I don’t really agree that …<br>
          I’m afraid I disagree. I think …<br>
      </div>
        `,
        answer: [
          "I have posted some photos on my blog.",
          "Some of my friends have left negative comments on the photos.",
          "I will not forget them.",
          "You have relied too much on information from social media.",
          "You shouldn't spend time reading them.",
          "Your brother needs support from you.",
          "You shouldn't complain about his dependence on social media.",
        ],
      },
    ],
  },
};

export default json;
